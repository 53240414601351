import axios from "axios";
import { API_BASE_URL } from "../utills/constant";

export const getDashboardHomeData = async (data) => {
  const { userId, modelId, startDate, endDate, checkUserName } = data;
  if (checkUserName === true) {
    return await axios.get(
      `${API_BASE_URL}/dashboard/get-dashboard-details?model_id=${modelId}&start_date=${startDate}&end_date=${endDate}`
    );
  } else {
    return await axios.get(
      `${API_BASE_URL}/dashboard/get-dashboard-details?user_id=${userId}&model_id=${modelId}&start_date=${startDate}&end_date=${endDate}`
    );
  }
};

export const getVehicleModelsData = async (data, enable_lead_creation = false) => {
  const { makeId } = data;
  return await axios.get(
    `${API_BASE_URL}/master/get-vehicle-models?make_id=${makeId}&enable_lead_creation=${enable_lead_creation}`
  );
};

export const getDashboardTargetData = async (props) => {
  const {
    userId,
    modelId,
    startDate,
    endDate,
    area_id,
    territory_id,
    month_id,
    year_id,
  } = props;
  let url = `${API_BASE_URL}/dashboard/get-target-details?`;

  if (userId) {
    url += `user_id=${userId}`;
    if (modelId || startDate || endDate) {
      url += `&`;
    }
  }
  if (modelId) {
    url += `model_id=${modelId}`;
    if (startDate || endDate) {
      url += `&`;
    }
  }
  if (startDate) {
    url += `start_date=${startDate}`;
    if (endDate) {
      url += `&`;
    }
  }
  if (endDate) {
    url += `end_date=${endDate}`;
    url += `&`;
  }
  if (month_id) {
    url += `&month=${month_id}`;
    url += `&`;
  }
  if (year_id) {
    url += `year=${year_id}`;
    url += `&`;
  }
  if (area_id) {
    url += `area_id=${area_id}`;
    url += `&`;
  }
  // if (territory_id) {
  //   url += `territory_id=${territory_id}`;
  //   url += `&`;
  // }
  const territoryQueryParams = territory_id
    ?.map((id, index) => `&territory_id[${index}]=${id}`)
    ?.join("");
  if (territoryQueryParams) {
    url += `${territoryQueryParams}&`;
  }
   // Remove trailing '&' or '?' at the end of the URL Check after push the it works
  //  url = url.endsWith("&") || url.endsWith("?") ? url.slice(0, -1) : url;
  return await axios.get(`${url}`);
};

export const getDashboardVasBookedData = async ({
  userId,
  modelId,
  startDate,
  endDate,
  area_id,
  territory_id,
  month_id,
  year_id,
}) => {
  let url = `${API_BASE_URL}/dashboard/get-vas-report-booked-details?`;

  if (userId) {
    url += `user_id=${userId}`;
    if (modelId || startDate || endDate) {
      url += `&`;
    }
  }
  if (modelId) {
    url += `model_id=${modelId}`;
    if (startDate || endDate) {
      url += `&`;
    }
  }
  if (startDate) {
    url += `start_date=${startDate}`;
    if (endDate) {
      url += `&`;
    }
  }
  if (endDate) {
    url += `end_date=${endDate}`;
  }
  if (month_id) {
    url += `&month=${month_id}`;
    url += `&`;
  }
  if (year_id) {
    url += `year=${year_id}`;
    url += `&`;
  }
  if (area_id) {
    url += `area_id=${area_id}`;
    url += `&`;
  }
  // if (territory_id) {
  //   url += `territory_id=${territory_id}`;
  //   url += `&`;
  // }
  const territoryQueryParams = territory_id
    ?.map((id, index) => `&territory_id[${index}]=${id}`)
    ?.join("");
  if (territoryQueryParams) {
    url += `${territoryQueryParams}&`;
  }
   // Remove trailing '&' or '?' at the end of the URL
  //  url = url.endsWith("&") || url.endsWith("?") ? url.slice(0, -1) : url;
  return await axios.get(`${url}`);
};

export const getDashboardVasRetailedData = async ({
  userId,
  modelId,
  startDate,
  endDate,
  area_id,
  territory_id,
  month_id,
  year_id,
}) => {
  let url = `${API_BASE_URL}/dashboard/get-vas-report-retailed-details?`;

  if (userId) {
    url += `user_id=${userId}`;
    if (modelId || startDate || endDate) {
      url += `&`;
    }
  }
  if (modelId) {
    url += `model_id=${modelId}`;
    if (startDate || endDate) {
      url += `&`;
    }
  }
  if (startDate) {
    url += `start_date=${startDate}`;
    if (endDate) {
      url += `&`;
    }
  }
  if (endDate) {
    url += `end_date=${endDate}`;
  }
  if (month_id) {
    url += `&month=${month_id}`;
    url += `&`;
  }
  if (year_id) {
    url += `year=${year_id}`;
    url += `&`;
  }
  if (area_id) {
    url += `area_id=${area_id}`;
    url += `&`;
  }
  // if (territory_id) {
  //   url += `territory_id=${territory_id}`;
  //   url += `&`;
  // }
  const territoryQueryParams = territory_id
    ?.map((id, index) => `&territory_id[${index}]=${id}`)
    ?.join("");
  if (territoryQueryParams) {
    url += `${territoryQueryParams}&`;
  }
   // Remove trailing '&' or '?' at the end of the URL
  //  url = url.endsWith("&") || url.endsWith("?") ? url.slice(0, -1) : url;
  return await axios.get(`${url}`);
};
