/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import InputSelect from '../../../components/common/InputSelect';
import ExchangeVechileDropdown from './ExchangeVehicleMAsterDropdown';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { Button, Row, Col, Form, Card, notification, Typography, Space } from 'antd';
import { fetchExchangeVehicleDetails,  fetchMasterSeater } from '../../auth/slice/authSlice';
import { addExchangeVehicleData, updateExchangeVehicleData } from '../../../services/master-services';

const { Title } = Typography;

const AddExchangeModel = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const fuelData = useSelector((state) => state?.auth?.fuelData?.data);
  const exchangeDataForEdit = useSelector((state) => state?.auth?.exchangeMasterDetails);
  const exchangeVehicleData = useSelector((state) => state?.auth?.exchangeMasterDetails);

  useEffect(() => {
    if (id) {
      getExchangeVechileMasterDetails();
    }
    dispatch(fetchMasterSeater());
  }, []);

  useEffect(() => {
    if (exchangeVehicleData && id) {
      form.setFieldsValue({
        ...exchangeVehicleData,
        exchange_make_id: exchangeVehicleData?.make_id ? exchangeVehicleData?.make_id : null,
        exchange_vehicle_model_id: exchangeVehicleData?.model_id ? exchangeVehicleData?.model_id : null,
        exchange_vehicle_variant_id: exchangeVehicleData?.variant?.id ? exchangeVehicleData?.variant?.id : null,
        exchange_transmission_id: exchangeVehicleData?.transmission_id ? exchangeVehicleData?.transmission_id : null,
        status: exchangeVehicleData?.status === 1,
        // year: exchangeVehicleData?.year ? dayjs(exchangeVehicleData?.year) : null,
      });
    }
  }, [exchangeVehicleData]);

  const getExchangeVechileMasterDetails = async () => {
    setIsLoading(true);
    try {
      await dispatch(fetchExchangeVehicleDetails({ id: id }));
      setIsLoading(false);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    const status = id ? exchangeDataForEdit.status : 1;
    values.status = status;
    setIsLoading(true);
    try {
      const data = id ? updateExchangeVehicleData(id, values) : addExchangeVehicleData(values);
      const response = await data;
      if (response.data.status === API_RESPONSE_CODES.OK || response.data.status === API_RESPONSE_CODES.CREATED) {
        notification.success({
          message: response?.data?.message,
        });
      }
      navigate('/admin/masters/exchange-model');
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='user-data'>
        <Loader loading={isLoading} />

        {/* Page header */}
        <PageContentHeader title={id ? 'Edit Exchange Vehicle' : 'Add Exchange Vehicle'} />

        <div className='personal-detail'>
          <Card>
            <div className='user-left d-flex align-items-center'>
              <Title level={4} className='pe-2 pb-2'>
                Exchange Vehicle Details
              </Title>
            </div>
            <Form
              layout='vertical'
              name='add-user-form'
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className='detail-wrapper'>
                <Row gutter={20} justify='start'>
                  {/* <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                </Col> */}
                  <ExchangeVechileDropdown
                    setIsLoading={setIsLoading}
                    form={form}
                    exchangeVehicleData={exchangeVehicleData}
                    isLoading={isLoading}
                    id={id}
                  />
                  <Col xl={4} md={8} sm={24} xs={24}>
                    <InputSelect
                      allowClear={false}
                      name='fuel_type_id'
                      placeholder='Fuel'
                      rules={[
                        {
                          required: true,
                          message: 'Please select your car fuel type',
                        },
                      ]}
                      options={fuelData}
                      label='Fuel'
                      optionName={'title'}
                    />
                  </Col>
                  {/* <Col xl={4} md={8} sm={24} xs={24}>
                    <InputSelect
                      allowClear={false}
                      label='Seater'
                      options={seatData}
                      name='seater_id'
                      optionName={'seater'}
                      placeholder='Seater'
                      className='form-control-ant'
                      rules={[
                        {
                          required: true,
                          message: 'Please select your seater',
                        },
                      ]}
                    />
                  </Col> */}
                  {/* <Col xl={4} md={8} sm={24} xs={24}>
                    <InputSelect
                      allowClear={false}
                      label='Color'
                      name='color_id'
                      options={colorData}
                      optionName={'color'}
                      placeholder='Color'
                      className='form-control-ant'
                      rules={[
                        {
                          required: true,
                          message: 'Please select your color',
                        },
                      ]}
                    />
                  </Col> */}
                  {/* <Col xl={4} md={8} sm={24} xs={24}>
                    <Form.Item
                      name="year"
                      label="Year"
                      rules={[
                        {
                          required: true,
                          message: "Please select year",
                        },
                      ]}
                    >
                      <DatePicker
                        picker="year"
                        placeholder="Select Year"
                        disabledDate={(current) => {
                          return (
                            current && dayjs(current).isAfter(dayjs().endOf("year"), "day")
                          );
                        }}
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
              </div>
              <div className='user-right d-flex align-items-center justify-content-end mt-3'>
                <Space size='middle'>
                  {/* Cancel button */}
                  <Button
                    type='primary'
                    ghost
                    danger
                    className='primary-button'
                    onClick={() => navigate('/admin/masters/exchange-model')}
                  >
                    Cancel
                  </Button>
                  {/* Save button */}
                  <Button type='primary' className='secondary-button' htmlType='submit'>
                    {id ? 'Update' : 'Save'}
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddExchangeModel;
