/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Col, DatePicker, Form, Input, Row, Select, Typography, notification } from 'antd';
import BookingDropdowns from './BookingDropdowns';
import Loader from '../../../../components/common/Loader';
import { AddBooking } from '../../../../services/admin-services';
import { API_RESPONSE_CODES } from '../../../../utills/constant';
import InputSelect from '../../../../components/common/InputSelect';
import UploadButton from '../../../../components/common/UploadButton';
import { fetchLeadDetailsServices } from '../../../auth/slice/authSlice';
import {
  getBankerService,
  getInsuranceCostService,
  getInsuranceTypeService,
  getRoadTaxCostService,
  getRoadTaxTypeService,
  getRtoService,
  getVehicleModelService,
} from '../../../../services/master-services';
import {
  formatIndianCurrency,
  notRequiredPriceValidationFunction,
  priceValidationFunction,
} from '../../../../utills/helpers';
import BookingDetails from './BookingDetails';

const { Title } = Typography;

const Bookings = (props) => {
  const { editBooking, setEditBooking, bookingDetailsShow, setBookingDetailsShow, isExchangeEvaluationTeam } = props;
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const dateFormat = 'DD/MM/YYYY';

  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const [rtoData, setRtoData] = useState([]);
  const [finance, setFinance] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [foreClosure, setForeClosure] = useState('');
  const [showOdometer, setShowOdometer] = useState(false);
  const [showDDreceived, setShowDdreceived] = useState(false);
  const [roadTaxCost, setRoadTaxCost] = useState(leadViewDetails?.booking?.road_tax);
  const [insuranceCost, setInsuranceCost] = useState(leadViewDetails?.booking?.insurance);
  const [roadTaxTypeData, setRoadTaxTypeData] = useState([]);
  const [insuranceTypeData, setInsuranceTypeData] = useState([]);
  const [customerAgreeToExc, setCustomerAgreeToExc] = useState();
  const [excVehicleIds, setExcVehicleIds] = useState({
    make_id: leadViewDetails?.vehicle_detail?.model?.make_id || '',
    model_id: leadViewDetails?.vehicle_detail?.model?.id || '',
    vehicle_variant_id: leadViewDetails?.vehicle_detail?.vehicle_variant_id || '',
    color_type_id: leadViewDetails?.vehicle_detail?.color_id || '',
    fuel_type_id: leadViewDetails?.vehicle_detail?.fuel_type_id || '',
    seater_id: leadViewDetails?.vehicle_detail?.seater_id || '',
  });

  const [fileList, setFileList] = useState({
    copy_of_rc: [],
    copy_of_insurance: [],
    copy_of_mr: [],
    copy_of_foreclosure_statement: [],
    copy_of_order_booklet: [],
  });

  const [tcsValue, setTcsValue] = useState(0);
  const [total, setTotal] = useState(leadViewDetails?.booking?.on_road_price || 0);
  const [totalOffer, setTotalOffer] = useState(leadViewDetails?.booking?.total_deal_offer || 0);
  const [totalCustomerPay, setTotalCustomerPay] = useState(leadViewDetails?.booking?.total_customer_payable || 0);
  const [bookingAmount, setBookingAmount] = useState(leadViewDetails?.booking?.total_payment_received || 0);
  const [netCustomerBalance, setNetCustomerBalance] = useState(leadViewDetails?.booking?.net_customer_balance || 0);

  const handleCustomerAgreeChange = (value) => {
    setShowOdometer(value === 'Yes');
  };

  const handleDDreceivedChange = (value) => {
    setShowDdreceived(value === 'Yes');
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchLeadDetailsServices({ id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (vehicleDataEntered) {
      form.setFieldsValue({
        road_tax: null,
        insurance: null,
      });
    }
  }, [excVehicleIds]);

  useEffect(() => {
    calculateTotal(form.getFieldsValue());
  }, [showOdometer, insuranceCost, roadTaxCost, customerAgreeToExc, excVehicleIds]);

  useEffect(() => {
    const customerAgreeToExchange = leadViewDetails?.exchange_vehicle_detail?.customer_agree_to_exchange;
    setShowOdometer(customerAgreeToExchange === 'Yes');

    const ddReceived = leadViewDetails?.booking?.dd_received;
    setShowDdreceived(ddReceived === 'Yes');
  }, []);

  useEffect(() => {
    getBankFinance();
    getRtoLocation(leadViewDetails?.customer?.state?.id);
    getModalData(leadViewDetails?.exchange_vehicle_detail?.make_id);
    form.setFieldsValue({
      ...leadViewDetails,
      in_house: leadViewDetails?.booking?.in_house,
      tentative_accessories: leadViewDetails?.booking?.tentative_accessories || 0,
      amc: leadViewDetails?.booking?.amc || 0,
      vehicle_model_id: leadViewDetails?.vehicle_detail?.model?.id,
      vehicle_variant_id: leadViewDetails?.vehicle_detail?.vehicle_variant_id,
      color_id: leadViewDetails?.vehicle_detail?.color_id,
      fuel_type_id: leadViewDetails?.vehicle_detail?.fuel_type_id,
      seater_id: leadViewDetails?.vehicle_detail?.seater_id,
      fastag: leadViewDetails?.booking?.fastag || 0,
      exchange_vehicle_model_id: leadViewDetails?.exchange_vehicle_detail?.model?.id,
      dd_amt: leadViewDetails?.booking?.dd_amt || 0,
      exchange_car: leadViewDetails?.exchange_car,
      road_tax: leadViewDetails?.booking?.road_tax || 0,
      road_tax_type_id: leadViewDetails?.booking?.road_tax_type_id || null,
      choice_vehicle_no: leadViewDetails?.choice_vehicle_no,
      mr_number: leadViewDetails?.booking?.mr_number,
      value_offered: leadViewDetails?.exchange_vehicle_detail?.value_offered || 0,
      ew: leadViewDetails?.booking?.ew || 0,
      financer_id: leadViewDetails?.booking?.financer_id,
      dd_received: leadViewDetails?.booking?.dd_received,
      social_occassion: leadViewDetails?.social_occassion,
      body_care_3m: leadViewDetails?.booking?.body_care_3m || 0,
      tcs: leadViewDetails?.booking?.tcs || 0,
      on_road_price: leadViewDetails?.booking?.on_road_price || 0,
      consumer_offer: leadViewDetails?.booking?.consumer_offer || 0,
      exchange_offer: leadViewDetails?.booking?.exchange_offer || 0,
      cash_amount_mr: leadViewDetails?.booking?.cash_amount_mr || 0,
      corporate_offer: leadViewDetails?.booking?.corporate_offer || 0,
      other_oem_offer: leadViewDetails?.booking?.other_oem_offer || 0,
      rto_location_id: leadViewDetails?.customer?.rto_location_id || leadViewDetails?.booking?.rto_location_id,
      odometer: leadViewDetails?.exchange_vehicle_detail?.odometer,
      // rto_location_id:leadViewDetails?.customer?.rto_location_id,
      total_deal_offer: leadViewDetails?.booking?.total_deal_offer || 0,
      logistic_charges: leadViewDetails?.booking?.logistic_charges || 0,
      year: leadViewDetails?.exchange_vehicle_detail?.car_year
        ? dayjs(leadViewDetails?.exchange_vehicle_detail?.car_year)
        : '',
      cash_amt_received: leadViewDetails?.booking?.cash_amt_received || 0,
      insurance: leadViewDetails?.booking?.insurance || 0,
      insurance_type_id: leadViewDetails?.booking?.insurance_type_id,
      sanction_mr_number: leadViewDetails?.booking?.sanction_mr_number,
      other_dealer_offer: leadViewDetails?.booking?.other_dealer_offer || 0,
      net_banking_number: leadViewDetails?.booking?.net_banking_number,
      sanction_letter_amt: leadViewDetails?.booking?.sanction_letter_amt || 0,
      net_customer_balance: leadViewDetails?.booking?.net_customer_balance || 0,
      booking_amt_received: leadViewDetails?.booking?.booking_amt_received || 0,
      expected_delivery_date: leadViewDetails?.expected_delivery_date
        ? dayjs(leadViewDetails?.expected_delivery_date)
        : '',
      total_customer_payable: leadViewDetails?.booking?.total_customer_payable || 0,
      total_payment_received: leadViewDetails?.booking?.total_payment_received || 0,
      net_banking_amt_received: leadViewDetails?.booking?.net_banking_amt_received || 0,
      foreclosure_cost: leadViewDetails?.exchange_vehicle_detail?.foreclosure_cost || 0,
      ex_showroom_price: leadViewDetails?.booking?.ex_showroom_price || 0,
      discount_approval_insurance: leadViewDetails?.booking?.discount_approval_insurance || 0,
      hypothecation_on_care: leadViewDetails?.exchange_vehicle_detail?.hypothecation_on_care,
      cost_of_registration: leadViewDetails?.booking?.cost_of_registration || 0,
      customer_agree_to_exchange: leadViewDetails?.exchange_vehicle_detail?.customer_agree_to_exchange,
      foreclosure_responsibility: leadViewDetails?.exchange_vehicle_detail?.foreclosure_responsibility
        ? leadViewDetails?.exchange_vehicle_detail?.foreclosure_responsibility
        : null,
      vehicle_handed_over_to_exchange_team:
        leadViewDetails?.exchange_vehicle_detail?.vehicle_handed_over_to_exchange_team,
      rto_cost: leadViewDetails?.booking?.rto_cost || 0,
    });
    setCustomerAgreeToExc(
      leadViewDetails?.exchange_vehicle_detail?.customer_agree_to_exchange === 'Yes' ? true : false
    );
    setUploadComponentData(leadViewDetails?.documents);
    getInsuranceTypeData();
    getRoadTaxTypeData();
  }, []);

  const getRtoLocation = async (id) => {
    setIsLoading(true);
    try {
      const response = await getRtoService(id);
      setRtoData(response?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getBankFinance = async () => {
    setIsLoading(true);
    try {
      const response = await getBankerService(true);
      setFinance(response?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const convertUrlToBlob = async (url) => {
    if (!url) return null;
    try {
      const response = await fetch(url);
      return await response.blob();
    } catch (error) {
      return null;
    }
  };

  const getModalData = async (value) => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleModelService(value);
      setModalData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const setUploadComponentData = (imagesData) => {
    const formattedFileLists = {
      copy_of_rc: [],
      copy_of_mr: [],
      copy_of_insurance: [],
      copy_of_foreclosure_statement: [],
      copy_of_order_booklet: [],
    };

    imagesData?.forEach((doc) => {
      const file = {
        uid: doc.id,
        name: doc.document_file_name,
        status: 'done',
        url: doc.document_path,
      };

      switch (doc.document_type) {
        case 'RC':
          formattedFileLists.copy_of_rc.push(file);
          break;
        case 'Insurance Policy':
          formattedFileLists.copy_of_insurance.push(file);
          break;
        case 'Old Car Foreclosure Statement':
          formattedFileLists.copy_of_foreclosure_statement.push(file);
          break;
        case 'MR Copy':
          formattedFileLists.copy_of_mr.push(file);
          break;
        case 'Order Booklet Copy':
          formattedFileLists.copy_of_order_booklet.push(file);
          break;
        default:
          break;
      }
    });

    setFileList(formattedFileLists);
  };

  const handleStatusTracker = (key) => {
    form.setFieldsValue({ status: key });
  };

  const getRoadTaxTypeData = async () => {
    setIsLoading(true);
    try {
      const roadTaxData = await getRoadTaxTypeService();
      setRoadTaxTypeData(roadTaxData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getInsuranceTypeData = async () => {
    setIsLoading(true);
    try {
      const insuranceData = await getInsuranceTypeService();
      setInsuranceTypeData(insuranceData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    const status = form.getFieldValue('status');

    formData.append('lead_id', id);
    formData.append('status', status ? status : '');
    formData.append('in_house', values?.in_house ? values?.in_house : 0);
    formData.append('ew', values?.ew ? values?.ew : 0);
    formData.append('tcs', values?.tcs ? values?.tcs : 0);
    formData.append('amc', values?.amc ? values?.amc : 0);
    formData.append('dd_amt', values?.dd_amt ? values?.dd_amt : 0);
    formData.append('fastag', values?.fastag ? values?.fastag : 0);
    formData.append('odometer', values?.odometer ? values?.odometer : '');
    formData.append('color_id', values?.color_id ? values?.color_id : '');
    formData.append('road_tax', values?.road_tax ? values?.road_tax : 0);
    formData.append('seater_id', values?.seater_id ? values?.seater_id : '');
    formData.append('insurance', values?.insurance ? values?.insurance : 0);
    formData.append('mr_number', values?.mr_number ? values?.mr_number : '');
    formData.append('rto_cost', values?.rto_cost ? values?.rto_cost : 0);
    formData.append('road_tax_type_id', values?.road_tax_type_id ? values?.road_tax_type_id : '');
    formData.append('insurance_type_id', values?.insurance_type_id ? values?.insurance_type_id : 0);
    formData.append('financer_id', values?.financer_id ? values?.financer_id : '');
    formData.append('dd_received', values?.dd_received ? values?.dd_received : '');
    formData.append('year', values?.year ? dayjs(values.year).format('YYYY') : null);
    formData.append('body_care_3m', values?.body_care_3m ? values?.body_care_3m : 0);
    formData.append('exchange_car', values?.exchange_car ? values?.exchange_car : '');
    formData.append('value_offered', values?.value_offered ? values?.value_offered : 0);
    formData.append('on_road_price', values?.on_road_price ? values?.on_road_price : 0);
    formData.append('cash_amount_mr', values?.cash_amount_mr ? values?.cash_amount_mr : 0);
    formData.append('consumer_offer', values?.consumer_offer ? values?.consumer_offer : 0);
    formData.append('exchange_offer', values?.exchange_offer ? values?.exchange_offer : 0);
    formData.append('other_oem_offer', values?.other_oem_offer ? values?.other_oem_offer : 0);
    formData.append('corporate_offer', values?.corporate_offer ? values?.corporate_offer : 0);
    formData.append('rto_location_id', values?.rto_location_id ? values?.rto_location_id : '');
    formData.append('foreclosure_cost', values?.foreclosure_cost ? values?.foreclosure_cost : 0);
    formData.append('total_deal_offer', values?.total_deal_offer ? values?.total_deal_offer : 0);
    formData.append('logistic_charges', values?.logistic_charges ? values?.logistic_charges : 0);
    formData.append('social_occassion', values?.social_occassion ? values?.social_occassion : '');
    formData.append('cash_amt_received', values?.cash_amt_received ? values?.cash_amt_received : 0);
    formData.append('choice_vehicle_no', values?.choice_vehicle_no ? values?.choice_vehicle_no : '');
    formData.append('ex_showroom_price', values?.ex_showroom_price ? values?.ex_showroom_price : 0);
    formData.append('other_dealer_offer', values?.other_dealer_offer ? values?.other_dealer_offer : 0);
    formData.append('net_banking_number', values?.net_banking_number ? values?.net_banking_number : '');
    formData.append('sanction_mr_number', values?.sanction_mr_number ? values?.sanction_mr_number : '');
    formData.append('sanction_letter_amt', values?.sanction_letter_amt ? values?.sanction_letter_amt : 0);
    formData.append('net_customer_balance', values?.net_customer_balance ? values?.net_customer_balance : 0);
    formData.append('booking_amt_received', values?.booking_amt_received ? values?.booking_amt_received : 0);
    formData.append('cost_of_registration', values?.cost_of_registration ? values?.cost_of_registration : 0);
    formData.append('fuel_type_id', values?.fuel_type_id ? values?.fuel_type_id : '');
    formData.append('tentative_accessories', values?.tentative_accessories ? values?.tentative_accessories : 0);
    formData.append('hypothecation_on_care', values?.hypothecation_on_care ? values?.hypothecation_on_care : '');
    formData.append('total_customer_payable', values?.total_customer_payable ? values?.total_customer_payable : 0);
    formData.append('total_payment_received', values?.total_payment_received ? values?.total_payment_received : 0);
    formData.append(
      'net_banking_amt_received',
      values?.net_banking_amt_received ? values?.net_banking_amt_received : 0
    );
    formData.append(
      'exchange_vehicle_model_id',
      values?.exchange_vehicle_model_id ? values?.exchange_vehicle_model_id : ''
    );
    formData.append('vehicle_model_id', values?.vehicle_model_id ? values?.vehicle_model_id : '');
    formData.append(
      'foreclosure_responsibility',
      values?.foreclosure_responsibility ? values?.foreclosure_responsibility : ''
    );
    formData.append(
      'customer_agree_to_exchange',
      values?.customer_agree_to_exchange ? values?.customer_agree_to_exchange : ''
    );
    formData.append('vehicle_variant_id', values?.vehicle_variant_id ? values?.vehicle_variant_id : '');
    formData.append(
      'discount_approval_insurance',
      values?.discount_approval_insurance ? values?.discount_approval_insurance : 0
    );
    formData.append(
      'vehicle_handed_over_to_exchange_team',
      values?.vehicle_handed_over_to_exchange_team ? values?.vehicle_handed_over_to_exchange_team : ''
    );
    formData.append(
      'expected_delivery_date',
      values?.expected_delivery_date ? dayjs(values?.expected_delivery_date).format('YYYY-MM-DD') : null
    );
    const appendFile = async (key, files) => {
      if (files?.length > 0) {
        const file = files[0].originFileObj || (await convertUrlToBlob(files[0].url));
        formData.append(key, file || '');
      } else {
        formData.append(key, '');
      }
    };
    await appendFile('copy_of_rc', fileList.copy_of_rc);
    await appendFile('copy_of_mr', fileList.copy_of_mr);
    await appendFile('copy_of_insurance', fileList.copy_of_insurance);
    await appendFile('copy_of_order_booklet', fileList.copy_of_order_booklet);
    await appendFile('copy_of_foreclosure_statement', fileList.copy_of_foreclosure_statement);

    try {
      const response = await AddBooking(formData);
      dispatch(fetchLeadDetailsServices({ id: id }));
      if (response.data.status === API_RESPONSE_CODES?.OK || response.data.status === API_RESPONSE_CODES?.CREATED) {
        if (status === 'Booked') {
          setBookingDetailsShow(true);
        } else {
          setEditBooking(false);
          setBookingDetailsShow(true);
        }

        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    if (editBooking) {
      setEditBooking(false);
      setBookingDetailsShow(true);
    } else {
      form.resetFields();
      setFileList({
        copy_of_rc: [],
        copy_of_mr: [],
        copy_of_insurance: [],
        copy_of_order_booklet: [],
        copy_of_foreclosure_statement: [],
      });
    }
  };

  const handleInsuranceTypeChange = async (val) => {
    const payload = {
      vehicle_model_id: excVehicleIds?.model_id,
      vehicle_variant_id: excVehicleIds?.vehicle_variant_id,
      fuel_type_id: excVehicleIds?.fuel_type_id,
      seater_id: excVehicleIds?.seater_id,
      color_id: excVehicleIds?.color_type_id,
      insurance_type_id: val ? val : leadViewDetails?.booking?.insurance_type_id,
    };
    const insuranceCost = await getInsuranceCostService(payload);
    if (insuranceCost.data.status === API_RESPONSE_CODES.OK) {
      setInsuranceCost(insuranceCost?.data?.data);
      form.setFieldsValue({
        insurance: insuranceCost?.data?.data,
      });
    }
  };

  const handleRoadTaxTypeChange = async (val) => {
    const payload = {
      vehicle_model_id: excVehicleIds?.model_id,
      vehicle_variant_id: excVehicleIds?.vehicle_variant_id,
      fuel_type_id: excVehicleIds?.fuel_type_id,
      seater_id: excVehicleIds?.seater_id,
      color_id: excVehicleIds?.color_type_id,
      road_tax_id: val ? val : leadViewDetails?.booking?.insurance_type_id,
    };
    const roadTaxTypeCost = await getRoadTaxCostService(payload);
    if (roadTaxTypeCost.data.status === API_RESPONSE_CODES.OK) {
      setRoadTaxCost(roadTaxTypeCost?.data?.data);
      form.setFieldsValue({
        road_tax: roadTaxTypeCost?.data?.data,
      });
    }
  };

  const handleChangeForeclosure = (e) => {
    setForeClosure(e);
  };

  const calculateTotal = (values) => {
    const fields = [
      'ex_showroom_price',
      'tcs',
      'insurance',
      'cost_of_registration',
      'ew',
      'amc',
      'fastag',
      'body_care_3m',
      'logistic_charges',
      'road_tax',
      'tentative_accessories',
      'rto_cost',
    ];

    const totalSum = fields.reduce((sum, field) => {
      const value = parseFloat(values[field]) || 0;
      return sum + value;
    }, 0);

    const dealOffer = [
      'discount_approval_insurance',
      'consumer_offer',
      'exchange_offer',
      'corporate_offer',
      'other_oem_offer',
      'other_dealer_offer',
    ];

    const totalSumOfDealOffer = dealOffer.reduce((sum, field) => {
      const value = parseFloat(values[field]) || 0;
      return sum + value;
    }, 0);

    let totalCustPayable = totalSum - totalSumOfDealOffer;

    let cutomer;
    if (values.customer_agree_to_exchange === 'Yes') {
      if (values.foreclosure_responsibility === 'Customer') {
        cutomer = values['value_offered'];
        const amount = totalCustPayable - cutomer;
        form.setFieldsValue({
          total_customer_payable: amount,
        });
        totalCustPayable = amount;
      } else if (values.foreclosure_responsibility === 'Dealer') {
        cutomer = values['value_offered'] - values['foreclosure_cost'];
        const amount = totalCustPayable - cutomer;
        form.setFieldsValue({
          total_customer_payable: amount,
        });
        totalCustPayable = amount;
      }
    } else if (values.customer_agree_to_exchange === 'No') {
      cutomer = 0;
      const amount = totalCustPayable - cutomer;
      totalCustPayable = amount;
      form.setFieldsValue({
        total_customer_payable: amount,
      });
    }

    const netCustomer = [
      'booking_amt_received',
      'dd_amt',
      'sanction_letter_amt',
      'net_banking_amt_received',
      'cash_amt_received',
    ];

    const totalPaymentReceived = netCustomer?.reduce((sum, field) => {
      const value = parseFloat(values[field]) || 0;
      return sum + value;
    }, 0);

    const netCutomerBal =
      totalPaymentReceived && totalCustPayable
        ? totalCustPayable - totalPaymentReceived
        : totalCustPayable
        ? totalCustPayable
        : 0;

    setTotalCustomerPay(totalCustPayable);
    setBookingAmount(totalPaymentReceived);
    setNetCustomerBalance(netCutomerBal);
    // setTotalCustomerPay(cutomer);
    setTotalOffer(totalSumOfDealOffer);
    setTotal(totalSum);
  };

  const handleExShowroomChange = (e) => {
    const exShowroomPrice = parseFloat(e.target.value) || 0;

    // Check if the ex-showroom price is greater than 10 lakhs (1,000,000)
    if (exShowroomPrice > 1000000) {
      const calculatedTCS = (exShowroomPrice * 1) / 100; // 1% of ex showroom price
      setTcsValue(calculatedTCS); // Update the TCS value and round to 2 decimals
      form.setFieldsValue({ tcs: calculatedTCS }); // Set the TCS field value in the form
      calculateTotal(form.getFieldsValue());
    } else {
      setTcsValue(0); // Set TCS to 0 for values 10 lakhs or less
      form.setFieldsValue({ tcs: 0 }); // Update the TCS field in the form
      calculateTotal(form.getFieldsValue());
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      net_customer_balance: netCustomerBalance,
      total_payment_received: bookingAmount,
      on_road_price: total,
      total_deal_offer: totalOffer,
      total_customer_payable: totalCustomerPay,
    });
    // form.setFieldsValue({ total_payment_received: bookingAmount });
    // form.setFieldsValue({ on_road_price: total });
    // form.setFieldsValue({ total_deal_offer: totalOffer });
    // form.setFieldsValue({ total_customer_payable: totalCustomerPay });
  }, [total, form, totalOffer, totalCustomerPay, totalCustomerPay, bookingAmount, netCustomerBalance]);

  const vehicleDataEntered =
    !excVehicleIds?.make_id ||
    !excVehicleIds?.model_id ||
    !excVehicleIds?.vehicle_variant_id ||
    !excVehicleIds?.color_type_id ||
    !excVehicleIds?.fuel_type_id ||
    !excVehicleIds?.seater_id;

  return (
    <>
      <Loader loading={isLoading} />
      {bookingDetailsShow || isExchangeEvaluationTeam ? (
        <BookingDetails
          editBooking={editBooking}
          setEditBooking={setEditBooking}
          setBookingDetailsShow={setBookingDetailsShow}
          isExchangeEvaluationTeam={isExchangeEvaluationTeam}
        />
      ) : (
        <div className='user-data lead-detail'>
          <Form
            form={form}
            layout='vertical'
            onFinish={onSubmit}
            onValuesChange={(_, values) => {
              calculateTotal(values);
            }}
          >
            <div className='basic-detail-wrapper'>
              <Row gutter={20}>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className='mb-3 me-3'>
                    <Title level={4} className='mb-3'>
                      Basic Details
                    </Title>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Expected Date of Delivery <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='expected_delivery_date'
                          label=''
                          rules={[
                            {
                              required: true,
                              message: 'Please select any date',
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={(current) => current && current < dayjs().startOf('day')}
                            format={dateFormat}
                            placeholder='Select Date'
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>Choice of Vehicle Number</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='choice_vehicle_no'
                          label=''
                          rules={[
                            {
                              required: false,
                            },
                            {
                              pattern: /^[0-9]{4}$/,
                              message: 'Please enter a valid 4-digit vehicle number',
                            },
                          ]}
                        >
                          <Input placeholder='Choice of Vehicle Number' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        RTO Location <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <InputSelect
                          rules={[
                            {
                              required: true,
                              message: 'Please select RTO location',
                            },
                          ]}
                          className='form-control-ant'
                          name='rto_location_id'
                          placeholder='RTO Location'
                          options={rtoData}
                          optionName={'name'}
                          showSearch
                        />
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>Social Occasion</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='social_occassion'
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                // Check if value is not provided or is only spaces
                                if (!value || value.trim().length === 0) {
                                  return Promise.resolve(); // Consider it valid if the field is empty
                                }

                                const trimmedValue = value.trim();
                                if (trimmedValue !== value) {
                                  return Promise.reject('Social occasion cannot start or end with spaces');
                                } else if (value.length > 80) {
                                  return Promise.reject(new Error('Please enter at most 80 characters'));
                                } else if (/\s{2,}/.test(value)) {
                                  return Promise.reject('Social occasion cannot contain two consecutive spaces');
                                } else if (!/^[a-zA-Z\s]*$/.test(value)) {
                                  return Promise.reject(new Error('Please enter only alphabets'));
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Input placeholder='Social Occasion' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>Exchange</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='exchange_car'
                          label=''
                          rules={[
                            {
                              required: true,
                              message: 'Please select exchange',
                            },
                          ]}
                        >
                          <p>{leadViewDetails?.exchange_car ? leadViewDetails?.exchange_car : '-'}</p>
                          {/* <Select
                            className="form-control-ant"
                            placeholder="Yes/No"
                          >
                            <Select.Option value="Yes">Yes</Select.Option>
                            <Select.Option value="No">No</Select.Option>
                          </Select> */}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  {leadViewDetails?.exchange_car === 'Yes' && (
                    <div className='mb-3 ms-0'>
                      <Title level={4} className='mb-3'>
                        Exchange Related Details
                      </Title>
                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>
                          Customer Agree to Exchange <span className='asterisk'>*</span>
                        </h6>
                        <div className='lead-input'>
                          <Form.Item
                            name='customer_agree_to_exchange'
                            label=''
                            rules={[
                              {
                                required: true,
                                message: 'Please select customer agree to exchange',
                              },
                            ]}
                          >
                            <Select
                              className='form-control-ant'
                              placeholder='Yes/No'
                              onChange={handleCustomerAgreeChange}
                            >
                              <Select.Option value='Yes'>Yes</Select.Option>
                              <Select.Option value='No'>No</Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>Exchange Car</h6>
                        <div className='lead-input'>
                          <InputSelect
                            name='exchange_vehicle_model_id'
                            placeholder='Model'
                            options={modalData}
                            label=''
                            optionName={'model_name'}
                            showSearch
                            disabled
                          />
                        </div>
                      </div>
                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>
                          Value Offered <span className='asterisk'>*</span>
                        </h6>
                        <div className='lead-input'>
                          <Form.Item name='value_offered' label=''>
                            <p>{formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.value_offered) || 0}</p>
                            {/* <Input placeholder="Value Offered" /> */}
                          </Form.Item>
                        </div>
                      </div>
                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>
                          Exchange Car Year <span className='asterisk'>*</span>
                        </h6>
                        <div className='lead-input'>
                          <Form.Item
                            name='year'
                            label=''
                            rules={[
                              {
                                required: true,
                                message: 'Please select year',
                              },
                            ]}
                          >
                            <DatePicker
                              picker='year'
                              disabledDate={(current) => {
                                return current && dayjs(current)?.isAfter(dayjs().endOf('year'), 'day');
                              }}
                              placeholder='Select Year'
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>
                          Odometer <span className='asterisk'>*</span>
                        </h6>
                        <div className='lead-input'>
                          <Form.Item
                            name='odometer'
                            label=''
                            rules={[
                              {
                                required: true,
                                validator: (_, value) => {
                                  if (!value) {
                                    return Promise.reject(new Error('Please input your odometer details'));
                                  } else if (!/^\d+$/.test(value)) {
                                    return Promise.reject(new Error('The input must be numeric'));
                                  } else if (value?.length < 2) {
                                    return Promise.reject(new Error('The input must be at least 2 digits long'));
                                  } else if (value?.length > 10) {
                                    return Promise.reject(new Error('Odometer reading cannot be more than 10 digits'));
                                  } else if (/\s/.test(value)) {
                                    return Promise.reject(new Error('The input must not contain whitespace'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input placeholder='Odometer' />
                          </Form.Item>
                        </div>
                      </div>
                      {showOdometer && (
                        <>
                          <div className='price-detail-wrap d-flex align-items-baseline'>
                            <h6>
                              Foreclouser Responsibility
                              <span className='asterisk'>*</span>
                            </h6>
                            <div className='lead-input'>
                              <Form.Item
                                name='foreclosure_responsibility'
                                label=''
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select foreclouser responsibility',
                                  },
                                ]}
                              >
                                <Select
                                  className='form-control-ant'
                                  placeholder='Foreclouser Responsibility'
                                  onChange={(e) => handleChangeForeclosure(e)}
                                  allowClear
                                >
                                  <Select.Option value='Customer'>Customer</Select.Option>
                                  <Select.Option value='Dealer'>Dealer</Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className='price-detail-wrap d-flex align-items-baseline'>
                            <h6>
                              Hypothecation on Car <span className='asterisk'>*</span>
                            </h6>
                            <div className='lead-input'>
                              <Form.Item
                                name='hypothecation_on_care'
                                label=''
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value) {
                                        return Promise.reject('Please input your Hypothecation on Car');
                                      }
                                      if (!/^[A-Za-z\s]+$/.test(value)) {
                                        return Promise.reject('Please input a valid Hypothecation on Car');
                                      }
                                      if (/\s{2,}/.test(value)) {
                                        return Promise.reject('Cannot contain two consecutive spaces');
                                      }
                                      const trimmedValue = value.trim();
                                      if (trimmedValue !== value) {
                                        return Promise.reject('Cannot start or end with spaces');
                                      }
                                      if (value?.length < 3 || value?.length > 30) {
                                        return Promise.reject(
                                          'Hypothecation on Car must be between 3 and 30 characters'
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Input placeholder='Hypothecaton on Car ' />
                              </Form.Item>
                            </div>
                          </div>
                          <div className='price-detail-wrap d-flex align-items-baseline'>
                            <h6>Foreclouser Cost</h6>
                            <div className='lead-input'>
                              <Form.Item
                                name='foreclosure_cost'
                                label=''
                                rules={notRequiredPriceValidationFunction('Foreclouser Cost')}
                              >
                                <Input placeholder='Foreclouser Cost' />
                              </Form.Item>
                            </div>
                          </div>
                          <div className='price-detail-wrap d-flex align-items-baseline'>
                            <h6>
                              Vehicle Handed Over to Exchange Team <span className='asterisk'>*</span>
                            </h6>
                            <div className='lead-input'>
                              <Form.Item
                                name='vehicle_handed_over_to_exchange_team'
                                label=''
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select vehicle handed over to exchange team',
                                  },
                                ]}
                              >
                                <Select className='form-control-ant' placeholder='Yes/No'>
                                  <Select.Option value='Yes'>Yes</Select.Option>
                                  <Select.Option value='No'>No</Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className='mb-3 ms-0'>
                    <Title level={4} className='mb-3'>
                      Car Details
                    </Title>
                    <BookingDropdowns
                      form={form}
                      setIsLoading={setIsLoading}
                      excVehicleIds={excVehicleIds}
                      leadViewDetails={leadViewDetails}
                      setExcVehicleIds={setExcVehicleIds}
                    />
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        EX-Showroom <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='ex_showroom_price'
                          label=''
                          rules={priceValidationFunction('Ex-Showroom price')}
                        >
                          <Input placeholder='Ex-Showroom Price' onChange={handleExShowroomChange} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        TCS<span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='tcs' label='' rules={priceValidationFunction('TCS')}>
                          <Input placeholder='TCS' value={tcsValue} readOnly />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Insurance Type */}
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Insurance Type: <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          label=''
                          name='insurance_type_id'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your insurance type ',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Insurance Type'
                            disabled={vehicleDataEntered}
                            onChange={handleInsuranceTypeChange}
                          >
                            {insuranceTypeData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.type}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    {/* Insurance */}
                    {!isNaN(insuranceCost) && !vehicleDataEntered ? (
                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>
                          Insurance <span className='asterisk'>*</span>
                        </h6>
                        <div className='lead-input'>
                          {/* <Form.Item label='' name='insurance' rules={priceValidationFunction('Insurance')}>
                            <p>{insuranceCost ? formatIndianCurrency(insuranceCost) : '-'}</p>
                          </Form.Item> */}
                          <Form.Item name='insurance' label='' rules={priceValidationFunction('Insurance')}>
                            <Input placeholder='Insurance' />
                          </Form.Item>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        RTO Cost<span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item label='' name='rto_cost' rules={priceValidationFunction('RTO Cost')}>
                          <Input placeholder='RTO Cost' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Cost of Registration<span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          label=''
                          name='cost_of_registration'
                          rules={priceValidationFunction('Cost of Registration')}
                        >
                          <Input placeholder='Cost of Registration' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Extended Warranty <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='ew' label='' rules={priceValidationFunction('Extended Warranty')}>
                          <Input placeholder='Extended Warranty' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>AMC</h6>
                      <div className='lead-input'>
                        <Form.Item name='amc' label='' rules={notRequiredPriceValidationFunction('AMC')}>
                          <Input placeholder='AMC' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Tentative Accessories <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='tentative_accessories'
                          label=''
                          rules={priceValidationFunction('Tentative Accessories')}
                        >
                          <Input placeholder='Tentative Accessories' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Fastag <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='fastag' label='' rules={priceValidationFunction('Fastag')}>
                          <Input placeholder='Fastag' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        T-Gloss <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='body_care_3m' label='' rules={priceValidationFunction('T-Gloss')}>
                          <Input placeholder='T-Gloss' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Other Logistcs Charges <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='logistic_charges' label='' rules={priceValidationFunction('Logistcs Charges')}>
                          <Input placeholder='Other Logistcs Charges' />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Road Tax Type */}
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Road Tax Type: <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          label=''
                          name='road_tax_type_id'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your road tax type ',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Road Tax Type'
                            disabled={vehicleDataEntered}
                            onChange={handleRoadTaxTypeChange}
                          >
                            {roadTaxTypeData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.type}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    {/* Road Tax */}
                    {!isNaN(roadTaxCost) && !vehicleDataEntered ? (
                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>
                          Road Tax <span className='asterisk'>*</span>
                        </h6>
                        <div className='lead-input'>
                          <Form.Item name='road_tax' label='' rules={priceValidationFunction('Road Tax')}>
                            <p>{roadTaxCost ? formatIndianCurrency(roadTaxCost) : '-'}</p>
                          </Form.Item>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        On Road Price <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='on_road_price'
                          label=''
                          rules={[
                            {
                              required: true,
                              message: 'Please input on road price ',
                            },
                          ]}
                        >
                          <Input placeholder='On Road Price' value={total} readOnly />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className='mb-3 ms-0'>
                    <Title level={4} className='mb-3'>
                      Payment Related Details
                    </Title>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Financer Name <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <InputSelect
                          rules={[
                            {
                              required: true,
                              message: 'Please select financer name',
                            },
                          ]}
                          className='form-control-ant'
                          name='financer_id'
                          placeholder='Financer Name'
                          options={finance}
                          optionName={'name'}
                          showSearch
                        />
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        In House <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='in_house'
                          label=''
                          rules={[
                            {
                              required: true,
                              message: 'Please select an option',
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Yes/No'>
                            <Select.Option value='Yes'>Yes</Select.Option>
                            <Select.Option value='No'>No</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Booking Amount Recived <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='booking_amt_received'
                          label=''
                          rules={priceValidationFunction('Booking Amount Received')}
                        >
                          <Input placeholder='Booking Amount Received' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        MR Number <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='mr_number' label='' rules={priceValidationFunction('MR Number')}>
                          <Input placeholder='MR Number' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        DD Received <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='dd_received'
                          label=''
                          rules={[
                            {
                              required: true,
                              message: 'Please select an option',
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Yes/No' onChange={handleDDreceivedChange}>
                            <Select.Option value='Yes'>Yes</Select.Option>
                            <Select.Option value='No'>No</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    {showDDreceived && (
                      <div className='price-detail-wrap d-flex align-items-baseline'>
                        <h6>
                          DD Amount <span className='asterisk'>*</span>
                        </h6>
                        <div className='lead-input'>
                          <Form.Item name='dd_amt' label='' rules={priceValidationFunction('DD Amount')}>
                            <Input placeholder='DD Amount' />
                          </Form.Item>
                        </div>
                      </div>
                    )}
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>DD/Sanction Letter Amount</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='sanction_letter_amt'
                          label=''
                          rules={notRequiredPriceValidationFunction('DD Letter Amount')}
                        >
                          <Input placeholder='DD Letter Amount' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>DD/Sanction MR Number</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='sanction_mr_number'
                          label=''
                          rules={notRequiredPriceValidationFunction('DD MR Number')}
                        >
                          <Input placeholder='DD MR Number' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>UPI/Net Banking Amount Received</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='net_banking_amt_received'
                          label=''
                          rules={notRequiredPriceValidationFunction('Net Banking Amount Received')}
                        >
                          <Input placeholder='Net Banking Amount Received' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>UPI/Net Banking Number</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='net_banking_number'
                          label=''
                          rules={notRequiredPriceValidationFunction('Net Banking Number')}
                        >
                          <Input placeholder='Net Banking Number' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>Cash Amount Recived</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='cash_amt_received'
                          label=''
                          rules={notRequiredPriceValidationFunction('Cash Amount Received')}
                        >
                          <Input placeholder='Cash Amount Received' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>Cash Amount MR</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='cash_amount_mr'
                          label=''
                          rules={notRequiredPriceValidationFunction('Cash Amount MR')}
                        >
                          <Input placeholder='Cash Amount MR' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>Total Payment Received</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='total_payment_received'
                          label=''
                          rules={notRequiredPriceValidationFunction('Total Payment Received')}
                        >
                          <Input placeholder='Total Payment Received' value={bookingAmount} readOnly />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>Net Customer Balance</h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='net_customer_balance'
                          label=''
                          rules={notRequiredPriceValidationFunction('Net Customer Balance')}
                        >
                          <Input placeholder='Net Customer Balance' value={netCustomerBalance} readOnly />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className='mb-3 me-3'>
                    <Title level={4} className='mb-3'>
                      Deal Related Details
                    </Title>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Discount Approval Insurance <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='discount_approval_insurance'
                          label=''
                          rules={priceValidationFunction('Discount Approval Insurance')}
                        >
                          <Input placeholder='Discount Approval Insurance' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Consumer Offer <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='consumer_offer' label='' rules={priceValidationFunction('Consumer Offer')}>
                          <Input placeholder='Consumer Offer' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Exchange Offer<span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='exchange_offer' label='' rules={priceValidationFunction('Exchange Offer')}>
                          <Input placeholder='Exchange Offer' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Corporate Offer <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='corporate_offer' label='' rules={priceValidationFunction('Corporate Offer')}>
                          <Input placeholder='Corporate Offer' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Other OEM Offer <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='other_oem_offer' label='' rules={priceValidationFunction('Other OEM Offer')}>
                          <Input placeholder='Other OEM Offer' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Other Dealer Offer <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='other_dealer_offer'
                          label=''
                          rules={priceValidationFunction('Other Dealer Offer')}
                        >
                          <Input placeholder='Other Dealer Offer' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Total Deal Offer <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item name='total_deal_offer' label='' rules={priceValidationFunction('Total Deal Offer')}>
                          <Input placeholder='Total Deal Offer' value={totalOffer} readOnly />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='price-detail-wrap d-flex align-items-baseline'>
                      <h6>
                        Total Customer Payable <span className='asterisk'>*</span>
                      </h6>
                      <div className='lead-input'>
                        <Form.Item
                          name='total_customer_payable'
                          label=''
                          rules={[
                            {
                              required: true,
                              message: 'Please input total amount payable',
                            },
                            {
                              validator: (_, value) =>
                                value <= 0
                                  ? Promise.reject(new Error('The Total Amount Payable should be a valid price'))
                                  : Promise.resolve(),
                            },
                          ]}
                          // rules={priceValidationFunction(
                          //   "Total Customer Payable"
                          // )}
                        >
                          <Input placeholder='Total Customer Payable' value={totalCustomerPay} readOnly />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className='mb-3 me-3'>
                    <Title level={4} className='mb-3'>
                      Image Upload
                    </Title>
                    <Row gutter={[16, 12]} align='middle' className='mb-3'>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className='lead-input'>
                          <Form.Item name='copy_of_rc' layout='horizontal' className='mb-0'>
                            <UploadButton
                              name='copy_of_rc'
                              // documentType="copy_of_rc"
                              fileList={fileList}
                              setFileList={setFileList}
                              fileType={['image/jpeg', 'image/png']}
                              fileSizeLimit={5}
                              label='RC Copy'
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className='lead-input'>
                          <Form.Item name='copy_of_insurance' layout='horizontal' className='mb-0'>
                            <UploadButton
                              name='copy_of_insurance'
                              // documentType="copy_of_insurance"
                              fileList={fileList}
                              setFileList={setFileList}
                              fileType={['image/jpeg', 'image/png']}
                              fileSizeLimit={5}
                              label='Old Car Insurance Copy'
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className='lead-input'>
                          <Form.Item name='copy_of_foreclosure_statement' layout='horizontal' className='mb-0'>
                            <UploadButton
                              name='copy_of_foreclosure_statement'
                              // documentType="copy_of_foreclosure_statement"
                              fileList={fileList}
                              setFileList={setFileList}
                              fileType={['image/jpeg', 'image/png']}
                              fileSizeLimit={5}
                              label='Old Car Foreclosure Statement'
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className='lead-input'>
                          <Form.Item name='copy_of_mr' layout='horizontal' className='mb-0'>
                            <UploadButton
                              name='copy_of_mr'
                              // documentType="copy_of_mr"
                              fileList={fileList}
                              setFileList={setFileList}
                              fileType={['image/jpeg', 'image/png']}
                              fileSizeLimit={5}
                              label='MR Copy'
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className='lead-input'>
                          <Form.Item name='copy_of_order_booklet' layout='horizontal' className='mb-0'>
                            <UploadButton
                              name='copy_of_order_booklet'
                              fileList={fileList}
                              setFileList={setFileList}
                              fileType={['image/jpeg', 'image/png']}
                              fileSizeLimit={5}
                              label='Order Booklet Copy'
                              // documentType="copy_of_order_booklet"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className='user-right d-flex align-items-center justify-content-end flex-md-wrap '>
                <Button type='primary' ghost danger className='primary-button' onClick={handleCancel}>
                  Cancel
                </Button>

                <Button
                  type='primary'
                  className='secondary-button ms-3'
                  htmlType='submit'
                  onClick={() =>
                    leadViewDetails?.booking?.status === 'Booked'
                      ? handleStatusTracker('Booked')
                      : handleStatusTracker('Save')
                  }
                >
                  {editBooking ? 'Update' : 'Save'}
                </Button>

                {leadViewDetails?.booking?.status !== 'Booked' && (
                  <Button
                    type='primary'
                    className='secondary-button mt-md-0 ms-md-3 ms-3'
                    htmlType='submit'
                    onClick={() => handleStatusTracker('Booked')}
                  >
                    Book
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default Bookings;
