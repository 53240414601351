import React, { useEffect, useState } from 'react';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { Button, Space, Table, Tooltip, Select, Card, Form, Row, Col, notification, Popconfirm, Switch } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/input/Search';
import {
  deleteBankService,
  getMasterBankListService,
  updateBankStatusService,
} from '../../../services/master-services';
import Loader from '../../../components/common/Loader';
import { API_RESPONSE_CODES } from '../../../utills/constant';

const Bank = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // State to manage list data
  const [listCount, setListCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filters, setFilters] = useState({
    per_page: 10,
    page: 1,
    search: '',
    in_house: '',
  });

  //useEffect to get bank master data
  useEffect(() => {
    getBankListData(filters);
  }, [filters]);

  const getBankListData = async (data) => {
    try {
      setIsLoading(true);
      const userList = await getMasterBankListService(data);
      setListCount(userList?.data?.data?.total_records);
      setListData(userList?.data?.data?.list_data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handler for editing a bank
  const handleEdit = (record) => {
    navigate(`/admin/masters/bank/edit/${record.id}`);
  };

  // Handler for deleting a bank (currently a placeholder)
  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteBankService(id);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getBankListData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };

  // Handle for inHouse change
  const handleInHouseChange = (value) => {
    if (value) {
      setFilters((prev) => ({
        ...prev,
        in_house: value,
        page: 1,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        in_house: '',
        page: 1,
      }));
    }
  };

  const handleStatusChange = async (checked, record) => {
    try {
      setIsLoading(true);
      let payload = {
        status: checked ? 1 : 0,
      };
      const response = await updateBankStatusService(record.id, payload);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        const updatedData = listData.map((item) => (item.id === record.id ? { ...item, status: checked } : item));
        setListData(updatedData);
        getBankListData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Columns for the table
  const columns = [
    {
      title: 'Bank',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Finance Commission',
      dataIndex: 'finance_commission',
      key: 'finance_commission',
      width: '200px',
      className: 'text-center no-wrap',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'In House',
      dataIndex: 'in_house',
      key: 'in_house',
      width: '200px',
      className: 'text-center no-wrap',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      className: 'action text-center no-wrap',
      render: (text, record) => (
        <Switch
          checked={record.status}
          onChange={(checked) => handleStatusChange(checked, record)}
          checkedChildren='Active'
          unCheckedChildren='Inactive'
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      className: 'action text-center no-wrap',
      render: (text, record) => (
        <Space size='small'>
          <Popconfirm
            title='Are you sure you want to update this master, if you do so the current value of it will be replaced with updated one in all leads (previous and current)?'
            onConfirm={() => handleEdit(record)}
            okButtonProps={{
              loading: false,
            }}
            okText='Yes'
            cancelText='No'
          >
            <Tooltip title='Edit Bank'>
              <Button shape='circle' icon={<EditOutlined />} />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title='Are you sure you want to delete this bank?'
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{
              loading: false,
            }}
            okText='Yes'
            cancelText='No'
          >
            <Tooltip title='Delete Bank'>
              <Button
                shape='circle'
                danger
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className='user-data'>
      <Loader loading={isLoading} />
      <PageContentHeader
        title='Bank'
        headerRight={
          <Button
            type='primary'
            icon={<PlusOutlined />}
            className='secondary-button'
            onClick={() => navigate('/admin/masters/bank/add')}
          >
            New Bank
          </Button>
        }
      />

      {/* Filter Option Card */}
      <Card className='filter-card mb-3'>
        <Form
          layout='vertical'
          name='normal_search'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className='login-input-field'>
            <Row gutter={[16, 16]}>
              {/* Search Bank Filter */}
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name='search'
                  label='Search (By Bank)'
                  // extra="(by Bank)"
                >
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder='Search by bank'
                  />
                </Form.Item>
              </Col>

              {/* In house filter */}
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name='in_house_id' label='In-House'>
                  <Select allowClear placeholder='In-House' className='form-control-ant' onChange={handleInHouseChange}>
                    <Select.Option value='yes'>Yes</Select.Option>
                    <Select.Option value='no'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name="status_ids" label="Status">
                  <Select
                    placeholder="Status"
                    className="form-control-ant"
                    // onChange={handleAreaChange}
                    // disabled={register.territory_id === 0}
                  >
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
          </div>
        </Form>
      </Card>

      <div className='user-table-data'>
        <Table
          columns={columns}
          dataSource={listData}
          scroll={{ x: 'max-content' }}
          rowKey='id'
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount,
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>
    </div>
  );
};

export default Bank;
