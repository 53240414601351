/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  message,
  notification,
} from 'antd';
import { EyeOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  createRetailDetailsService,
  getInsuranceCostService,
  getInsuranceTypeService,
  getRoadTaxCostService,
  getRoadTaxTypeService,
} from '../../../services/master-services';
import Loader from '../../../components/common/Loader';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CommanPreviewImage from '../../../components/common/CommanPreviewImage';
import { fetchLeadDetailsServices, handleTabChange } from '../../auth/slice/authSlice';
import { formatIndianCurrency, priceValidationFunction } from '../../../utills/helpers';
import QuotationVehicleDetails from './QuotationVehicleDetails';

const { Title } = Typography;

const dateFormat = 'DD/MM/YYYY';

const Retail = (props) => {
  const { isLeadConverted } = props;

  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);

  const [isLoading, setIsLoading] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [roadTaxCost, setRoadTaxCost] = useState(leadViewDetails?.booking?.road_tax);
  const [insuranceCost, setInsuranceCost] = useState(leadViewDetails?.booking?.insurance);
  const [roadTaxTypeData, setRoadTaxTypeData] = useState([]);
  const [insuranceTypeData, setInsuranceTypeData] = useState([]);
  // const [exShowroomPrice, setExShowroomPrice] = useState(null);
  const [fileList, setFileList] = useState({
    doucment: null,
  });
  const [excVehicleIds, setExcVehicleIds] = useState({
    make_id: leadViewDetails?.vehicle_detail?.model?.make_id,
    model_id: leadViewDetails?.vehicle_detail?.model?.id,
    vehicle_variant_id: leadViewDetails?.vehicle_detail?.vehicle_variant_id,
    color_type_id: leadViewDetails?.vehicle_detail?.color_id,
    fuel_type_id: leadViewDetails?.vehicle_detail?.fuel_type_id,
    seater_id: leadViewDetails?.vehicle_detail?.seater_id,
  });

  const roleType = useSelector((state) => state?.auth?.userData?.role);

  const vehicleDataEntered =
    !excVehicleIds?.make_id ||
    !excVehicleIds?.model_id ||
    !excVehicleIds?.vehicle_variant_id ||
    !excVehicleIds?.color_type_id ||
    !excVehicleIds?.fuel_type_id ||
    !excVehicleIds?.seater_id;

  useEffect(() => {
    form.setFieldsValue({
      amc: leadViewDetails?.booking?.amc,
      body_care_3m: leadViewDetails?.booking?.body_care_3m,
      ew: leadViewDetails?.booking?.ew,
      tcs: leadViewDetails?.booking?.tcs,
      road_tax: leadViewDetails?.booking?.road_tax,
      cost_of_registration: leadViewDetails?.booking?.cost_of_registration,
      insurance: leadViewDetails?.booking?.insurance,
      insurance_type_id: leadViewDetails?.booking?.insurance_type?.id,
      road_tax_type_id: leadViewDetails?.booking?.road_tax_type?.id,
      fastag: leadViewDetails?.booking?.fastag,
      commercial_rebate_discount: leadViewDetails?.booking?.commercial_rebate_discount,
      rto_cost: leadViewDetails?.booking?.rto_cost,
      logistic_charges: leadViewDetails?.booking?.logistic_charges,
      ex_showroom_price: leadViewDetails?.booking?.ex_showroom_price,
      tentative_accessories: leadViewDetails?.booking?.tentative_accessories,
      on_road_price: leadViewDetails?.booking?.on_road_price,
      in_house: leadViewDetails?.booking?.in_house,
    });

    calculateOnRoadPrice();
  }, [leadViewDetails, form]);

  useEffect(() => {
    getRoadTaxTypeData();
    getInsuranceTypeData();
    // handleInsuranceTypeChange(leadViewDetails?.booking?.insurance_type?.id);
    // handleRoadTaxTypeChange(leadViewDetails?.booking?.road_tax_type?.id);
    if (leadViewDetails?.retail?.insurance) {
      setInsuranceCost(leadViewDetails?.retail?.insurance);
    }
    if (leadViewDetails?.retail?.road_tax) {
      setRoadTaxCost(leadViewDetails?.retail?.road_tax);
    }
  }, []);

  useEffect(() => {
    calculateOnRoadPrice();
  }, [insuranceCost, roadTaxCost, excVehicleIds]);

  useEffect(() => {
    if (vehicleDataEntered) {
      form.setFieldsValue({
        road_tax: null,
        insurance: null,
      });
      setInsuranceCost('');
      setRoadTaxCost('');
    } else {
      // handleInsuranceTypeChange(leadViewDetails?.booking?.insurance_type?.id);
      // handleRoadTaxTypeChange(leadViewDetails?.booking?.road_tax_type?.id);
    }
  }, [excVehicleIds]);

  const calculateOnRoadPrice = () => {
    const values = form.getFieldsValue();

    const {
      ex_showroom_price,
      tcs,
      rto_cost,
      cost_of_registration,
      ew,
      amc,
      tentative_accessories,
      fastag,
      body_care_3m,
      insurance,
      logistic_charges,
    } = values;

    const onRoadPrice = [
      ex_showroom_price,
      tcs,
      cost_of_registration,
      ew,
      amc,
      tentative_accessories,
      fastag,
      body_care_3m,
      logistic_charges,
      rto_cost,
      // insuranceCost,
      insurance,
      roadTaxCost,
    ]?.reduce((acc, curr) => acc + (parseFloat(curr) || 0), 0);

    form.setFieldsValue({
      on_road_price: onRoadPrice?.toFixed(2),
    });
  };

  const retailData = leadViewDetails?.retail;
  const isRetailed = leadViewDetails?.retail?.model?.model_name?.length > 0;
  const isExchangeEvaluationTeam = roleType === 'Exchange Evaluation Team';

  const validateFile = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file');
      return Upload.LIST_IGNORE; // Reject file upload
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must be smaller than 10MB');
      return Upload.LIST_IGNORE; // Reject file upload
    }

    return true; // Allow file upload
  };

  const handleFileChange = (info, field) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);

    setFileList((prev) => ({
      ...prev,
      [field]: newFileList,
    }));
  };

  const handleSave = async (values) => {
    delete values.document;
    delete values.iconnect_doc;
    values.lead_id = leadViewDetails?.id;
    values.booking_id = leadViewDetails?.booking?.id;
    values.retail_date = values.retail_date ? dayjs(values?.retail_date).format('YYYY/MM/DD') : '';

    const formData = new FormData();

    Object.keys(fileList).forEach((key) => {
      if (fileList[key]?.length > 0 && fileList[key][0].originFileObj) {
        formData.append(key, fileList[key][0].originFileObj);
      }
    });
    const filteredValues = Object.fromEntries(Object.entries(values).filter(([, v]) => v !== undefined && v !== null));

    for (let key in filteredValues) {
      formData.append(key, filteredValues[key]);
    }
    try {
      setIsLoading(true);
      const res = await createRetailDetailsService(formData);
      if (res.data.status === API_RESPONSE_CODES.OK || res.data.status === API_RESPONSE_CODES.CREATED) {
        form.resetFields();
        notification.success({
          message: res.data.message,
        });
        dispatch(fetchLeadDetailsServices({ id: id }));
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
        style: {
          width: 400,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRoadTaxTypeData = async () => {
    setIsLoading(true);
    try {
      const roadTaxData = await getRoadTaxTypeService();
      setRoadTaxTypeData(roadTaxData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getInsuranceTypeData = async () => {
    setIsLoading(true);
    try {
      const insuranceData = await getInsuranceTypeService();
      setInsuranceTypeData(insuranceData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
  };

  const handleViewBooking = () => {
    dispatch(handleTabChange('bookings'));
  };

  const handleInsuranceTypeChange = async (val) => {
    const payload = {
      vehicle_model_id: excVehicleIds?.model_id,
      vehicle_variant_id: excVehicleIds?.vehicle_variant_id,
      fuel_type_id: excVehicleIds?.fuel_type_id,
      seater_id: excVehicleIds?.seater_id,
      color_id: excVehicleIds?.color_type_id,
      insurance_type_id: val ? val : leadViewDetails?.booking?.insurance_type_id,
    };
    const insuranceCost = await getInsuranceCostService(payload);
    if (insuranceCost.data.status === API_RESPONSE_CODES.OK) {
      setInsuranceCost(insuranceCost?.data?.data);
      form.setFieldsValue({
        insurance: insuranceCost?.data?.data,
      });
    }
  };

  const handleRoadTaxTypeChange = async (val) => {
    const payload = {
      vehicle_model_id: excVehicleIds?.model_id,
      vehicle_variant_id: excVehicleIds?.vehicle_variant_id,
      fuel_type_id: excVehicleIds?.fuel_type_id,
      seater_id: excVehicleIds?.seater_id,
      color_id: excVehicleIds?.color_type_id,
      road_tax_id: val ? val : leadViewDetails?.booking?.insurance_type_id,
    };
    const roadTaxTypeCost = await getRoadTaxCostService(payload);
    if (roadTaxTypeCost.data.status === API_RESPONSE_CODES.OK) {
      setRoadTaxCost(roadTaxTypeCost?.data?.data);
      form.setFieldsValue({
        road_tax: roadTaxTypeCost?.data?.data,
      });
    }
  };

  // Handler to update Ex-showroom price and calculate TCS (1% of Ex-showroom)
  // const handleExShowroomChange = (e) => {
  //   const value = e.target.value;
  //   setExShowroomPrice(value);
  //   setTcs((value && !isNaN(value)) ? (value * 0.01).toFixed(2) : null); // Calculate 1% TCS
  // };

  const [tcsValue, setTcsValue] = useState(0);

  const handleExShowroomChange = (e) => {
    const exShowroomPrice = parseFloat(e.target.value) || 0;

    // Check if the ex-showroom price is greater than 10 lakhs (1,000,000)
    if (exShowroomPrice > 1000000) {
      const calculatedTCS = (exShowroomPrice * 1) / 100; // 1% of ex showroom price
      setTcsValue(calculatedTCS); // Update the TCS value and round to 2 decimals
      form.setFieldsValue({ tcs: calculatedTCS }); // Set the TCS field value in the form
      calculateOnRoadPrice();
    } else {
      setTcsValue(0); // Set TCS to 0 for values 10 lakhs or less
      form.setFieldsValue({ tcs: 0 }); // Update the TCS field in the form
      calculateOnRoadPrice();
    }
  };

  return (
    <div className='user-data lead-detail'>
      <Loader loading={isLoading} />
      <div className='user-left'>
        <Title level={4} className=''>
          Retail Detail
        </Title>
      </div>
      {!isExchangeEvaluationTeam ? (
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSave}
          onValuesChange={(_, allValues) => {
            calculateOnRoadPrice(allValues);
          }}
        >
          <div>
            <Row gutter={20}>
              {/* Booking redirect icon */}
              <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                <div className='lead-form-label d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2'>Booking Details:</h6>
                  <div className='m-0 w-50 value'>
                    <div>
                      {
                        <Space className='mt-2 mb-4'>
                          <Button shape='circle' icon={<EyeOutlined />} onClick={handleViewBooking} />
                        </Space>
                      }
                    </div>
                  </div>
                </div>
              </Col>

              {/* Engine Number */}
              <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                <div className='lead-form-label d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2'>
                    Engine Number:
                    {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                  </h6>
                  <div className='m-0 w-50 value mb-sm-2'>
                    {retailData?.engine_number || isLeadConverted ? (
                      <>{retailData?.engine_number ? retailData?.engine_number : '-'}</>
                    ) : (
                      <Form.Item
                        label=''
                        name='engine_number'
                        rules={[
                          {
                            required: true,
                            message: 'Please input the engine number',
                          },
                          {
                            pattern: /^[a-zA-Z0-9]{1,30}$/,
                            message: 'Engine number must be 1-30 alphanumeric characters only',
                          },
                        ]}
                      >
                        <Input placeholder='Engine Number' />
                      </Form.Item>
                    )}
                  </div>
                </div>
              </Col>

              {/* Retail Date */}
              <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                <div className='lead-form-label d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2'>
                    Retail Date:
                    {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                  </h6>
                  <div className='m-0 w-50 value mb-sm-2'>
                    {retailData?.retail_date || isLeadConverted ? (
                      <>{retailData?.retail_date ? dayjs(retailData?.retail_date).format('DD/MM/YYYY') : '-'} </>
                    ) : (
                      <Form.Item
                        label=''
                        name='retail_date'
                        rules={[
                          {
                            required: true,
                            message: 'Please input retail date ',
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={(current) => current && current < dayjs().startOf('day')}
                          format={dateFormat}
                          placeholder='Select Date'
                        />
                      </Form.Item>
                    )}
                  </div>
                </div>
              </Col>

              {/* Upload Picture */}
              <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                <div
                  className={`lead-form-label d-flex align-items-baseline flex-nowrap ${
                    leadViewDetails?.retail?.document_path ? 'picture-uploaded' : ''
                  }`}
                >
                  <h6 className='me-2'>
                    {leadViewDetails?.retail?.document_path ? (
                      'Uploaded Picture:'
                    ) : (
                      <>
                        Upload Pictures:
                        {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                      </>
                    )}
                  </h6>
                  <div className='m-0 w-50 value mb-sm-2'>
                    {leadViewDetails?.retail?.document_path || isLeadConverted ? (
                      <CommanPreviewImage width={70} height={70} src={leadViewDetails?.retail?.document_path || '#'} />
                    ) : (
                      <Form.Item
                        label=''
                        name='document'
                        rules={[
                          {
                            required: true,
                            message: 'Please upload picture',
                          },
                        ]}
                      >
                        <div className='exchange-upload '>
                          <Upload
                            maxCount={1}
                            beforeUpload={validateFile}
                            fileList={fileList?.inside_image}
                            accept='.jpg,.jpeg,.png,.gif'
                            maxFileSize={10 * 1024 * 1024}
                            customRequest={({ onSuccess }) => {
                              onSuccess('ok');
                            }}
                            onChange={(info) => handleFileChange(info, 'document')}
                          >
                            <Button icon={<UploadOutlined />}></Button>
                          </Upload>
                        </div>
                      </Form.Item>
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                <div className='lead-form-label d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2'>
                    Chassis Number:
                    {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                  </h6>
                  <div className='m-0 w-50 value mb-sm-2'>
                    {retailData?.chassis_number || isLeadConverted ? (
                      <>{retailData?.chassis_number ? retailData?.chassis_number : '-'}</>
                    ) : (
                      <Form.Item
                        name='chassis_number'
                        className='mb-0'
                        rules={[
                          {
                            required: true,
                            message: 'Please input the chassis number',
                          },
                          {
                            pattern: /^[a-zA-Z0-9]{1,30}$/,
                            message: 'Chassis number must be 1-30 alphanumeric characters only',
                          },
                        ]}
                      >
                        <Input className='' placeholder='Chassis Number' />
                      </Form.Item>
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                <div
                  className={`lead-form-label d-flex align-items-baseline flex-nowrap ${
                    leadViewDetails?.retail?.iconnect_doc_path ? 'picture-uploaded' : ''
                  }`}
                >
                  <h6 className='me-2'>
                    {leadViewDetails?.retail?.iconnect_doc_path ? (
                      'IConnect:'
                    ) : (
                      <>
                        IConnect:
                        {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                      </>
                    )}
                  </h6>
                  <div className='m-0 w-50 value mb-sm-2'>
                    {leadViewDetails?.retail?.iconnect_doc_path || isLeadConverted ? (
                      <CommanPreviewImage
                        width={70}
                        height={70}
                        src={leadViewDetails?.retail?.iconnect_doc_path || '#'}
                      />
                    ) : (
                      <Form.Item
                        label=''
                        name='iconnect_doc'
                        className='mb-0'
                        rules={[
                          {
                            required: true,
                            message: 'Please upload iconnect picture',
                          },
                        ]}
                      >
                        <div className='exchange-upload '>
                          <Upload
                            maxCount={1}
                            beforeUpload={validateFile}
                            fileList={fileList?.inside_image}
                            accept='.jpg,.jpeg,.png,.gif'
                            maxFileSize={10 * 1024 * 1024}
                            customRequest={({ onSuccess }) => {
                              onSuccess('ok');
                            }}
                            onChange={(info) => handleFileChange(info, 'iconnect_doc')}
                          >
                            <Button icon={<UploadOutlined />}></Button>
                          </Upload>
                        </div>
                      </Form.Item>
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={20} lg={16} md={24} sm={24} xs={24}>
                <div className='lead-form-label feedbacks d-flex align-items-center flex-nowrap'>
                  <h6 className='me-2'>
                    Delivery Feedbacks:
                    {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                  </h6>
                  <div className='m-0 w-50 value mb-sm-2'>
                    {leadViewDetails?.retail?.delivery_feedback || isLeadConverted ? (
                      <>{retailData?.delivery_feedback ? retailData?.delivery_feedback : '-'}</>
                    ) : (
                      <Form.Item
                        name='delivery_feedback'
                        className='mb-0'
                        rules={[
                          {
                            required: true,
                            message: 'Please input delivery feedback ',
                          },
                          {
                            max: 250,
                            message: 'Maximum length of 250 words allowed',
                          },
                        ]}
                      >
                        <Input.TextArea className='' placeholder='Delivery Feedbacks' />
                      </Form.Item>
                    )}
                  </div>
                </div>
              </Col>

              {/* Vehicle Details - Model, Variant, Color, Fuel and Seater */}

              {!isRetailed && !isLeadConverted && (
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Title level={4} className=''>
                        Vehicle Details
                      </Title>
                    </div>
                    <Row gutter={[16, 0]} align='middle' className='mb-3'>
                      <QuotationVehicleDetails
                        form={form}
                        masterData={masterData}
                        setIsLoading={setIsLoading}
                        setMasterData={setMasterData}
                        excVehicleIds={excVehicleIds}
                        setExcVehicleIds={setExcVehicleIds}
                      />
                    </Row>
                  </div>
                </Col>
              )}

              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='mt-5'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Title level={4} className=''>
                      Price Details
                    </Title>
                  </div>
                  {/* Model Name when retailed  or converted*/}
                  {(leadViewDetails?.retail?.model?.model_name || isLeadConverted) && (
                    <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                      <h6>Model:</h6>
                      <div className='mb-3'>
                        {leadViewDetails?.retail?.model?.model_name ? leadViewDetails?.retail?.model?.model_name : '-'}
                      </div>
                    </div>
                  )}
                  {/* Variant Name when retailed  or converted*/}
                  {(leadViewDetails?.retail?.variant?.variant_code || isLeadConverted) && (
                    <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                      <h6>Variant:</h6>
                      <div className='mb-3'>
                        {leadViewDetails?.retail?.variant?.variant_code
                          ? leadViewDetails?.retail?.variant?.variant_code
                          : '-'}
                      </div>
                    </div>
                  )}
                  {/* Color Name when retailed  or converted*/}
                  {(leadViewDetails?.retail?.color?.color || isLeadConverted) && (
                    <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                      <h6>Color:</h6>
                      <div className='mb-3'>
                        {leadViewDetails?.retail?.color?.color ? leadViewDetails?.retail?.color?.color : '-'}
                      </div>
                    </div>
                  )}
                  {/* Fuel Name when retailed  or converted*/}
                  {(leadViewDetails?.retail?.fuel_type?.title || isLeadConverted) && (
                    <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                      <h6>Fuel:</h6>
                      <div className='mb-3'>
                        {leadViewDetails?.retail?.fuel_type?.title ? leadViewDetails?.retail?.fuel_type?.title : '-'}
                      </div>
                    </div>
                  )}
                  {/* Seater when retailed  or converted*/}
                  {(leadViewDetails?.retail?.seater?.seater || isLeadConverted) && (
                    <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                      <h6>Seater:</h6>
                      <div className='mb-3'>
                        {leadViewDetails?.retail?.seater?.seater ? leadViewDetails?.retail?.seater?.seater : '-'}
                      </div>
                    </div>
                  )}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      EX-Showroom:
                      {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.ex_showroom_price !== undefined &&
                        leadViewDetails?.retail?.ex_showroom_price !== null) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.ex_showroom_price
                            ? formatIndianCurrency(leadViewDetails?.retail?.ex_showroom_price)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item
                          name='ex_showroom_price'
                          label=''
                          rules={priceValidationFunction('Ex-Showroom price')}
                        >
                          <Input
                            placeholder='Ex-Showroom Price'
                            // value={exShowroomPrice}
                            onChange={handleExShowroomChange}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>

                  {/* TCS */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      TCS:
                      {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.tcs !== undefined && leadViewDetails?.retail?.tcs !== null) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.tcs ? formatIndianCurrency(leadViewDetails?.retail?.tcs) : '-'}
                        </div>
                      ) : (
                        <Form.Item name='tcs' label='' rules={priceValidationFunction('TCS')}>
                          <Input placeholder='TCS' value={tcsValue} readOnly />
                        </Form.Item>
                      )}
                    </div>
                  </div>

                  {/* Insurance Type */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      Insurance Type:
                      {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                    </h6>
                    <div>
                      {leadViewDetails?.retail?.insurance?.insurance_type?.type || isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.insurance_type?.type
                            ? leadViewDetails?.retail?.insurance_type?.type
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item
                          label=''
                          name='insurance_type_id'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your insurance type ',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Insurance Type'
                            onChange={handleInsuranceTypeChange}
                            disabled={vehicleDataEntered}
                          >
                            {insuranceTypeData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.type}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </div>

                  {/* Insurance */}
                  {!isNaN(insuranceCost) && !vehicleDataEntered ? (
                    <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                      <h6>
                        Insurance:
                        {!isRetailed && !isLeadConverted && <span className='asterisk inline-block ml-1'>*</span>}
                      </h6>
                      <div>
                        {leadViewDetails?.retail?.insurance || isLeadConverted ? (
                          <div className='mb-3'>{insuranceCost ? formatIndianCurrency(insuranceCost) : '-'}</div>
                        ) : (
                          // <Form.Item label='' name='insurance' rules={priceValidationFunction('Insurance')}>
                          //   {insuranceCost ? formatIndianCurrency(insuranceCost) : '-'}
                          // </Form.Item>
                          <Form.Item name='insurance' label='' rules={priceValidationFunction('Insurance')}>
                            <Input placeholder='Insurance' />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* RTO Cost */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      RTO Cost:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.rto_cost !== null &&
                        leadViewDetails?.retail?.rto_cost !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.rto_cost
                            ? formatIndianCurrency(leadViewDetails?.retail?.rto_cost)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item label='' name='rto_cost' rules={priceValidationFunction('RTO Cost')}>
                          <Input placeholder='RTO Cost' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* Cost of Registration */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      Cost of Registration:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.cost_of_registration !== null &&
                        leadViewDetails?.retail?.cost_of_registration !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.cost_of_registration
                            ? formatIndianCurrency(leadViewDetails?.retail?.cost_of_registration)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item
                          label=''
                          name='cost_of_registration'
                          rules={priceValidationFunction('Cost of Registration')}
                        >
                          <Input placeholder='Cost of Registration' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* EW */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      Extended Warranty:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.ew !== null && leadViewDetails?.retail?.ew !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.ew ? formatIndianCurrency(leadViewDetails?.retail?.ew) : '-'}
                        </div>
                      ) : (
                        <Form.Item name='ew' label='' rules={priceValidationFunction('Extended Warranty')}>
                          <Input placeholder='Extended Warranty' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* AMC */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      AMC:
                      {/* {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>} */}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.amc !== null && leadViewDetails?.retail?.amc !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.amc ? formatIndianCurrency(leadViewDetails?.retail?.amc) : '-'}
                        </div>
                      ) : (
                        <Form.Item name='amc' label='' rules={priceValidationFunction('AMC')}>
                          <Input placeholder='AMC' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* Accessories */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      Tentative Accessories:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.tentative_accessories !== null &&
                        leadViewDetails?.retail?.tentative_accessories !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.tentative_accessories
                            ? formatIndianCurrency(leadViewDetails?.retail?.tentative_accessories)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item
                          name='tentative_accessories'
                          label=''
                          rules={priceValidationFunction('Tentative Accessories')}
                        >
                          <Input placeholder='Tentative Accessories' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* Fastag */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      Fastag:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.fastag !== null && leadViewDetails?.retail?.fastag !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.fastag
                            ? formatIndianCurrency(leadViewDetails?.retail?.fastag)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item name='fastag' label='' rules={priceValidationFunction('Fastag')}>
                          <Input placeholder='Fastag' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* T-Gloss */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      T-Gloss:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.body_care_3m !== null &&
                        leadViewDetails?.retail?.body_care_3m !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.body_care_3m
                            ? formatIndianCurrency(leadViewDetails?.retail?.body_care_3m)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item name='body_care_3m' label='' rules={priceValidationFunction('T-Gloss')}>
                          <Input placeholder='T-Gloss' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* Logistics */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      Other Logistics Charges:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.logistic_charges !== null &&
                        leadViewDetails?.retail?.logistic_charges !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.logistic_charges
                            ? formatIndianCurrency(leadViewDetails?.retail?.logistic_charges)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item
                          name='logistic_charges'
                          label=''
                          rules={priceValidationFunction('Logistics Charges')}
                        >
                          <Input placeholder='Other Logistcs Charges' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* Road Tax Type*/}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      Road Tax Type:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.road_tax !== null &&
                        leadViewDetails?.retail?.road_tax !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.road_tax_type?.type
                            ? leadViewDetails?.retail?.road_tax_type?.type
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item
                          label=''
                          name='road_tax_type_id'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your road tax type ',
                            },
                          ]}
                        >
                          <Select
                            onChange={handleRoadTaxTypeChange}
                            disabled={vehicleDataEntered}
                            placeholder='Road Tax Type'
                          >
                            {roadTaxTypeData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.type}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  {/* Road Tax */}
                  {!isNaN(roadTaxCost) && !vehicleDataEntered ? (
                    <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                      <h6>
                        Road Tax:
                        {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                      </h6>
                      <div>
                        {leadViewDetails?.retail?.road_tax?.type?.type || isLeadConverted ? (
                          <div className='mb-3'>{roadTaxCost ? formatIndianCurrency(roadTaxCost) : '-'}</div>
                        ) : (
                          <Form.Item> {roadTaxCost ? formatIndianCurrency(roadTaxCost) : '-'}</Form.Item>
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* On Road Price */}
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      On Road Price:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.on_road_price !== null &&
                        leadViewDetails?.retail?.on_road_price !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.on_road_price
                            ? formatIndianCurrency(leadViewDetails?.retail?.on_road_price)
                            : '-'}
                        </div>
                      ) : (
                        <Form.Item name='on_road_price' label='' rules={priceValidationFunction('On Road Price ')}>
                          <Input placeholder='On Road Price' />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                    <h6>
                      In House:
                      {!isRetailed && !isLeadConverted && <span className='asterisk'>*</span>}
                    </h6>
                    <div>
                      {(leadViewDetails?.retail?.in_house !== null &&
                        leadViewDetails?.retail?.in_house !== undefined) ||
                      isLeadConverted ? (
                        <div className='mb-3'>
                          {leadViewDetails?.retail?.in_house ? leadViewDetails?.retail?.in_house : '-'}
                        </div>
                      ) : (
                        <Form.Item
                          name='in_house'
                          label=''
                          rules={[
                            {
                              required: true,
                              message: 'Please select an option',
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Yes/No'>
                            <Select.Option value='Yes'>Yes</Select.Option>
                            <Select.Option value='No'>No</Select.Option>
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {!leadViewDetails?.retail?.engine_number && !isLeadConverted && (
            <div className='user-right d-flex align-items-center justify-content-end mt-4'>
              <Button type='primary' ghost danger className='primary-button me-4' onClick={handleCancel}>
                Cancel
              </Button>
              <Button htmlType='submit' type='primary' className='secondary-button'>
                Save
              </Button>
            </div>
          )}
        </Form>
      ) : (
        <div className='detail-wrapper pt-2'>
          <div className='view-input-field'>
            <Row gutter={20}>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2 label'>Engine Number:</h6>
                  <div className='m-0 w-50 value'>
                    {leadViewDetails?.retail?.engine_number ? <>{retailData?.engine_number}</> : '-'}
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2 label'>Retail Date:</h6>
                  <div className='m-0 w-50 value'>
                    {leadViewDetails?.retail?.retail_date ? (
                      <>{dayjs(retailData?.retail_date).format('DD/MM/YYYY')} </>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2 label'>Uploaded Picture:</h6>
                  <div className='m-0 w-50 value'>
                    {leadViewDetails?.retail?.document_path ? (
                      <CommanPreviewImage width={100} height={100} src={leadViewDetails?.retail?.document_path} />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-center flex-nowrap'>
                  <h6 className='me-2 label'>Delivery Feedbacks:</h6>
                  <div className='m-0 w-50 value '>
                    {leadViewDetails?.retail?.delivery_feedback ? <>{retailData?.delivery_feedback}</> : '-'}
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2 label'>IConnect:</h6>
                  <div className='m-0 w-50 value'>
                    {leadViewDetails?.retail?.iconnect_doc_path ? (
                      <CommanPreviewImage width={100} height={100} src={leadViewDetails?.retail?.iconnect_doc_path} />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline flex-nowrap'>
                  <h6 className='me-2 label'>Chassis Number:</h6>
                  <div className='m-0 w-50 value'>
                    {leadViewDetails?.retail?.chassis_number ? <>{retailData?.chassis_number}</> : '-'}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default Retail;
