import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Space, Tag, Typography, notification, Tooltip } from 'antd';
import CancellationModal from './CancelBooking';
import Loader from '../../../../components/common/Loader';
import { cancelBooking } from '../../../../services/master-services';
import dayjs from 'dayjs';
import PageContentHeader from '../../../../components/common/PageContentHeader';
import { formatIndianCurrency } from '../../../../utills/helpers';
import { fetchLeadDetailsServices } from '../../../auth/slice/authSlice';
import CommanPreviewImage from '../../../../components/common/CommanPreviewImage';

const { Title } = Typography;

const BookingDetails = (props) => {
  const { setBookingDetailsShow, setEditBooking, isExchangeEvaluationTeam } = props;
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dateFormat = 'DD/MM/YYYY';

  const [remark, setRemark] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [checkedReasons, setCheckedReasons] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const isLeadConverted =
    leadViewDetails?.lead_status?.name === 'Converted' ||
    leadViewDetails?.lead_status?.name === 'Lost' ||
    leadViewDetails?.lead_status?.name === 'Dropped';
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  const getDocumentInfo = (type) => {
    return leadViewDetails?.documents?.find((doc) => doc.document_type === type);
  };

  const renderDocumentLink = (type, label) => {
    const docInfo = getDocumentInfo(type);
    return docInfo ? (
      <>
        <div className='view-fields d-flex align-items-baseline'>
          <h6 className='label' name={`${type.toLowerCase()}_image`}>
            {label}:
          </h6>
          <p className='value'>
            <a href={docInfo.document_path} target='_blank' rel='noopener noreferrer'>
              {docInfo.document_file_name}
            </a>
          </p>
        </div>
        {/* <Form.Item
                name={`${type.toLowerCase()}_image`}
                label={label}
                layout="horizontal"
                // className="form-item-horizontal"
                className="upload-image-label"
            >
                <a href={docInfo.document_path} target="_blank" rel="noopener noreferrer">
                    {docInfo.document_file_name}
                </a>
            </Form.Item> */}
      </>
    ) : (
      <>
        <div className='view-fields d-flex align-items-baseline'>
          <h6 className='label' name={`${type.toLowerCase()}_image`}>
            {label}:
          </h6>
          <p className='value'>
            <span>No file available</span>
          </p>
        </div>
        {/* <Form.Item
                    name={`${type.toLowerCase()}_image`}
                    label={label}
                    layout="horizontal"
                    className="mb-0"
                >
                    <span>No file available</span>
                </Form.Item> */}
      </>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (files) => {
    setIsLoading(true);
    const reason_details = checkedReasons?.map((reason) => ({ reason }));
    const formData = new FormData();
    formData.append('lead_id', id);
    formData.append('remark', remark);
    reason_details.forEach((detail, index) => {
      formData.append(`reason_details[${index}][reason]`, detail.reason);
    });
    if (files?.length > 0) {
      files.forEach((file) => formData.append('cancellation_letter', file));
    }
    try {
      const response = await cancelBooking(formData);
      notification.success({
        message: response?.data?.message,
      });
      form.resetFields();
      // navigate(-1);
      setIsModalVisible(false);
      setIsLoading(false);
      dispatch(fetchLeadDetailsServices({ id: id }));
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
        style: {
          width: 400,
        },
      });
      dispatch(fetchLeadDetailsServices({ id: id }));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxChange = (checkedValues) => {
    setCheckedReasons(checkedValues);
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const BookingDetailsSection = () => (
    <div className='detail-wrapper'>
      <div className='view-input-field view-booking'>
        <Row gutter={20} justify='start'>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <div className='mb-3 me-3'>
              <Title level={4} className='mb-3'>
                Basic Details{' '}
              </Title>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Expected Date of Delivery:</h6>
                <p className='value'>
                  {leadViewDetails?.expected_delivery_date
                    ? dayjs(leadViewDetails?.expected_delivery_date).format(dateFormat)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Choice of Vehicle Number:</h6>
                <p className='value'>{leadViewDetails?.choice_vehicle_no ? leadViewDetails?.choice_vehicle_no : '-'}</p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>RTO Location:</h6>
                <p className='value'>
                  {leadViewDetails?.customer?.rto_location?.name || leadViewDetails?.booking?.rto_location?.name || '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Social Occasion:</h6>
                <p className='value'>{leadViewDetails?.social_occassion ? leadViewDetails?.social_occassion : '-'}</p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Exchange:</h6>
                <p className='value'>{leadViewDetails?.exchange_car ? leadViewDetails?.exchange_car : '-'}</p>
              </div>
            </div>
          </Col>

          {leadViewDetails?.exchange_car === 'Yes' && (
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className='mb-3 ms-0'>
                <Title level={4} className='mb-3'>
                  Exchange Related Details
                </Title>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Exchange Car:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.model?.model_name
                      ? leadViewDetails?.exchange_vehicle_detail?.model?.model_name
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Value Offered:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.value_offered
                      ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.value_offered)
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Exchange Car Year:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.car_year
                      ? leadViewDetails?.exchange_vehicle_detail?.car_year
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Customer Agree to Exchange:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.customer_agree_to_exchange
                      ? leadViewDetails?.exchange_vehicle_detail?.customer_agree_to_exchange
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Odometer:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.odometer
                      ? `${leadViewDetails?.exchange_vehicle_detail?.odometer} Kms`
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Foreclouser Responsibility:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.foreclosure_responsibility
                      ? leadViewDetails?.exchange_vehicle_detail?.foreclosure_responsibility
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Hypothecation on Car:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.hypothecation_on_care
                      ? leadViewDetails?.exchange_vehicle_detail?.hypothecation_on_care
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Foreclouser Cost:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.foreclosure_cost
                      ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.foreclosure_cost)
                      : '-'}
                  </p>
                </div>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6 className='label'>Vehicle Handed Over to Exchange Team:</h6>
                  <p className='value'>
                    {leadViewDetails?.exchange_vehicle_detail?.vehicle_handed_over_to_exchange_team
                      ? leadViewDetails?.exchange_vehicle_detail?.vehicle_handed_over_to_exchange_team
                      : '-'}
                  </p>
                </div>
              </div>
            </Col>
          )}

          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <div className='mb-3 ms-0'>
              <Title level={4} className='mb-3'>
                Car Details
              </Title>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Model:</h6>
                <p className='value'>
                  {leadViewDetails?.vehicle_detail?.model?.model_name
                    ? leadViewDetails?.vehicle_detail?.model?.model_name
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Variant:</h6>
                <p className='value'>
                  {leadViewDetails?.vehicle_detail?.variant?.variant_code
                    ? leadViewDetails?.vehicle_detail?.variant?.variant_code
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Color:</h6>
                <p className='value'>
                  {leadViewDetails?.vehicle_detail?.color?.color ? leadViewDetails?.vehicle_detail?.color?.color : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Fuel:</h6>
                <p className='value'>
                  {leadViewDetails?.vehicle_detail?.fuel_type?.title
                    ? leadViewDetails?.vehicle_detail?.fuel_type?.title
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Seater:</h6>
                <p className='value'>
                  {leadViewDetails?.vehicle_detail?.seater?.seater
                    ? leadViewDetails?.vehicle_detail?.seater?.seater
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>EX-Showroom:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.ex_showroom_price
                    ? formatIndianCurrency(leadViewDetails?.booking?.ex_showroom_price)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>TCS:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.tcs ? formatIndianCurrency(leadViewDetails?.booking?.tcs) : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Insurance Type:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.insurance_type?.type
                    ? leadViewDetails?.booking?.insurance_type?.type
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Insurance:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.insurance
                    ? formatIndianCurrency(leadViewDetails?.booking?.insurance)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>RTO:</h6>
                <p className='value'>
                  {leadViewDetails?.customer?.rto_location?.name ? leadViewDetails?.customer?.rto_location?.name : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>RTO Cost:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.rto_cost ? formatIndianCurrency(leadViewDetails?.booking?.rto_cost) : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Cost of Registration:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.cost_of_registration
                    ? formatIndianCurrency(leadViewDetails?.booking?.cost_of_registration)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Extended Warranty:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.ew ? formatIndianCurrency(leadViewDetails?.booking?.ew) : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>AMC:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.amc ? formatIndianCurrency(leadViewDetails?.booking?.amc) : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Tentative Accessories:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.tentative_accessories
                    ? formatIndianCurrency(leadViewDetails?.booking?.tentative_accessories)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Fastag:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.fastag ? formatIndianCurrency(leadViewDetails?.booking?.fastag) : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>T-Gloss:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.body_care_3m
                    ? formatIndianCurrency(leadViewDetails?.booking?.body_care_3m)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Other Logistcs Charges:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.logistic_charges
                    ? formatIndianCurrency(leadViewDetails?.booking?.logistic_charges)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Road Tax Type:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.road_tax_type?.type ? leadViewDetails?.booking?.road_tax_type?.type : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Road Tax:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.road_tax ? formatIndianCurrency(leadViewDetails?.booking?.road_tax) : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>On Road Price:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.on_road_price
                    ? formatIndianCurrency(leadViewDetails?.booking?.on_road_price)
                    : '-'}
                </p>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <div className='mb-3 ms-0'>
              <Title level={4} className='mb-3'>
                Payment Related Details
              </Title>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Financer Name:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.financer?.name ? leadViewDetails?.booking?.financer?.name : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>In House:</h6>
                <p className='value'>{leadViewDetails?.booking?.in_house ? leadViewDetails?.booking?.in_house : '-'}</p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Booking Amount Recived:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.booking_amt_received
                    ? formatIndianCurrency(leadViewDetails?.booking?.booking_amt_received)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>MR Number:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.mr_number ? leadViewDetails?.booking?.mr_number : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>DD Received:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.dd_received ? leadViewDetails?.booking?.dd_received : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>DD Amount:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.dd_amt ? formatIndianCurrency(leadViewDetails?.booking?.dd_amt) : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>DD/Sanction Letter Amount:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.sanction_letter_amt
                    ? formatIndianCurrency(leadViewDetails?.booking?.sanction_letter_amt)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>DD/Sanction MR number:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.sanction_mr_number ? leadViewDetails?.booking?.sanction_mr_number : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>UPI/Net Banking Amount Received:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.net_banking_amt_received
                    ? formatIndianCurrency(leadViewDetails?.booking?.net_banking_amt_received)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>UPI/Net Banking Number:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.net_banking_number ? leadViewDetails?.booking?.net_banking_number : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Cash Amount Recived:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.cash_amt_received
                    ? formatIndianCurrency(leadViewDetails?.booking?.cash_amt_received)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Cash Amount MR:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.cash_amount_mr ? leadViewDetails?.booking?.cash_amount_mr : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Total payment Received:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.total_payment_received
                    ? formatIndianCurrency(leadViewDetails?.booking?.total_payment_received)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Net Customer Balance:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.net_customer_balance
                    ? formatIndianCurrency(leadViewDetails?.booking?.net_customer_balance)
                    : '-'}
                </p>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <div className='mb-3 me-3'>
              <Title level={4} className='mb-3'>
                Deal Related Details
              </Title>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Discount Approval Insurance:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.discount_approval_insurance
                    ? formatIndianCurrency(leadViewDetails?.booking?.discount_approval_insurance)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Consumer Offer:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.consumer_offer
                    ? formatIndianCurrency(leadViewDetails?.booking?.consumer_offer)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Exchange Offer:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.exchange_offer
                    ? formatIndianCurrency(leadViewDetails?.booking?.exchange_offer)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Corporate Offer :</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.corporate_offer
                    ? formatIndianCurrency(leadViewDetails?.booking?.corporate_offer)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Other OEM Offer:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.other_oem_offer
                    ? formatIndianCurrency(leadViewDetails?.booking?.other_oem_offer)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Other Dealer Offer:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.other_dealer_offer
                    ? formatIndianCurrency(leadViewDetails?.booking?.other_dealer_offer)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Total Deal Offer:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.total_deal_offer
                    ? formatIndianCurrency(leadViewDetails?.booking?.total_deal_offer)
                    : '-'}
                </p>
              </div>
              <div className='view-fields d-flex align-items-baseline'>
                <h6 className='label'>Total Customer Payable:</h6>
                <p className='value'>
                  {leadViewDetails?.booking?.total_customer_payable
                    ? formatIndianCurrency(leadViewDetails?.booking?.total_customer_payable)
                    : '-'}
                </p>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <div className='mb-3 me-3'>
              <Title level={4} className='mb-3'>
                Image Upload
              </Title>
              <Row gutter={[16, 0]} align='middle' className='mb-3'>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div>{renderDocumentLink('RC', 'RC Copy')}</div>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div>{renderDocumentLink('Insurance Policy', 'Old Car Insurance Copy')}</div>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div>{renderDocumentLink('Old Car Foreclosure Statement', 'Old Car Forclousure Statement')}</div>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div>{renderDocumentLink('MR Copy', 'MR Copy')}</div>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div>{renderDocumentLink('Order Booklet Copy', 'Order Booklet Copy')}</div>
                </Col>
              </Row>
            </div>
          </Col>
          {leadViewDetails?.booking?.status === 'Cancelled' && (
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className='mb-3 me-3'>
                <Title level={4} className='mb-3'>
                  Cancel Booking Reason
                </Title>
                <p>
                  {leadViewDetails?.booking?.cancel_reason_detail?.map((item) => {
                    return (
                      <>
                        <ul>
                          <li>{item?.reason}</li>
                        </ul>
                      </>
                    );
                  })}
                  <p>Remark - {leadViewDetails?.booking?.cancel_remark}</p>
                  <p>
                    Cancellation Letter:{' '}
                    <CommanPreviewImage
                      width={70}
                      height={70}
                      src={leadViewDetails?.booking?.cancel_letter_file_path || '#'}
                    />
                  </p>
                </p>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );

  return (
    <>
      <Loader loading={isLoading} />
      {location.pathname.includes('booking-details') ? (
        <div className='user-data'>
          <PageContentHeader
            title='Booking Details'
            headerRight={
              <Space size='middle'>
                <Button onClick={showModal} type='primary' ghost danger className='primary-button'>
                  Booking Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(isAdmin ? `/admin/leads/view/${id}` : `/leads/view/${id}`);
                  }}
                  type='primary'
                  className='secondary-button'
                >
                  Retail Detail
                </Button>
              </Space>
            }
          />
          <Card>
            <div className='user-data lead-detail'>
              <div className='user-table-head d-flex justify-content-between align-items-center'>
                {/* booking details */}
                <div className='personal-detail'>
                  <BookingDetailsSection />
                </div>
              </div>
            </div>
          </Card>
        </div>
      ) : (
        <div className='user-data lead-detail'>
          <div className='user-table-head d-flex justify-content-between align-items-center'>
            {/* booking details */}
            <div className='personal-detail'>
              {leadViewDetails?.retail === null && (
                <PageContentHeader
                  title='Booking Details'
                  headerRight={
                    !isExchangeEvaluationTeam &&
                    !isLeadConverted && (
                      <>
                        <Button
                          onClick={() => {
                            setEditBooking(true);
                            setBookingDetailsShow(false);
                          }}
                          type='primary'
                          className='secondary-button ms-3'
                        >
                          {leadViewDetails?.booking?.status === 'Cancelled' ? 'Re-Book' : 'Edit'}
                        </Button>

                        {leadViewDetails?.booking?.status === 'Cancelled' && (
                          <Tooltip
                            title={leadViewDetails?.booking?.cancel_reason_detail?.map((ele, index) => (
                              <ul key={index}>
                                <li>{ele?.reason}</li>
                              </ul>
                            ))}
                          >
                            <Tag color='red'>{leadViewDetails?.booking?.status}</Tag>
                          </Tooltip>
                        )}
                        {leadViewDetails?.booking?.status === 'Booked' && (
                          <Button onClick={showModal} type='primary' ghost danger className='primary-button'>
                            Cancel
                          </Button>
                        )}
                      </>
                    )
                  }
                />
              )}
              <BookingDetailsSection />
            </div>
          </div>
        </div>
      )}

      <CancellationModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        onReasonsChange={handleCheckboxChange}
        onRemarkChange={handleRemarkChange}
        checkedReasons={checkedReasons}
        remark={remark}
      />
    </>
  );
};

export default BookingDetails;
