/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Input, Select, Card, notification, Typography, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  getAreasService,
  getDesignationsService,
  getReportingManagerService,
  getRolesService,
  getStateService,
  getTerritoriesService,
} from '../../../services/master-services';
import Loader from '../../../components/common/Loader';
import { addNewUserService } from '../../../services/admin-services';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { useSelector } from 'react-redux';

const { Title } = Typography;

const AddUsers = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [areaData, setAreaData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [territoryData, setTerritoryData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [territorySelection, setTerritorySelection] = useState();
  const [reportingManagerData, setReportingManagerData] = useState([]);
  const [register, setRegister] = useState({
    state_id: 0,
    territory_ids: 0,
    role_id: 0,
  });

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  useEffect(() => {
    getRoleData();
    getStateData();
    getDesignationData();
  }, []);

  const handleStateChange = (value) => {
    setRegister((state) => ({
      ...state,
      state_id: value,
      territory_ids: null,
      area_ids: null,
      reporting_manager_id: null,
    }));

    form.setFieldsValue({
      city_id: undefined,
      territory_ids: undefined,
      area_ids: undefined,
    });
    setAreaData([]);
    setReportingManagerData([]);
    getTerritoryData(value);
  };

  const handleTerritoryChange = (value) => {
    setRegister((state) => ({
      ...state,
      territory_ids: value,
    }));
    form.setFieldsValue({
      area_ids: undefined,
      reporting_manager_id: undefined,
    });
    if (value?.length < 1) {
      setAreaData([]);
      setReportingManagerData([]);
    } else {
      getAreaData(register?.state_id, value);
      getRMData(register?.role_id, value);
    }
  };

  const handleRoleChange = (value) => {
    setTerritorySelection(value);
    form.setFieldsValue({
      reporting_manager_id: null,
      territory_ids: null,
      area_ids: null,
    });
    if (value) {
      setRegister((role) => ({
        ...role,
        role_id: value,
      }));
    } else {
      setReportingManagerData([]);
      setAreaData([]);
      // setTerritoryData([]);
    }
  };

  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTerritoryData = async (state_id) => {
    setIsLoading(true);
    try {
      const territoryData = await getTerritoriesService(state_id, true);
      setTerritoryData(territoryData?.data?.data);
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAreaData = async (state_id, territory_id) => {
    setIsLoading(true);
    try {
      const areaData = await getAreasService(state_id, territory_id, true);
      setAreaData(areaData?.data?.data);
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getDesignationData = async () => {
    setIsLoading(true);
    try {
      const designationArray = await getDesignationsService(true);
      setDesignationData(designationArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRMData = async (id, territory_id) => {
    setIsLoading(true);
    try {
      const reportingManagerArray = await getReportingManagerService(id, territory_id);
      setReportingManagerData(reportingManagerArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRoleData = async () => {
    setIsLoading(true);
    try {
      const roleArray = await getRolesService(true);
      setRoleData(roleArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const res = await addNewUserService(values);
      if (res.data.status === API_RESPONSE_CODES.OK || res.data.status === API_RESPONSE_CODES.CREATED) {
        notification.success({
          message: res.data.message,
        });
        navigate(isAdmin ? '/admin/users' : '/users');
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='user-data'>
        <Loader loading={isLoading} />
        <PageContentHeader title='Add Users' />

        <div className='personal-detail'>
          <Card>
            <div className='user-left d-flex align-items-center'>
              <Title level={4} className='pe-2 pb-2'>
                User Details
              </Title>
            </div>
            <Form
              layout='vertical'
              name='add-user-form'
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className='detail-wrapper'>
                <Row gutter={20} justify='start'>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='full_name'
                        label='Name'
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject('Please input your name');
                              }
                              if (!/^[A-Za-z\s]+$/.test(value)) {
                                return Promise.reject('Please input a valid name');
                              }
                              if (/\s{2,}/.test(value)) {
                                return Promise.reject('Name cannot contain two consecutive spaces');
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject('Name cannot start or end with spaces');
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject('Name must be between 3 and 30 characters');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input type='text' placeholder='Name' className='form-control-ant' />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='emp_code'
                        label='Employee Code'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your employee code',
                          },
                          {
                            pattern: /^[a-zA-Z0-9]{10,15}$/,
                            message: 'Employee code must be alphanumeric and between 10 to 15 characters',
                          },
                        ]}
                      >
                        <Input placeholder='Employee Code' className='form-control-ant' />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='designation_id'
                        label='Designation'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your designation',
                          },
                        ]}
                      >
                        <Select allowClear className='form-control-ant' placeholder='Designation'>
                          {designationData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='role_id'
                        label='Role'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your role ',
                          },
                        ]}
                      >
                        <Select allowClear placeholder='Role' className='form-control-ant' onChange={handleRoleChange}>
                          {roleData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='state_id'
                        label='State'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your state',
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder='State'
                          className='form-control-ant'
                          onChange={handleStateChange}
                        >
                          {stateData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='territory_ids'
                        label='Territory'
                        disabled={!register?.state_id}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your territory',
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          disabled={register.state_id === 0}
                          mode={
                            territorySelection === 1 ||
                            territorySelection === 2 ||
                            territorySelection === 3 ||
                            territorySelection === 6
                              ? 'multiple'
                              : null
                          }
                          maxTagCount={2}
                          placeholder='Territory'
                          className='form-control-ant'
                          onChange={handleTerritoryChange}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {territoryData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        label='Area'
                        name='area_ids'
                        disabled={!register?.territory_ids}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your area',
                          },
                        ]}
                      >
                        <Select
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                          disabled={register.territory_ids === 0}
                          className='form-control-ant'
                          placeholder='Area'
                          mode='multiple'
                          maxTagCount={2}
                        >
                          {areaData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='reporting_manager_id'
                        label='Reporting Manager'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your reporting manager',
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          className='form-control-ant'
                          placeholder='Reporting Manager'
                          disabled={reportingManagerData?.length < 1}
                        >
                          {reportingManagerData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {`${option?.full_name} (${option?.role})`}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='phone_no'
                        label='Contact Number'
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          {
                            validator: (_, value) => {
                              if (!value) {
                                return Promise.reject('Please input your contact number');
                              }
                              if (!/^[0-9]*$/.test(value)) {
                                return Promise.reject('Contact number must be digits only');
                              }
                              if (value?.length < 10 || value?.length > 10) {
                                return Promise.reject('Please enter a valid 10 digit contact number');
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input placeholder='Contact Number' className='form-control-ant' />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className='login-input-field'>
                      <Form.Item
                        name='email'
                        label='Email'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your email id',
                          },
                          {
                            type: 'email',
                            message: 'Please enter a valid email id',
                          },
                          {
                            validator: (_, value) => {
                              if (value && /@-/.test(value)) {
                                return Promise.reject('Please enter a valid email id');
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input placeholder='Email' className='form-control-ant' />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='user-right d-flex align-items-center justify-content-end mt-3'>
                <Space size='middle'>
                  <Button
                    type='primary'
                    ghost
                    danger
                    className='primary-button'
                    onClick={() => navigate(isAdmin ? '/admin/users' : '/users')}
                  >
                    Cancel
                  </Button>
                  <Button type='primary' className='secondary-button' htmlType='submit'>
                    Save
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddUsers;
