/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, notification } from "antd";
import InputSelect from "../../../components/common/InputSelect";
import { fetchLeadsTransmission } from "../../auth/slice/authSlice";
import { getVehicleMakeService, getVehicleModelService, getVehicleVarientService } from "../../../services/master-services";

const ExchangeVechileDropdown = (props) => {
    const { exchangeVehicleData, form, setIsLoading, id } = props;
    const dispatch = useDispatch();
    const transmissionData = useSelector(
        (state) => state?.auth?.transmissionData?.data
    );
    const [makeData, setMakeData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [varientData, setVarientData] = useState([]);
    const [excVehicleIds, setExcVehicleIds] = useState({
        make_id: "",
        model_id: "",
        variant_id: "",
    });

    useEffect(() => {
        getMakeData();
        handleLeadsTransmission();
    }, []);

    useEffect(() => {
        if (exchangeVehicleData?.make_id && id) {
            getModalData(exchangeVehicleData?.make_id);
        }
    }, [exchangeVehicleData?.make_id]);

    useEffect(() => {
        if (exchangeVehicleData?.model_id && id) {
            getVarientData(exchangeVehicleData?.model_id);
        }
    }, [exchangeVehicleData?.model_id]);

    const handleLeadsTransmission = () => {
        try {
            dispatch(fetchLeadsTransmission());
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
        }
    };

    const getVarientData = async (model_id) => {
        setIsLoading(true);
        try {
            const varientDataArray = await getVehicleVarientService(model_id);
            setVarientData(varientDataArray?.data?.data);
            if (exchangeVehicleData?.variant_id && id) {
                form.setFieldsValue({
                    exchange_vehicle_variant_id: exchangeVehicleData?.variant_id,
                });
            }
            setIsLoading(false);
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const getModalData = async (value) => {
        setIsLoading(true);
        try {
            const modalDataArray = await getVehicleModelService(value);
            setModalData(modalDataArray?.data?.data);
            // if (exchangeVehicleData?.model_id && id) {
            //     form.setFieldsValue({
            //         exchange_vehicle_model_id: exchangeVehicleData?.model_id,
            //     });
            //     // getVarientData(exchangeVehicleData?.model_id);
            // }
            setIsLoading(false);
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const getMakeData = async () => {
        setIsLoading(true);
        try {
            const modalDataArray = await getVehicleMakeService();
            setMakeData(modalDataArray?.data?.data);
            // if (exchangeVehicleData?.make_id && id) {
            //     form.setFieldsValue({
            //         exchange_make_id: exchangeVehicleData?.make_id,
            //     });
            //     // getModalData(exchangeVehicleData?.make_id);
            // }
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleExcMakeChange = async (value) => {
        setExcVehicleIds((state) => ({
            ...state,
            make_id: value,
        }));
        form.setFieldsValue({
            exchange_vehicle_model_id: null,
            exchange_vehicle_variant_id: null,
        });
        getModalData(value);
    };

    const handleExcModalChange = async (value) => {
        setExcVehicleIds((state) => ({
            ...state,
            model_id: value,
        }));
        form.setFieldsValue({
            exchange_vehicle_variant_id: null,
        });
        getVarientData(value);
    };

    const handleExcVariantChange = async (value) => {
        setExcVehicleIds((state) => ({
            ...state,
            variant_id: value,
        }));
    };

    return (
        <>
            <Col xl={4} md={8} sm={24} xs={24}>
                <InputSelect
                allowClear={false}
                    rules={[
                        {
                            required: true,
                            message: "Please select make",
                        },
                    ]}
                    name="exchange_make_id"
                    placeholder="Make"
                    options={makeData}
                    label="Make"
                    onSelect={(val) => {
                        handleExcMakeChange(val);
                    }}
                    optionName={"title"}
                />
            </Col>
            {/* Modal Id  */}
            <Col xl={4} md={8} sm={24} xs={24}>
                <InputSelect
                allowClear={false}
                    rules={[
                        {
                            required: true,
                            message: "Please select your car model",
                        },
                    ]}
                    name="exchange_vehicle_model_id"
                    placeholder="Model"
                    options={modalData}
                    label="Model"
                    optionName={"model_name"}
                    onSelect={(val) => {
                        handleExcModalChange(val);
                    }}
                />
            </Col>

            {/* Car Variant  */}
            <Col xl={4} md={8} sm={24} xs={24}>
                <InputSelect
                allowClear={false}
                    rules={[
                        {
                            required: true,
                            message: "Please select your car variant",
                        },
                    ]}
                    name="exchange_vehicle_variant_id"
                    placeholder="Car Variant"
                    options={varientData}
                    label="Car Variant"
                    optionName={"variant_code"}
                    onSelect={(val) => {
                        handleExcVariantChange(val);
                    }}
                />
            </Col>
            <Col xl={4} md={8} sm={24} xs={24}>
                <InputSelect
                allowClear={false}
                    rules={[
                        {
                            required: true,
                            message: "Please select your car transmission",
                        },
                    ]}
                    name="exchange_transmission_id"
                    placeholder="Transmission"
                    options={transmissionData}
                    label="Transmission"
                    optionName={"name"}
                />
            </Col>
        </>
    );
};

export default ExchangeVechileDropdown;
