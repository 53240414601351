/* eslint-disable react-hooks/exhaustive-deps */
import Loader from './Loader';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogout } from '../../app/auth/slice/authSlice';
import { logoutService } from '../../services/auth-service';
import { Avatar, Dropdown, Space, Image, Typography, Button, Badge, List, Drawer, notification } from 'antd';
import { API_RESPONSE_CODES } from '../../utills/constant';
import PasswordChangeModal from '../modals/PasswordChangeModal';
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  LockOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { getNotificationCount, readNotifications } from '../../services/master-services';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
const { Title } = Typography;

const PageHeader = (props) => {
  const { collapsed, toggleCollapsed, mobileView, handleMobileMenuToggle, pageHeaderClick } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const route = useSelector((state) => state.auth.route);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const roleName = useSelector((state) => state.auth.userData?.full_name);

  const [isLoading, setIsLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const firstLoginCheck = useSelector((state) => state.auth.userData.first_login);

  const handleLogoutFunction = async () => {
    try {
      setIsLoading(true);
      const res = await logoutService();
      if (res.status === API_RESPONSE_CODES?.OK) {
        dispatch(handleLogout());
        navigate(isAdmin ? '/admin' : '/');
      }
    } catch (error) {
      dispatch(handleLogout());
      navigate(isAdmin ? '/admin' : '/');
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuClick = () => {
    setDropdownVisible(false);
  };

  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };

  useEffect(() => {
    // Reset notifications when route changes
    setNotifications([]);
    // Fetch notifications without pagination
    fetchNotifications();
  }, [location.pathname]);

  useEffect(() => {
    // Update every 30 seconds
    const timeUpdateInterval = setInterval(() => {
      fetchNotifications();
    }, 30000);

    return () => clearInterval(timeUpdateInterval);
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await getNotificationCount();
      if (response?.data?.status === API_RESPONSE_CODES?.OK) {
        const fetchedNotifications = response?.data?.data?.notification_data;
        setNotificationCount(response?.data?.data?.unread_notification);
        setNotifications(fetchedNotifications || []);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch notifications',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const allNotificationsRead = notifications?.every((notification) => notification.read_at);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const renderTime = (timestamp) => {
    return moment(timestamp).fromNow();
  };

  const handleReadAllNotifications = async () => {
    const reqData = {
      mark_as_read: true,
    };
    try {
      const response = await readNotifications(reqData);
      if (response.data.status === API_RESPONSE_CODES?.OK || response.data.status === API_RESPONSE_CODES?.CREATED) {
        fetchNotifications(true);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReadNotifications = async (id) => {
    const reqData = {
      mark_as_read: false,
      notification_id: id ? id : null,
    };
    try {
      const response = await readNotifications(reqData);
      if (response.data.status === API_RESPONSE_CODES?.OK || response.data.status === API_RESPONSE_CODES?.CREATED) {
        await fetchNotifications(true);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const items = [
    {
      icon: <UserOutlined />,
      label: (
        <Link className='text-decoration-none' to={isAdmin ? `admin/profile` : `/profile`}>
          Profile
        </Link>
      ),
      key: 'profile',
      onClick: handleMenuClick,
    },
    {
      icon: <LockOutlined />,
      label: (
        <Link className='text-decoration-none' to={isAdmin ? `${route}/change-password` : `/change-password`}>
          Change Password
        </Link>
      ),
      key: 'change-password',
      onClick: handleMenuClick,
    },
    {
      icon: <LogoutOutlined />,
      label: 'Log out',
      key: 'logout',
      onClick: handleLogoutFunction,
    },
  ];

  return (
    <div className='page-header fixed d-flex align-items-center'>
      <Loader loading={isLoading} />
      <div className='header-left d-flex align-items-center'>
        <div className='header-logo'>
          <Link to={isAdmin ? '/admin/dashboard' : '/dashboard'}>
            <Space size='middle'>
              <Image src={'/toyota-logo.png'} alt='Header Image' preview={false} />
              <div className='header-heading'>TOYOTA</div>
            </Space>
          </Link>
        </div>
        {firstLoginCheck ? <PasswordChangeModal open={firstLoginCheck} /> : <></>}
        <Button
          type='text'
          icon={mobileView ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={handleMobileMenuToggle}
          className='menu-trigger-button d-hide'
        />
        <Button
          type='text'
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={toggleCollapsed}
          className='menu-trigger-button m-hide'
        />
      </div>
      <div className='welcome'>
        <Title level={4} className='pe-2'>
          Welcome to JD Toyota
        </Title>
      </div>
      <div className='user-profile d-flex align-items-center ms-auto' onClick={pageHeaderClick}>
        <Badge count={notificationCount} overflowCount={999} className='mr-2 cursor-pointer' onClick={showDrawer}>
          <Button shape='circle' size='small' onClick={showDrawer} icon={<BellOutlined />} />
        </Badge>
        <Dropdown
          menu={{ items }}
          className='header-drop'
          overlayClassName='user-profile-header'
          open={dropdownVisible}
          onOpenChange={handleVisibleChange}
          trigger={['click']}
        >
          <a>
            <Avatar
              size='small'
              icon={<UserOutlined />}
              style={{ backgroundColor: '#0a9551' }}
              className='profile-logo'
            />
            <Space>
              <span className='user-name'> {roleName}</span>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        <Drawer title='Notifications' placement='right' onClose={() => setDrawerVisible(false)} visible={drawerVisible}>
          <div style={{ marginBottom: 16, display: 'flex', gap: '8px' }}>
            {!allNotificationsRead && (
              <Button type='primary' className='secondary-button' size='small' onClick={handleReadAllNotifications}>
                Read All
              </Button>
            )}
          </div>
          <List
            itemLayout='horizontal'
            dataSource={notifications}
            loading={isLoading}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                className={item.read_at ? 'read' : 'unread'}
                onClick={() => handleReadNotifications(item.id)}
              >
                <List.Item.Meta title={item.data} />
                <p className='time'>{renderTime(item.created_at)}</p>
              </List.Item>
            )}
            className='notification-list'
            style={{
              maxHeight: 'auto',
              overflow: 'auto',
              scrollBehavior: 'smooth',
            }}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default PageHeader;
