/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Select, Pagination, Card, Space, notification, Tag } from 'antd';
import UserCard from '../../../components/card/UserCard';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import Search from 'antd/es/input/Search';
import { getLeadsListingService } from '../../../services/admin-services';
import axios from 'axios';
import {
  getEnquirySourcesService,
  getFilterAreaService,
  getFilterTerritoriesService,
  getLeadFiltersLeadRange,
  getLeadOwnerService,
  getLeadService,
  getLeadStagesService,
  getLeadStatusService,
  getVehicleModelService,
} from '../../../services/master-services';
import { API_BASE_URL, API_RESPONSE_CODES } from '../../../utills/constant';

import { useSelector } from 'react-redux';
import NoDataFound from '../../../components/common/NoDataFound';
import ModalComponent from '../../../components/modals/ModalComponent';
import { leadsInitialState } from '../../../utills/constant';
import { ddmmyyFormatter } from '../../../utills/helpers';
const { Option } = Select;

const Leads = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const hotKeyId = searchParams.get('key');
  const typeParamId = searchParams.get('lead_type');
  const graphMetricsId = searchParams.get('metrics');
  const graphValue = searchParams.get('graph_value');
  const stageParamId = searchParams.get('lead_stage');
  const statusParamId = searchParams.get('lead_status');
  const sourceParamId = searchParams.get('lead_source');
  const graphDuration = searchParams.get('graph_duration');
  const followUpDateParamId = searchParams.get('follow_up_date_id');
  const userTransferParams = searchParams.get('transfer');
  const testDriveParam = searchParams.get('test_drive_completed');
  const homeVisitParam = searchParams.get('home_visit_completed');
  const exchangeCarParam = searchParams.get('exchange_car');
  const evaluationCompletedParam = searchParams.get('evaluation_completed');
  const taskAgeingOneDay = searchParams.get('greater_than_one_day');
  const taskAgeingTenDay = searchParams.get('greater_than_ten_day');
  const taskAgeingTwentyDay = searchParams.get('greater_than_twenty_day');
  const lineGraphLastMonth = searchParams.get('last_month');
  const territoryIdParam = searchParams.get('territory_id');
  const targeRedirectParam = searchParams.get('target_redirect');
  const excludeIdQueryParams = searchParams.get('exclude_user_ids');
  const procuredQueryParams = searchParams.get('procured');

  const excludeIdArray = excludeIdQueryParams?.split(',')?.map((item) => parseInt(item?.trim(), 10));

  const dashboardData = useSelector((state) => state?.auth?.dashboardHomeFilters);
  const route = useSelector((state) => state?.auth?.route);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const permissions = useSelector((state) => state.auth.userData.permissions);

  const modelType = dashboardData?.modelNo || searchParams.get('model_type') || searchParams.get('model_no');
  const userType = dashboardData?.userId;
  const startDateId = dashboardData?.startDate;
  const endDateId = dashboardData?.endDate;
  const exclude_user_ids = dashboardData?.exclude_user_ids ? dashboardData?.exclude_user_ids : '';
  const monthID = targeRedirectParam ? dashboardData?.month_id : '';
  const year = targeRedirectParam ? dashboardData?.year_id : '';
  const leadOwned = searchParams.get('lead_owned');
  const kpiParameterTargetQuery = searchParams.get('kpi_parameter');
  const kpiParamVasBookEw = searchParams.get('kpi_parameter_ew');
  const kpiParamVasBookbody = searchParams.get('kpi_parameter_body_care');
  const kpiParamVasBookaccess = searchParams.get('kpi_parameter_accessories');
  const kpiParamVasBookinhouse = searchParams.get('kpi_parameter_in_house');
  const vasReportQueryParam = searchParams.get('vas_report_redirect');
  const vasReportRetailQueryParam = searchParams.get('vas_report_redirect_retail');
  const isBookingCompleted = searchParams.get('booking_completed');

  const [rtoData, setRtoData] = useState([]);
  const [tagNames, setTagnames] = useState({});
  const [fuelData, setFuelData] = useState([]);
  const [filters, setFilters] = useState({
    per_page: 10,
    page: 1,
    search: '',
    lead_status: statusParamId ? [statusParamId] : [],
    lead_type: typeParamId && +typeParamId !== 10 ? [typeParamId] : [],
    lead_stage: stageParamId ? [stageParamId] : [],
    model_type: hotKeyId && modelType ? [modelType] : [],
    fuel_type: [],
    rto_locations: [],
    lead_sources: sourceParamId ? [sourceParamId] : [],
    variant_type: [],
    state_id: [],
    territory_id: territoryIdParam ? [territoryIdParam] : [],
    area_id: [],
    lead_owned: userTransferParams
      ? [userTransferParams]
      : hotKeyId && userType
      ? [userType]
      : hotKeyId && leadOwned
      ? [leadOwned]
      : [],
    follow_up_date_id: followUpDateParamId ? [followUpDateParamId] : [],
    start_date: hotKeyId && startDateId ? startDateId : '',
    end_date: hotKeyId && endDateId ? endDateId : '',
    graph_metrics: hotKeyId && graphMetricsId ? graphMetricsId : '',
    date_range: hotKeyId && graphDuration ? graphDuration : '',
    test_drive_completed: testDriveParam === `"true"` ? true : false,
    home_visit_completed: homeVisitParam === `"true"` ? true : false,
    exchange_car: exchangeCarParam === `"true"` ? true : false,
    evaluation_completed: evaluationCompletedParam === `"true"` ? true : false,
    greater_than_one_day: taskAgeingOneDay === `"true"` ? true : false,
    greater_than_ten_day: taskAgeingTenDay === `"true"` ? true : false,
    greater_than_twenty_day: taskAgeingTwentyDay === `"true"` ? true : false,
    last_month: lineGraphLastMonth === `"true"` ? true : false,
    live_lead: typeParamId === '10' ? true : false,
    dashboard_redirect:
      (userType || startDateId || endDateId || dashboardData?.modelNo || hotKeyId) && !userTransferParams
        ? true
        : false,
    exclude_user_ids: excludeIdArray ? excludeIdArray : exclude_user_ids ? exclude_user_ids : null,
    target_redirect: targeRedirectParam ? true : false,
    month: monthID,
    year: year,
    kpi_parameter: kpiParameterTargetQuery
      ? 'booking'
      : kpiParamVasBookEw
      ? 'ew'
      : kpiParamVasBookbody
      ? 'body_care'
      : kpiParamVasBookaccess
      ? 'accessories'
      : kpiParamVasBookinhouse
      ? 'inhouse'
      : '',
    procured: procuredQueryParams ? true : '',
    vas_report_redirect: vasReportQueryParam ? 'booking' : vasReportRetailQueryParam ? 'retail' : '',
    booking_completed: isBookingCompleted === 'true' ? 1 : 0,
  });
  const [areaData, setAreaData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);
  const [followUpData, setFollowUpData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leadsTypeData, setLeadsTypeData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [leadStageData, setLeadStageData] = useState([]);
  const [leadStatusData, setLeadStatusData] = useState([]);
  const [transferLeadIds, setTransferLeadIds] = useState([]);
  const [vehicleIds, setVehicleIds] = useState({
    make_id: 1,
    model_id: '',
    variant_id: '',
  });
  const [register, setRegister] = useState({
    state_id: 0,
    territory_id: 0,
  });

  const hasLeadsData = leadsData?.list_data?.length > 0;
  const hasAddLeadPermission = isAdmin ? true : permissions?.includes('add-lead');
  const selectedLeadsLength = transferLeadIds?.length;
  const routeCheck = isAdmin ? '/admin' : '';

  useEffect(() => {
    if (userTransferParams) {
      // getLeadListing(filters);
      setIsModalOpen(true);
    }
  }, [userTransferParams]);

  useEffect(() => {
    // getLeadListing(filters);
    getRTOData();
    getFuelData();
    getAreaData();
    getOwnerData();
    getEnquiryData();
    getLeadsTypeData();
    getTerritoryData();
    getLeadStagesData();
    getLeadStatusData();
    setTransferLeadIds([]);
    getLeadFilterDateData();
    getModalData(vehicleIds?.make_id);
  }, []);

  useEffect(() => {
    getLeadListing(filters);
  }, [filters]);

  useEffect(() => {
    if (hotKeyId && typeParamId) {
      const lead_type =
        typeParamId === '10' ? 'Live' : leadsTypeData?.filter((ele) => ele.id === +typeParamId)?.at(0)?.name;
      setTagnames((state) => ({
        ...state,
        'Lead Type': lead_type,
      }));
    }
    if (hotKeyId && stageParamId) {
      let val;
      if (+stageParamId === 11) {
        val = 'Enquiry';
      } else if (+stageParamId === 12) {
        val = 'Test Drive';
      } else if (+stageParamId === 13) {
        val = 'Home Visit';
      } else if (+stageParamId === 14) {
        val = 'Exchange';
      } else if (+stageParamId === 15) {
        val = 'Valuation';
      } else {
        val = leadStageData?.filter((ele) => ele.id === +stageParamId)?.at(0)?.name;
      }
      setTagnames((state) => ({
        ...state,
        'Lead Stage': val,
      }));
    }
    if (hotKeyId && sourceParamId) {
      const source = enquiryData?.filter((ele) => ele.id === +sourceParamId)?.at(0)?.source;
      setTagnames((state) => ({
        ...state,
        'Lead Source': source,
      }));
    }
    if (hotKeyId && followUpDateParamId) {
      const followUp = followUpData?.filter((ele) => ele.id === +followUpDateParamId)?.at(0)?.title;
      setTagnames((state) => ({
        ...state,
        'Follow Up Date': followUp,
      }));
    }
    if (hotKeyId && statusParamId) {
      const status = leadStatusData?.filter((ele) => ele.id === +statusParamId)?.at(0)?.name;
      setTagnames((state) => ({ ...state, 'Lead Status': status }));
    }
    if (hotKeyId && modelType) {
      const model = modalData?.filter((ele) => ele.id === +modelType)?.at(0)?.model_name;
      setTagnames((state) => ({ ...state, Model: model }));
    }
    if (hotKeyId && userType) {
      const owner = ownerData?.filter((ele) => ele.id === +userType)?.at(0)?.full_name;
      setTagnames((state) => ({ ...state, 'Lead Owner': owner }));
    }
    if (hotKeyId && leadOwned) {
      const owner = ownerData?.filter((ele) => ele.id === +leadOwned)?.at(0)?.full_name;
      setTagnames((state) => ({ ...state, 'Lead Owner': owner }));
    }
    if (userTransferParams && userTransferParams) {
      const owner = ownerData?.filter((ele) => ele.id === +userTransferParams)?.at(0)?.full_name;
      setTagnames((state) => ({ ...state, 'Lead Owner': owner }));
    }
    if (hotKeyId && startDateId) {
      setTagnames((state) => ({
        ...state,
        'From Date': ddmmyyFormatter(startDateId),
      }));
    }
    if (hotKeyId && endDateId) {
      setTagnames((state) => ({
        ...state,
        'To Date': ddmmyyFormatter(endDateId),
      }));
    }
    if (hotKeyId && graphDuration) {
      const string = graphMetricsId === 'variation' ? `${graphDuration}` : `${graphDuration} days`;
      setTagnames((state) => ({
        ...state,
        ' Duration': string,
      }));
    }
    if (hotKeyId && testDriveParam === `"true"`) {
      setTagnames((state) => ({
        ...state,
        'Lead Stage': 'Test Drive',
      }));
    }
    if (hotKeyId && homeVisitParam === `"true"`) {
      setTagnames((state) => ({
        ...state,
        'Lead Stage': 'Home Visit',
      }));
    }
    if (hotKeyId && exchangeCarParam === `"true"`) {
      setTagnames((state) => ({
        ...state,
        'Lead Stage': 'Exchange',
      }));
    }
    if (hotKeyId && evaluationCompletedParam === `"true"`) {
      setTagnames((state) => ({
        ...state,
        'Lead Stage': 'Evaluation',
      }));
    }
    if (hotKeyId && taskAgeingOneDay === `"true"`) {
      setTagnames((state) => ({
        ...state,
        'Task Ageing': '>1 Day',
      }));
    }
    if (hotKeyId && taskAgeingTenDay === `"true"`) {
      setTagnames((state) => ({
        ...state,
        'Task Ageing': '>10 Days',
      }));
    }
    if (hotKeyId && taskAgeingTwentyDay === `"true"`) {
      setTagnames((state) => ({
        ...state,
        'Task Ageing': '>20 Days',
      }));
    }
    if (hotKeyId && territoryIdParam) {
      const territoryName = territoryData?.filter((ele) => ele.id === +territoryIdParam)?.at(0)?.name;
      setTagnames((state) => ({
        ...state,
        Territory: territoryName,
      }));
    }
    if (hotKeyId && graphMetricsId) {
      let title;
      if (graphMetricsId === 'lead_count') {
        title = 'Lead Count';
      } else if (graphMetricsId === 'quotation_sent') {
        title = 'Quotation Sent';
      } else if (graphMetricsId === 'pending_valuation') {
        title = 'Pending valuation';
      } else if (graphMetricsId === 'variation') {
        title = 'Variation';
      }

      setTagnames((state) => ({ ...state, 'Graph Metrics': `${title}` }));
    }
  }, [
    typeParamId,
    stageParamId,
    sourceParamId,
    testDriveParam,
    ownerData,
    modalData,
    enquiryData,
    followUpData,
    leadStageData,
    leadStatusData,
    leadsTypeData,
    graphMetricsId,
    graphDuration,
    userTransferParams,
  ]);

  useEffect(() => {
    if (!hotKeyId && !userTransferParams) {
      // setFilters({
      //   ...filters,
      //   ...leadsInitialState,
      // });
    }
  }, [hotKeyId]);

  const handleButtonClick = () => {
    navigate(`${routeCheck}/leads`);
    setFilters({
      ...filters,
      ...leadsInitialState,
    });
  };

  const getOwnerData = async () => {
    setIsLoading(true);
    try {
      const ownerData = await getLeadOwnerService();
      if (ownerData?.status === API_RESPONSE_CODES?.OK) {
        setOwnerData(ownerData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadStagesData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getLeadStagesService();
      if (stateData?.status === API_RESPONSE_CODES?.OK) {
        setLeadStageData(stateData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalIdChange = (value) => {
    setFilters((state) => ({ ...state, page: 1, model_type: value }));
    setVehicleIds((state) => ({
      ...state,
      model_id: value,
    }));

    form.setFieldsValue({
      vehicle_variant_id: undefined,
      color_id: undefined,
      fuel_type_id: undefined,
      seater_id: undefined,
    });
    // getVarientData(value);
  };

  const getEnquiryData = async () => {
    setIsLoading(true);
    try {
      const enquiryDataArray = await getEnquirySourcesService();
      if (enquiryDataArray?.status === API_RESPONSE_CODES?.OK) {
        setEnquiryData(enquiryDataArray?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerritoryChange = (value) => {
    setFilters((state) => ({
      ...state,
      page: 1,
      territory_id: value,
    }));

    setRegister((state) => ({
      ...state,
      territory_id: value,
    }));
    getAreaData(register.state_id, value);
  };

  const getLeadStatusData = async () => {
    setIsLoading(true);
    try {
      const response = await getLeadStatusService();
      if (response?.status === API_RESPONSE_CODES?.OK) {
        setLeadStatusData(response?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTerritoryData = async () => {
    setIsLoading(true);
    try {
      const territoryData = await getFilterTerritoriesService();
      if (territoryData?.status === API_RESPONSE_CODES?.OK) {
        setTerritoryData(territoryData?.data?.data);
      }
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAreaData = async () => {
    setIsLoading(true);
    try {
      const areaData = await getFilterAreaService();
      if (areaData?.status === API_RESPONSE_CODES?.OK) {
        setAreaData(areaData?.data?.data);
      }
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadFilterDateData = async () => {
    setIsLoading(true);
    try {
      const rangeData = await getLeadFiltersLeadRange();
      if (rangeData?.status === API_RESPONSE_CODES?.OK) {
        setFollowUpData(rangeData?.data?.data);
      }
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadsTypeData = async () => {
    setIsLoading(true);
    try {
      const leadsDataArray = await getLeadService();
      if (leadsDataArray?.status === API_RESPONSE_CODES?.OK) {
        setLeadsTypeData(leadsDataArray?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getModalData = async (value) => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleModelService(1, true);
      if (modalDataArray?.status === API_RESPONSE_CODES?.OK) {
        setModalData(modalDataArray?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRTOData = async () => {
    setIsLoading(true);
    try {
      const stateData = await axios.get(`${API_BASE_URL}/master/get-rto-locations`);
      if (stateData?.status === API_RESPONSE_CODES?.OK) {
        setRtoData(stateData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadListing = async (data) => {
    setIsLoading(true);
    try {
      const userList = await getLeadsListingService(data);
      if (userList?.status === API_RESPONSE_CODES?.OK) {
        setLeadsData(userList?.data?.data);
        if (userTransferParams) {
          const transferIds = userList?.data?.data?.list_data?.map((ele) => ele?.id);
          setTransferLeadIds(transferIds);
        }
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getFuelData = async () => {
    setIsLoading(true);
    try {
      const fuelData = await axios.get(`${API_BASE_URL}/master/get-fuel-types`);
      if (fuelData?.status === API_RESPONSE_CODES?.OK) {
        setFuelData(fuelData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      page: 1,
      search: value,
    }));
  };

  // const handlePageChange = (val) => {
  //   setFilters((state) => ({ ...state, page: val }));
  // };

  const handlePageChange = (page, pageSize) => {
    setFilters((state) => ({
      ...state,
      page: page, // Update the page
      per_page: pageSize, // Update the per_page
    }));
  };

  const handleTransferLeads = () => {
    setIsModalOpen((state) => !state);
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className='user-data'>
        <div className='search-leads'>
          {hotKeyId ? (
            ''
          ) : (
            <Row gutter={[16, 16]} align='middle'>
              <Col xl={15} lg={14} md={24} sm={24} xs={24}>
                <Search allowClear onSearch={onSearch} placeholder='Search by Customer and Contact Number' />
              </Col>
              <Col xl={9} lg={10} md={24} sm={24} xs={24}>
                <div className='user-right d-flex align-items-center justify-content-end leads-btn'>
                  <Space size='middle'>
                    {hasAddLeadPermission && (
                      <Link to={isAdmin ? `${route}/leads/add` : `/leads/add`}>
                        <Button type='primary' className='secondary-button'>
                          Add New Lead
                        </Button>
                      </Link>
                    )}
                  </Space>
                  {isAdmin && (
                    <Space size='middle'>
                      <Button
                        type='primary'
                        className='secondary-button ms-3'
                        onClick={handleTransferLeads}
                        disabled={!selectedLeadsLength}
                      >
                        Transfer Lead
                      </Button>
                    </Space>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <div>
            <Card className='mt-4'>
              {hotKeyId || userTransferParams ? (
                <div className='d-flex align-items-center justify-content-between flex-wrap gap-3'>
                  <Space size={'middle'} wrap>
                    {Object.entries(tagNames)?.map(([key, value]) => (
                      <Space key={key}>
                        {/* Adding a key for each pair for React's rendering */}
                        <span className='tag-label'>{key}:</span>
                        <Tag color='green'>{value}</Tag>
                      </Space>
                    ))}
                  </Space>
                  {userTransferParams ? (
                    ''
                  ) : (
                    <Button type='primary' className='secondary-button' onClick={handleButtonClick}>
                      Go to Leads List
                    </Button>
                  )}
                </div>
              ) : (
                <Form
                  form={form}
                  layout='vertical'
                  name='normal_search'
                  className='login-form'
                  initialValues={{
                    remember: true,
                  }}
                >
                  {userTransferParams ? (
                    ''
                  ) : (
                    <div className='login-input-field'>
                      <Row gutter={[16, 0]}>
                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label=' Lead Status' name='lead_status'>
                            <Select
                              allowClear
                              // showSearch
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Status'
                              className='form-control-ant'
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  lead_status: ele,
                                }))
                              }
                            >
                              {leadStatusData?.map((option) => (
                                <Select.Option key={option.id} value={option.slug}>
                                  {option.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='Lead Type' name='lead_type'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Lead Type'
                              className='form-control-ant'
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  lead_type: ele,
                                }))
                              }
                            >
                              {leadsTypeData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='Model Type' name='model_type'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Model Type'
                              className='form-control-ant'
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleModalIdChange}
                            >
                              {modalData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.model_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='Fuel Type' name='fuel_type'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Fuel Type'
                              className='form-control-ant'
                              // disabled={!vehicleIds?.variant_id}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  fuel_type: ele,
                                }))
                              }
                            >
                              {fuelData &&
                                fuelData?.map((option) => (
                                  <Select.Option key={option.id} value={option.id}>
                                    {option?.title}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='RTO' name='rto_locations'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='RTO'
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              className='form-control-ant'
                              onChange={
                                (ele) =>
                                  setFilters((state) => ({
                                    ...state,
                                    page: 1,
                                    rto_locations: ele,
                                  }))
                                // disabled={register.state_id === 0}
                              }
                            >
                              {rtoData?.map((option) => (
                                <Option key={option.id} value={option.id}>
                                  {option.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item
                            name='territory_id'
                            label='Territory'
                            rules={[
                              {
                                required: false,
                                message: 'Please select your territory',
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              // disabled={register.state_id === 0}
                              placeholder='Territory'
                              className='form-control-ant'
                              onChange={handleTerritoryChange}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {territoryData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item
                            name='area_id'
                            label='Area'
                            rules={[
                              {
                                required: false,
                                message: 'Please select your area',
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Area'
                              className='form-control-ant'
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  area_id: ele,
                                }))
                              }
                              // disabled={reFgister.territory_id === 0}
                            >
                              {areaData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='Lead Owner' name='lead_owned'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Lead Owner'
                              className='form-control-ant'
                              filterOption={(input, option) =>
                                option.children?.join(' ')?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                              }
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  lead_owned: ele,
                                }))
                              }
                            >
                              {ownerData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.full_name}
                                  {option?.title ? ` (${option?.title})` : ''}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='Lead Stage' name='lead_stage'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Lead Stage'
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              className='form-control-ant'
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  lead_stage: ele,
                                }))
                              }
                            >
                              {leadStageData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='Lead Source' name='lead_source'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Lead Source'
                              className='form-control-ant'
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  lead_sources: ele,
                                }))
                              }
                            >
                              {enquiryData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.source}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                          <Form.Item label='Follow Up Date' name='follow_up_date_id'>
                            <Select
                              allowClear
                              mode='multiple'
                              maxTagCount={2}
                              placeholder='Follow Up Date'
                              className='form-control-ant'
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(ele) =>
                                setFilters((state) => ({
                                  ...state,
                                  page: 1,
                                  follow_up_date_id: ele,
                                }))
                              }
                            >
                              {followUpData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.title}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Form>
              )}
            </Card>

            <div className='taglist pt-4 text-start'>
              {/* <Tag closeIcon onClose={preventDefault} className="user-tags mb-4">
              Open
            </Tag> */}
            </div>
            <div className='lead-type-card'>
              <Row gutter={[20, 20]}>
                {hasLeadsData ? (
                  leadsData?.list_data?.map((item, index) => (
                    <Col key={index} xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <UserCard
                        data={item}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        dashboardRedirect={hotKeyId}
                        transferLeadIds={transferLeadIds}
                        setTransferLeadIds={setTransferLeadIds}
                        userTransferParams={userTransferParams}
                      />
                    </Col>
                  ))
                ) : (
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card>
                      <div className='user-table-data'>
                        <NoDataFound />
                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
              {leadsData?.list_data?.length > 0 && (
                // <Pagination
                //   current={filters?.page}
                //   total={leadsData?.total_records}
                //   pageSize={filters?.per_page}
                //   onChange={handlePageChange}
                //   className='pt-4 text-center'
                // />

                <Pagination
                  current={filters?.page}
                  total={leadsData?.total_records}
                  pageSize={filters?.per_page}
                  onChange={handlePageChange} // Handles page change
                  onShowSizeChange={handlePageChange} // Handles page size change
                  showSizeChanger // Enables the dropdown to change page size
                  className='pt-4 text-center'
                />
              )}
            </div>
            <ModalComponent
              filters={filters}
              setFilters={setFilters}
              isModalOpen={isModalOpen}
              territoryData={territoryData}
              setIsModalOpen={setIsModalOpen}
              getLeadListing={getLeadListing}
              transferLeadIds={transferLeadIds}
              leadsInitialState={leadsInitialState}
              userTransferParams={userTransferParams}
              setTransferLeadIds={setTransferLeadIds}
              selectedLeadsLength={selectedLeadsLength}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Leads;
