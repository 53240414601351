/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, notification } from 'antd';
import moment from 'moment';
import PieChart from './charts/pieChart';
import ColumnChart from './charts/columnChart';
import LineChart from './charts/lineChart';
import { formatKeyName } from '../../../../utills/helpers';
import Loader from '../../../../components/common/Loader';
import Filters from './filters/CommonFilters';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardHomeData, saveDashboardHomeFilters } from '../../../auth/slice/authSlice';

const DashboardHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const dashboardHomeData = useSelector((state) => state?.auth?.dashboardDetails?.data);

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    userId: '',
    modelNo: '',
    startDate: '',
    endDate: '',
    // month_id: '',
    // year_id: '',
  });
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const route = isAdmin ? '/admin' : '';
  const checkUserName = roleType === 'Exchange Evaluation Team';
  const exchangeRoute = checkUserName ? '/exchange-lead' : '';

  useEffect(() => {
    // const queryStringValue = generateQueryString(reduxFilters);
    dispatch(saveDashboardHomeFilters(filters));
    // setQueryString(queryStringValue);
    getDashboardHomeDetails(filters);
  }, [filters]);

  //Api call to get dashboard home data
  const getDashboardHomeDetails = async () => {
    setIsLoading(true);
    try {
      const reqData = {
        userId: filters?.userId ? filters?.userId : '',
        modelId: filters?.modelNo ? filters?.modelNo : '',
        startDate: filters?.startDate ? moment(filters?.startDate).format('YYYY-MM-DD') : '',
        endDate: filters?.endDate ? moment(filters?.endDate).format('YYYY-MM-DD') : '',
        checkUserName,
      };
      // const response = await getDashboardHomeData(reqData);
      // setDashboardHomeData(response && response?.data && response?.data?.data);
      await dispatch(fetchDashboardHomeData(reqData));
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Pie Chart Lead Funel (Lead Type) Data
  const leadTypes = dashboardHomeData?.lead_type;
  const dataChartLeadType = [
    ['Lead Type', 'Lead Counts'],
    ['Live Leads', leadTypes?.live_leads],
    ['Hot Leads', leadTypes?.hot],
    ['Warm Leads', leadTypes?.warm],
    ['Cold Leads', leadTypes?.cold],
  ];

  // Pie Chart Lead Funel (Lead Stage) Data
  const leadStage = dashboardHomeData?.lead_stage;
  const dataChartLeadStage = [
    ['Lead Stage', 'Lead Counts'],
    ['Booked', leadStage?.booked],
    ['Pre Sales', leadStage?.preSales],
    ['Procured', leadStage?.procured],
    ['Retailed', leadStage?.retailed],
  ];

  // Common function to format API data into chart data format
  const formatDataForChart = (data, keys) => {
    return data && data?.map((item) => keys?.map((key) => item[key]));
  };

  // Format the data for the chart
  const formattedChartData = dashboardHomeData?.lead_quotation_breakup
    ? [
        ['Days', 'Lead Count', { role: 'annotation' }, 'Quotation Sent', { role: 'annotation' }],
        ...formatDataForChart(dashboardHomeData?.lead_quotation_breakup, [
          'interval',
          'lead_count',
          'lead_count',
          'quotation_count',
          'quotation_count',
        ]),
      ]
    : [];

  const formattedRetailerData = dashboardHomeData?.retail_breakup
    ? [
        ['Cars', 'Retail Number', { role: 'annotation' }],
        ...formatDataForChart(dashboardHomeData?.retail_breakup, ['model_name', 'model_count', 'model_count']),
      ]
    : [];

  const onPieChartSegmentClick = (selectedItem) => {
    const segmentLabel = selectedItem[0];
    PieChartRedirect(segmentLabel);
  };

  const PieChartRedirect = (segmentLabel) => {
    if (segmentLabel === 'Live Leads') {
      navigate(`${route}/leads${exchangeRoute}?lead_type=10&key=hot_lead`);
    } else if (segmentLabel === 'Hot Leads') {
      navigate(`${route}/leads${exchangeRoute}?lead_type=1&key=hot_lead`);
    } else if (segmentLabel === 'Warm Leads') {
      navigate(`${route}/leads${exchangeRoute}?lead_type=2&key=hot_lead`);
    } else if (segmentLabel === 'Cold Leads') {
      navigate(`${route}/leads${exchangeRoute}?lead_type=3&key=hot_lead`);
    } else if (segmentLabel === 'Pre Sales') {
      navigate(`${route}/leads${exchangeRoute}?lead_stage=1&key=hot_lead`);
    } else if (segmentLabel === 'Booked') {
      navigate(`${route}/leads${exchangeRoute}?lead_stage=2&key=hot_lead`);
    } else if (segmentLabel === 'Retailed') {
      navigate(`${route}/leads${exchangeRoute}?lead_stage=3&key=hot_lead`);
    } else if (segmentLabel === 'Procured') {
      navigate(`${route}/leads${exchangeRoute}?lead_stage=4&key=hot_lead`);
    }
  };

  const handleCardClick = (ele) => {
    const param = ele?.toLowerCase()?.split(' ')?.join('_');

    if (param === 'phone_call') {
      navigate(`${route}/leads?follow_up_date_id=1&key=hot_lead`);
    } else if (param === 'pending_for_procurement') {
      navigate(`${route}/leads${exchangeRoute}?need_procured="true"&key=hot_lead`);
    } else if (param === 'pending_for_valuation') {
      navigate(`${route}/leads${exchangeRoute}?need_evaluation="true"&key=hot_lead`);
    } else if (param === 'total') {
      navigate(`${route}/leads${exchangeRoute}?need_evaluation="true"&need_procured="true"&key=hot_lead`);
    }
  };

  // Task Ageing Table Column
  const task_ageing_column = [
    {
      title: 'Pending Task',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: '> 1 Day',
      dataIndex: 'greater_than_one_day',
      key: 'greater_than_one_day',
      render: (text) => (
        <>
          {/* <a
            href={`${route}/leads?greater_than_one_day="true"&key=hot_lead`}
            style={{ cursor: "pointer" }}
          >
            {text}
          </a> */}
          {+text !== 0 ? (
            <a href={`${route}/leads?greater_than_one_day="true"&key=hot_lead`} style={{ cursor: 'pointer' }}>
              {text}
            </a>
          ) : (
            text
          )}
        </>
      ),
    },
    {
      title: '> 10 Day',
      dataIndex: 'greater_than_ten_day',
      key: 'greater_than_ten_day',
      render: (text) => (
        <>
          {/* <a
            href={`${route}/leads?greater_than_ten_day="true"&key=hot_lead`}
            style={{ cursor: "pointer" }}
          >
            {text}
          </a> */}
          {+text !== 0 ? (
            <a href={`${route}/leads?greater_than_ten_day="true"&key=hot_lead`} style={{ cursor: 'pointer' }}>
              {text}
            </a>
          ) : (
            text
          )}
        </>
      ),
    },
    {
      title: '> 20 Day',
      dataIndex: 'greater_than_twenty_day',
      key: 'greater_than_twenty_day',
      render: (text) => (
        <>
          {/* <a
            href={`${route}/leads?greater_than_twenty_day="true"&key=hot_lead`}
            style={{ cursor: "pointer" }}
          >
            {text}
          </a> */}
          {+text !== 0 ? (
            <a href={`${route}/leads?greater_than_twenty_day="true"&key=hot_lead`} style={{ cursor: 'pointer' }}>
              {text}
            </a>
          ) : (
            text
          )}
        </>
      ),
    },
  ];

  // Enquiry Source Table Column
  const columnsEnquiry = [
    {
      title: 'Enquiry Source',
      dataIndex: 'enquiry_source',
      key: 'enquiry_source',
    },
    {
      title: 'Enquiry',
      dataIndex: 'enquiry',
      key: 'enquiry',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {+text !== 0 ? (
              <a
                // href={`${route}/leads?lead_source=${record?.enquiry_source_id}&key=hot_lead&lead_stage=1`}
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&key=hot_lead`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Test Drive',
      dataIndex: 'test_drive',
      key: 'test_drive',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&key=hot_lead&test_drive_completed="true"`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Home Visit',
      dataIndex: 'home_visit',
      key: 'home_visit',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&key=hot_lead&home_visit_completed="true"`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Booking',
      dataIndex: 'booking',
      key: 'booking',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                // href={`${route}/leads?lead_source=${record?.enquiry_source_id}&lead_stage=2&key=hot_lead`}
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&key=hot_lead&booking_completed=true`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Retail',
      dataIndex: 'retail',
      key: 'retail',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&lead_stage=3&key=hot_lead`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Exchange',
      dataIndex: 'exchange',
      key: 'exchange',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&key=hot_lead&exchange_car="true"`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Valuation',
      dataIndex: 'valuation',
      key: 'valuation',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&key=hot_lead&evaluation_completed="true"`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Procured',
      dataIndex: 'procured',
      key: 'procured',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&procured="true"&key=hot_lead`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
    {
      title: 'Lost',
      dataIndex: 'lost',
      key: 'lost',
      className: 'text-center',
      render: (text, record) =>
        record?.enquiry_source === 'Total' ? (
          text
        ) : (
          <>
            {text !== 0 ? (
              <a
                href={`${route}/leads?lead_source=${record?.enquiry_source_id}&lead_status=3&key=hot_lead`}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </a>
            ) : (
              text
            )}
          </>
        ),
    },
  ];

  return (
    <>
      <Loader loading={isLoading} />
      <div className='user-data p-0'>
        {/* <div className="user-table-head pb-3 d-flex justify-content-between align-items-center">
                    <div className="user-right">
                        <Space size="middle">
                            <Button icon={<FilterFilled />} />
                        </Space>
                    </div>
                </div> */}

        <Filters onFiltersChange={setFilters} heading={'Home'} />

        <Row gutter={20}>
          {/* Task of The Day */}
          <Col xl={12} lg={24} md={12} sm={24} xs={24} className='mb-4'>
            <Card className='me-lg-3'>
              <div className='day-task'>
                <div className='user-left'>
                  <h5 className='pb-3'>Task of The Day</h5>
                </div>
                <div className='task-wrapper'>
                  <Row gutter={[20, 20]} justify='center'>
                    {Object.entries(dashboardHomeData?.task_of_the_day || {}).map(([key, value]) => (
                      <Col xl={12} lg={12} md={24} sm={24} xs={24} className='mb-lg-0 mb-md-3' key={key}>
                        <Card
                          className='text-center cursor-pointer'
                          onClick={() => {
                            +value !== 0 && handleCardClick(formatKeyName(key));
                          }}
                        >
                          <p>{value}</p>
                          <span>{formatKeyName(key)}</span>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </Card>
          </Col>

          {/* Task Ageing Table */}
          {roleType === 'Exchange Evaluation Team' ? (
            ''
          ) : (
            <>
              <Col xl={12} lg={24} md={12} sm={24} xs={24} className='mb-4'>
                <Card className='ms-lg-3'>
                  <div className='user-table-data'>
                    <div className='user-left'>
                      <h5 className='pb-3'>Task Ageing Table</h5>
                    </div>
                    <div className='responsive-table-container'>
                      <Table
                        columns={task_ageing_column}
                        dataSource={dashboardHomeData?.task_ageing}
                        pagination={false}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            </>
          )}

          {/* Lead Funel (Lead Type) */}
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className='mb-4'>
            <Card className='ms-lg-3'>
              <div className='lead-funel-type'>
                <div className='user-left'>
                  <h5 className='pb-3'>Lead Funel (Lead Type)</h5>
                </div>
                <div className='lead-funel-type-wrapper'>
                  <PieChart onSegmentClick={onPieChartSegmentClick} data={dataChartLeadType} />
                </div>
              </div>
            </Card>
          </Col>

          {/* Lead Funel (Lead Stage) */}
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className='mb-4'>
            <Card className='me-lg-3'>
              <div className='lead-funel-stage'>
                <div className='user-left'>
                  <h5 className='pb-3'>Lead Funel (Lead Stage)</h5>
                </div>
                <div className='lead-funel-stage-wrapper'>
                  <PieChart
                    data={dataChartLeadStage}
                    onSegmentClick={onPieChartSegmentClick}
                    chartColors={['#f8b743', '#83eea6', '#eca7ee', '#94c9f1']}
                  />
                </div>
              </div>
            </Card>
          </Col>

          {/* Lead-bar-chart */}
          {roleType === 'Exchange Evaluation Team' ? (
            ''
          ) : (
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className='mb-4'>
              <Card className='me-lg-3'>
                <div className='user-table-data'>
                  <div className='lead-bar-chart'>
                    <ColumnChart data={formattedChartData} />
                  </div>
                </div>
              </Card>
            </Col>
          )}

          {/* lead-line-chart */}
          {roleType === 'Exchange Evaluation Team' ? (
            ''
          ) : (
            <>
              {dashboardHomeData && dashboardHomeData?.retail_breakup?.length !== 0 && (
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className='mb-4'>
                  <Card className=''>
                    <div className='user-table-data'>
                      <div className='user-left'>
                        <h5 className='pb-3 text-center'>Last Month Retail Breakup</h5>
                      </div>
                      <div className='lead-line-chart'>
                        <LineChart
                          fullRetailData={dashboardHomeData && dashboardHomeData?.retail_breakup}
                          data={formattedRetailerData}
                          chartColors={['#94959B']}
                          heading={'Last Month Retail Breakup'}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              )}
            </>
          )}

          {/* Enquiry Source Table */}
          {roleType === 'Exchange Evaluation Team' ? (
            ''
          ) : (
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className='user-table-data'>
                <div className='user-left'>
                  <h5 className='pb-3'>Enquiry Source Table</h5>
                </div>
                <div className='responsive-table-container'>
                  <Table
                    columns={columnsEnquiry}
                    dataSource={dashboardHomeData?.enquiry_source_table}
                    pagination={false}
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default DashboardHome;
