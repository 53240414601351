/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Card,
  Typography,
  Space,
  notification,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageContentHeader from '../../../components/common/PageContentHeader';
import {
  getAreasService,
  getBankerService,
  getCityService,
  getCustomerProfessionsService,
  getCustomersTypeService,
  getEnquirySourcesService,
  getEnquirySubSourcesService,
  getLeadService,
  getRtoService,
  getStateService,
  getTerritoriesService,
  getTramission,
  getVehicleColorService,
  getVehicleFuelTypeService,
  getVehicleMakeService,
  getVehicleModelService,
  getVehicleSeaterService,
  getVehicleVarientService,
} from '../../../services/master-services';
import Loader from '../../../components/common/Loader';
import {
  addNewLeadService,
  deleteDocuments,
  editLeadService,
  getExistingCustomerService,
  getLeadDetailsService,
} from '../../../services/admin-services';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../../utills/helpers';
import InputSelect from '../../../components/common/InputSelect';
import { fetchLeadDetailsServices } from '../../auth/slice/authSlice';

const { Title } = Typography;

const AddLeads = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [rtoData, setRtoData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [isExchange, setIsExchange] = useState();
  const [colorData, setColorData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [varientData, setVarientData] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);
  const [excFuelData, setExcFuelData] = useState([]);
  const [excModalData, setExcModalData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [leadsTypeData, setLeadsTypeData] = useState([]);
  const [customerDetailsDisable, setCustomerDetailsDisable] = useState(true);
  const [leadsDetailsData, setLeadDetailsData] = useState();
  const [excTransmissionData, setExcTransmissionData] = useState([]);
  const [enquirySubData, setEnquirySubData] = useState(leadsDetailsData?.enquiry_sub_source_id);
  const [excVarientData, setExcVarientData] = useState([]);
  const [customeTypeData, setCustomerTypeData] = useState([]);
  const [viewExchangeTab, setViewExchangeTab] = useState(false);
  const [customerProfessionData, setCustomerProfessionData] = useState([]);
  const [register, setRegister] = useState({
    state_id: 0,
    territory_id: 0,
  });

  const [vehicleIds, setVehicleIds] = useState({
    make_id: 1,
    model_id: '',
    variant_id: '',
  });
  const [excVehicleIds, setExcVehicleIds] = useState({
    make_id: '',
    model_id: '',
    variant_id: '',
    transmission_id: '',
  });

  const [fileList, setFileList] = useState({
    front_image: null,
    back_image: null,
    side_image: null,
    inside_image: null,
  });
  const route = useSelector((state) => state?.auth?.route);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  const isSentforEvaluation = leadsDetailsData?.exchange_vehicle_detail?.sent_for_evaluation === 1 ? true : false;

  useEffect(() => {
    if (pathname.includes('edit')) {
      getLeadsData(id);
      setEditMode(true);
      setIsExchange(leadsDetailsData?.exchange_car === 0 ? 'No' : 'Yes');
    }
    getBankData();
    getMakeData();
    getStateData();
    getEnquiryData();
    getLeadsTypeData();
    getCustomerTypeData();
    getCustomerProfessionData();
    getModalData(vehicleIds.make_id);
  }, []);

  useEffect(() => {
    if (leadsDetailsData?.id && editMode) {
      getRTOData(leadsDetailsData?.customer?.state_id);
      setViewExchangeTab(leadsDetailsData?.exchange_car === 'No' ? false : true);
      getTerritoryData(leadsDetailsData?.customer?.state_id);
      getModalData(leadsDetailsData?.exchange_vehicle_detail?.make?.id || vehicleIds?.make_id);
      getExcVarientData(leadsDetailsData?.exchange_vehicle_detail?.model?.id);
      getExcFuelData(
        leadsDetailsData?.exchange_vehicle_detail?.make?.id,
        leadsDetailsData?.exchange_vehicle_detail?.model?.id,
        leadsDetailsData?.exchange_vehicle_detail?.variant?.id,
        leadsDetailsData?.exchange_vehicle_detail?.transmission?.id
      );
      getAreaData(leadsDetailsData?.customer?.state_id, leadsDetailsData?.customer?.territory_id);
      getCityData(leadsDetailsData?.customer?.state_id);
      getVarientData(leadsDetailsData?.vehicle_detail?.vehicle_model_id);
      getColorData(
        leadsDetailsData?.vehicle_detail?.model?.make_id,
        leadsDetailsData?.vehicle_detail?.vehicle_model_id,
        leadsDetailsData?.vehicle_detail?.vehicle_variant_id
      );
      getFuelData(
        leadsDetailsData?.vehicle_detail?.model?.make_id,
        leadsDetailsData?.vehicle_detail?.vehicle_model_id,
        leadsDetailsData?.vehicle_detail?.vehicle_variant_id
      );
      handleLeadsTransmission(
        leadsDetailsData?.exchange_vehicle_detail?.make?.id,
        leadsDetailsData?.exchange_vehicle_detail?.model?.id,
        leadsDetailsData?.exchange_vehicle_detail?.variant?.id
      );
      getSeatData(
        leadsDetailsData?.vehicle_detail?.model?.make_id,
        leadsDetailsData?.vehicle_detail?.vehicle_model_id,
        leadsDetailsData?.vehicle_detail?.vehicle_variant_id
      );
      form.setFieldsValue({
        lead_type_id: leadsDetailsData?.lead_type_id,
        lead_status_id: leadsDetailsData?.lead_status_id,
        lead_stage_id: leadsDetailsData?.lead_stage_id,
        full_name: leadsDetailsData?.customer?.full_name,
        age: leadsDetailsData?.customer?.age,
        profession_id: leadsDetailsData?.customer?.profession_id,
        email: leadsDetailsData?.customer?.email,
        phone_no: leadsDetailsData?.customer?.phone_no,
        alt_phone_no: leadsDetailsData?.customer?.alt_phone_no,
        address: leadsDetailsData?.customer?.address,
        state_id: leadsDetailsData?.customer?.state_id,
        city_id: leadsDetailsData?.customer?.city_id,
        rto_location_id: leadsDetailsData?.customer?.rto_location_id,
        territory_id: leadsDetailsData?.customer?.territory_id,
        area_id: leadsDetailsData?.customer?.area_id,
        customer_type_id: leadsDetailsData?.customer?.customer_type_id,
        enquiry_source_id: leadsDetailsData?.enquiry_source_id,
        enquiry_sub_source_id: leadsDetailsData?.enquiry_sub_source_id,
        exchange_car: leadsDetailsData?.exchange_car,
        bank_id: leadsDetailsData?.bank_id,
        choice_vehicle_no: leadsDetailsData?.choice_vehicle_no,
        expected_delivery_date: leadsDetailsData?.expected_delivery_date
          ? dayjs(leadsDetailsData?.expected_delivery_date)
          : null,
        vehicle_model_id: leadsDetailsData?.vehicle_detail?.vehicle_model_id,
        vehicle_variant_id: leadsDetailsData?.vehicle_detail?.vehicle_variant_id,
        color_id: leadsDetailsData?.vehicle_detail?.color_id,
        fuel_type_id: leadsDetailsData?.vehicle_detail?.fuel_type_id,
        seater_id: leadsDetailsData?.vehicle_detail?.seater_id,
        next_follow_up_date: leadsDetailsData?.next_follow_up_date && dayjs(leadsDetailsData?.next_follow_up_date),
        remark: leadsDetailsData?.vehicle_remarks,
        exchange_make_id: leadsDetailsData?.exchange_vehicle_detail?.make?.id,
        exchange_vehicle_model_id: leadsDetailsData?.exchange_vehicle_detail?.model?.id,
        exchange_vehicle_variant_id: leadsDetailsData?.exchange_vehicle_detail?.vehicle_variant_id,
        exchange_fuel_type_id: leadsDetailsData?.exchange_vehicle_detail?.fuel_type_id,
        odometer: leadsDetailsData?.exchange_vehicle_detail?.odometer,
        remark2: leadsDetailsData?.exchange_vehicle_detail?.remarks,
        expected_price: leadsDetailsData?.exchange_vehicle_detail?.expected_price,
        year:
          leadsDetailsData?.exchange_vehicle_detail?.car_year &&
          dayjs(leadsDetailsData?.exchange_vehicle_detail?.car_year),
        insurance_validity:
          leadsDetailsData?.exchange_vehicle_detail?.insurance_validity &&
          dayjs(leadsDetailsData?.exchange_vehicle_detail?.insurance_validity),
        exchange_transmission_id: leadsDetailsData?.exchange_vehicle_detail?.transmission?.id,
      });
      leadsDetailsData?.enquiry_sub_source_id && getEnquirySubData(leadsDetailsData?.enquiry_source_id);
      setUploadComponentData(leadsDetailsData?.documents);
      setRegister((state) => ({
        ...state,
        state_id: leadsDetailsData?.state_id,
        territory_id: leadsDetailsData?.territory_id,
      }));
      setVehicleIds((state) => ({
        ...state,
        make_id: leadsDetailsData?.vehicle_detail?.model?.make_id,
        model_id: leadsDetailsData?.vehicle_detail?.vehicle_model_id,
        variant_id: leadsDetailsData?.vehicle_detail?.vehicle_variant_id,
      }));
      setExcVehicleIds((state) => ({
        ...state,
        make_id: leadsDetailsData?.exchange_vehicle_detail?.make_id,
        model_id: leadsDetailsData?.exchange_vehicle_detail?.model?.id,
        variant_id: leadsDetailsData?.exchange_vehicle_detail?.vehicle_variant_id,
      }));
    }
  }, [leadsDetailsData]);

  const handleEnquiry = (e) => {
    getEnquirySubData(e);
    form.setFieldsValue({ enquiry_sub_source_id: null });
  };
  const setUploadComponentData = (imagesData) => {
    const formattedFileLists = {
      front_image: [],
      back_image: [],
      side_image: [],
      inside_image: [],
    };

    imagesData.forEach((doc) => {
      const file = {
        uid: doc.id,
        name: doc.document_file_name,
        status: 'done',
        url: doc.document_path,
      };

      switch (doc.document_type) {
        case 'Front':
          formattedFileLists.front_image.push(file);
          break;
        case 'Back':
          formattedFileLists.back_image.push(file);
          break;
        case 'Side':
          formattedFileLists.side_image.push(file);
          break;
        case 'Inside':
          formattedFileLists.inside_image.push(file);
          break;
        default:
          break;
      }
    });

    setFileList(formattedFileLists);
  };

  const validateFile = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file');
      return Upload.LIST_IGNORE; // Reject file upload
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must be smaller than 10MB');
      return Upload.LIST_IGNORE; // Reject file upload
    }

    return true; // Allow file upload
  };

  const handleFileChange = (info, field) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);

    setFileList((prev) => ({
      ...prev,
      [field]: newFileList,
    }));
  };

  const handleStateChange = (value) => {
    setRegister((state) => ({
      ...state,
      state_id: value,
    }));
    form.setFieldsValue({
      city_id: null,
      territory_id: null,
      area_id: null,
      rto_location_id: null,
    });
    setAreaData([]);
    getRTOData(value);
    getCityData(value);
    getTerritoryData(value);
  };

  const handleExcMakeChange = (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      make_id: value,
    }));

    form.setFieldsValue({
      exchange_vehicle_model_id: undefined,
      exchange_vehicle_variant_id: undefined,
      exchange_fuel_type_id: undefined,
      exchange_transmission_id: undefined,
    });
    setExcVarientData([]);
    setExcFuelData([]);
    setExcTransmissionData([]);
    getModalData(value);
  };

  const handleExcModalChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      model_id: value,
    }));
    form.setFieldsValue({
      exchange_vehicle_variant_id: undefined,
      exchange_fuel_type_id: undefined,
      exchange_transmission_id: undefined,
    });
    setExcFuelData([]);
    setExcTransmissionData([]);
    getExcVarientData(value);
  };

  const handleExcVariantChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      variant_id: value,
    }));

    form.setFieldsValue({
      exchange_fuel_type_id: undefined,
      exchange_transmission_id: undefined,
    });
    handleLeadsTransmission(excVehicleIds?.make_id, excVehicleIds?.model_id, value);
    setExcFuelData([]);
  };

  const handleExcTransmissionChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      transmission_id: value,
    }));
    form.setFieldsValue({
      exchange_fuel_type_id: undefined,
    });
    getExcFuelData(excVehicleIds?.make_id, excVehicleIds?.model_id, excVehicleIds?.variant_id, value);
  };

  const handleModalIdChange = (value) => {
    setVehicleIds((state) => ({
      ...state,
      model_id: value,
    }));

    form.setFieldsValue({
      vehicle_variant_id: undefined,
      color_id: undefined,
      fuel_type_id: undefined,
      seater_id: undefined,
    });
    setColorData([]);
    setSeatData([]);
    setFuelData([]);
    getVarientData(value);
  };

  const handleVariantIdChange = (value) => {
    setVehicleIds((state) => ({
      ...state,
      variant_id: value,
    }));
    form.setFieldsValue({
      color_id: undefined,
      fuel_type_id: undefined,
      seater_id: undefined,
    });
    getColorData(vehicleIds?.make_id, vehicleIds?.model_id, value);
    getFuelData(vehicleIds?.make_id, vehicleIds?.model_id, value);
    // handleLeadsTransmission(vehicleIds?.make_id, vehicleIds?.model_id, value);
    getSeatData(vehicleIds?.make_id, vehicleIds?.model_id, value);
  };

  const handleTerritoryChange = (value) => {
    setRegister((state) => ({
      ...state,
      territory_id: value,
    }));
    form.setFieldsValue({
      area_id: undefined,
    });
    if (editMode) {
      getAreaData(register.state_id, value);
    } else {
      getAreaData(register.state_id, value);
    }
  };

  const getLeadsData = async (id) => {
    setIsLoading(true);
    try {
      const res = await getLeadDetailsService(id);
      setLeadDetailsData(res?.data?.data);
      setIsExchange(res?.data?.data?.exchange_car === 'No' ? false : true);
      if (res?.data?.data?.exchange_car) {
        // getModalData(res?.data?.data?.exchange_vehicle_detail?.make?.id);
        handleExcMakeChange(res?.data?.data?.exchange_vehicle_detail?.make_id);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRTOData = async (state_id) => {
    setIsLoading(true);
    try {
      const stateData = await getRtoService(state_id);
      setRtoData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomerTypeData = async () => {
    setIsLoading(true);
    try {
      const customerTypeDataArray = await getCustomersTypeService();
      setCustomerTypeData(customerTypeDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getEnquiryData = async () => {
    setIsLoading(true);
    try {
      const enquiryDataArray = await getEnquirySourcesService();
      setEnquiryData(enquiryDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getEnquirySubData = async (id) => {
    setIsLoading(true);
    try {
      const enquirySubDataArray = await getEnquirySubSourcesService(id);
      setEnquirySubData(enquirySubDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getBankData = async () => {
    setIsLoading(true);
    try {
      const bankDataArray = await getBankerService(!id ? true : null);
      setBankData(bankDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadsTypeData = async () => {
    setIsLoading(true);
    try {
      const leadsDataArray = await getLeadService();
      setLeadsTypeData(leadsDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomerProfessionData = async () => {
    setIsLoading(true);
    try {
      const customerProfessionArray = await getCustomerProfessionsService();
      setCustomerProfessionData(customerProfessionArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getModalData = async (value) => {
    setIsLoading(true);
    try {
      if (isExchange) {
        const ExModalDataArray = await getVehicleModelService(value, false);
        setExcModalData(ExModalDataArray?.data?.data);
      } else {
        const addModalDataArray = await getVehicleModelService(1, true);
        setModalData(addModalDataArray?.data?.data);
      }
      if (leadsDetailsData?.exchange_vehicle_detail?.make?.id) {
        const editExModalDataArray = await getVehicleModelService(value, false);
        setExcModalData(editExModalDataArray?.data?.data);
      }

      // isExchange && setExcModalData(modalDataArray?.data?.data);
      // setModalData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getMakeData = async () => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleMakeService();
      setMakeData(modalDataArray?.data?.data);
      if (editMode) {
        getModalData(leadsDetailsData?.exchange_vehicle_detail?.make?.id);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getVarientData = async (model_id) => {
    setIsLoading(true);
    try {
      const varientDataArray = await getVehicleVarientService(model_id, true);
      setVarientData(varientDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getExcVarientData = async (model_id) => {
    setIsLoading(true);
    try {
      const varientDataArray = await getVehicleVarientService(model_id, false);
      setExcVarientData(varientDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getCityData = async (state_id) => {
    setIsLoading(true);
    try {
      const cityDataArray = await getCityService(state_id);
      setCityData(cityDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTerritoryData = async (state_id) => {
    setIsLoading(true);
    try {
      const territoryData = await getTerritoriesService(state_id, !id ? true : null);
      setTerritoryData(territoryData?.data?.data);
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAreaData = async (state_id, territory_id) => {
    setIsLoading(true);
    try {
      const areaData = await getAreasService(state_id, territory_id, !id ? true : null);
      setAreaData(areaData?.data?.data);
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getColorData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const colorData = await getVehicleColorService(make_id, model_id, variant_id, !id ? true : null);
      setColorData(colorData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getSeatData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const seatData = await getVehicleSeaterService(make_id, model_id, variant_id, !id ? true : null);
      setSeatData(seatData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getFuelData = async (make_id, model_id, variant_id, transmission_id) => {
    setIsLoading(true);
    try {
      const fuelData = await getVehicleFuelTypeService(
        make_id,
        model_id,
        variant_id,
        transmission_id,
        !id ? true : null,
        isExchange && !id ? true : null
      );
      setFuelData(fuelData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const getExcFuelData = async (make_id, model_id, variant_id, transmission_id) => {
    setIsLoading(true);
    try {
      const fuelData = await getVehicleFuelTypeService(
        make_id,
        model_id,
        variant_id,
        transmission_id,
        !id ? true : null,
        true
      );
      setExcFuelData(fuelData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLeadsTransmission = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const transmissionData = await getTramission(
        make_id,
        model_id,
        variant_id,
        true,
        isExchange && !id ? true : null
      );
      isExchange && setExcTransmissionData(transmissionData?.data?.data);
      // dispatch(fetchLeadsTransmission(make_id, model_id, variant_id));
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleExchangeCar = (value) => {
    setIsExchange(value === 'Yes' ? true : false);
    setViewExchangeTab(value === 'Yes' ? true : false);
  };

  const handleRemoveDocument = async (file) => {
    if (!file.lastModifiedDate) {
      try {
        const documentId = file?.uid;
        const reqData = {
          document_id: documentId ? documentId : null,
          document_type: '',
          lead_id: id,
        };

        if (leadsDetailsData?.documents) {
          const response = await deleteDocuments(reqData);
          message.success(response?.data?.message || 'File removed successfully');
          setFileList({
            ...fileList,
            [name]: fileList[name]?.filter((item) => item.uid !== file.uid),
          });
          dispatch(fetchLeadDetailsServices({ id: id }));
        } else {
          message.error(`Failed to remove file. No document ID available.`);
        }
      } catch (error) {
        message.error(`Failed to remove file: ${error.message}`);
      }
    }
  };

  const onFinish = async (values) => {
    delete values.front_image;
    delete values.back_image;
    delete values.side_image;
    delete values.inside_image;
    try {
      setIsLoading(true);
      const formData = new FormData();

      // Append files to the formData
      Object.keys(fileList).forEach((key) => {
        if (fileList[key]?.length > 0 && fileList[key][0].originFileObj) {
          formData.append(key, fileList[key][0].originFileObj);
        }
      });

      // Convert year to just the year string
      if (values.year instanceof Object) {
        values.year = values.year.$y; // Extract year from the date object
      }

      // Format date fields
      const dateFields = ['expected_delivery_date', 'next_follow_up_date', 'insurance_validity'];
      dateFields.forEach((field) => {
        if (values[field] instanceof Object) {
          values[field] = formatDate(values[field]);
        }
      });

      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== undefined && v !== null)
      );

      // Append other form fields to the formData, ensuring no undefined or null values are included
      for (let key in filteredValues) {
        formData.append(key, filteredValues[key]);
      }

      let res;
      if (editMode) {
        const formDataFiltered = new FormData();
        formData.forEach((value, key) => {
          if (value !== undefined && value !== null) {
            formDataFiltered.append(key, value);
          }
        });
        formDataFiltered.append('id', id);
        formDataFiltered.append('vehicle_details_id', leadsDetailsData?.vehicle_detail?.id);
        formDataFiltered.append('customer_id', leadsDetailsData?.customer_id);
        // formDataFiltered.append(
        //   "vehicle_exchange_details_id",
        //   leadsDetailsData?.exchange_vehicle_detail?.id || null
        // );
        res = await editLeadService(formDataFiltered);
      } else {
        res = await addNewLeadService(formData);
      }
      if (res.data.status === API_RESPONSE_CODES.OK || res.data.status === API_RESPONSE_CODES.CREATED) {
        notification.success({
          message: res.data.message,
        });
        navigate(-1);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
        style: {
          width: 400,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    if (editMode) {
      navigate(-1);
    } else {
      navigate(isAdmin ? `${route}/leads` : '/leads');
    }
  };

  const getExistingCustomerDetails = async (ele) => {
    const phone_no = ele.target.value;
    try {
      setIsLoading(true);
      const response = await getExistingCustomerService(phone_no);
      if (response.status === API_RESPONSE_CODES?.OK && response?.data?.data) {
        setCustomerDetailsDisable(false);
        const existingCustomerData = response?.data?.data;
        getTerritoryData(existingCustomerData?.state_id);
        getRTOData(existingCustomerData?.state_id);
        getCityData(existingCustomerData?.state_id);
        getAreaData(existingCustomerData?.state_id, existingCustomerData?.territory_id);

        setRegister((state) => ({
          ...state,
          state_id: existingCustomerData?.state_id,
          territory_id: existingCustomerData?.territory_id,
        }));

        form.setFieldsValue({
          full_name: existingCustomerData?.full_name,
          age: existingCustomerData?.age,
          profession_id: existingCustomerData?.profession_id,
          email: existingCustomerData?.email,
          phone_no: existingCustomerData?.phone_no,
          alt_phone_no: existingCustomerData?.alt_phone_no,
          address: existingCustomerData?.address,
          state_id: existingCustomerData?.state_id,
          city_id: existingCustomerData?.city_id,
          rto_location_id: existingCustomerData?.rto_location_id,
          territory_id: existingCustomerData?.territory_id,
          area_id: existingCustomerData?.area_id,
          customer_type_id: existingCustomerData?.customer_type_id,
        });
      } else {
        form.setFieldsValue({
          full_name: '',
          age: '',
          profession_id: null,
          email: '',
          alt_phone_no: '',
          address: '',
          state_id: null,
          city_id: null,
          rto_location_id: null,
          territory_id: null,
          area_id: null,
          customer_type_id: null,
        });
        setCustomerDetailsDisable(false);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='user-data'>
        <Loader loading={isLoading} />
        <PageContentHeader title={editMode ? 'Edit Lead' : 'Add Lead'} />
        <div className='user-table-head d-flex justify-content-between align-items-center'>
          <Form
            layout='vertical'
            className='login-form'
            onFinish={onFinish}
            initialValues={{
              remember: true,
            }}
            form={form}
          >
            {/* customer details */}
            <div className='personal-detail pt-2'>
              <Card>
                <Row gutter={20} justify='start'>
                  {/* Lead type  */}
                  <Col xl={8} lg={16} md={16} sm={24} xs={24}>
                    <Form.Item
                      name='lead_type_id'
                      layout='inline'
                      label='Lead Type'
                      className='form-item-inline add-lead-form'
                      rules={[
                        {
                          required: true,
                          message: 'Please input lead type',
                        },
                      ]}
                    >
                      <Select placeholder='Lead' className='form-control-ant'>
                        {leadsTypeData?.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div className='user-left text-start'>
                  <Title level={4} className='py-2'>
                    Customer Details
                  </Title>
                </div>
                <div className='detail-wrapper'>
                  <div className=''>
                    <Row gutter={20} justify='start'>
                      {/* Phone Number */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='phone_no'
                          label='Contact Number'
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                            {
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.reject('Please input your contact number');
                                }
                                if (!/^[0-9]*$/.test(value)) {
                                  return Promise.reject('Contact number must be digits only ');
                                }
                                if (value?.length < 10 || value?.length > 10) {
                                  return Promise.reject('Please enter a valid 10 digit contact number');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            onBlur={getExistingCustomerDetails}
                            placeholder='Contact Number'
                            className='form-control-ant'
                          />
                        </Form.Item>
                      </Col>
                      {/* Name */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='full_name'
                          label='Name'
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject('Please input your name');
                                }
                                if (!/^[A-Za-z\s]+$/.test(value)) {
                                  return Promise.reject('Please input a valid name');
                                }
                                if (/\s{2,}/.test(value)) {
                                  return Promise.reject('Name cannot contain two consecutive spaces');
                                }
                                const trimmedValue = value.trim();
                                if (trimmedValue !== value) {
                                  return Promise.reject('Name cannot start or end with spaces');
                                }
                                if (value?.length < 3 || value?.length > 30) {
                                  return Promise.reject('Name must be between 3 and 30 characters');
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            disabled={customerDetailsDisable && !editMode}
                            placeholder='Name'
                            className='form-control-ant'
                          />
                        </Form.Item>
                      </Col>
                      {/* Email */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='email'
                          label='Email'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your email id',
                            },
                            {
                              type: 'email',
                              message: 'Please enter a valid email id',
                            },
                            {
                              validator: (_, value) => {
                                if (value && /@-/.test(value)) {
                                  return Promise.reject('Please enter a valid email id');
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              max: 254,
                              message: 'Email must be at most 254 characters long',
                            },
                          ]}
                        >
                          <Input
                            placeholder='Email'
                            className='form-control-ant'
                            disabled={customerDetailsDisable && !editMode}
                          />
                        </Form.Item>
                      </Col>
                      {/* Age  */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='age'
                          label='Age'
                          rules={[
                            {
                              required: false,
                            },
                            {
                              validator: (_, value) => {
                                if (value && (!/^\d+$/.test(value) || value < 18 || value > 100)) {
                                  return Promise.reject('Enter a valid age between 18 and 100');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            devices
                            type='text'
                            pattern='[0-9]*'
                            placeholder='Age'
                            inputMode='numeric'
                            className='form-control-ant'
                            disabled={customerDetailsDisable && !editMode}
                          />
                        </Form.Item>
                      </Col>
                      {/* Profession */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='profession_id'
                          label='Profession'
                          rules={[
                            {
                              required: false,
                              message: 'Please input your profession',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Profession'
                            className='form-control-ant'
                            disabled={customerDetailsDisable && !editMode}
                          >
                            {customerProfessionData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Alternate Phone Number */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='alt_phone_no'
                          label='Alternate Contact Number'
                          rules={[
                            {
                              required: false,
                              message: '',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.resolve(); // Skip validation if the field is empty
                                }
                                if (!/^[0-9]*$/.test(value)) {
                                  return Promise.reject('Contact number must be digits only');
                                }
                                if (value?.length !== 10) {
                                  return Promise.reject('Please enter a valid 10 digit contact number');
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            className='form-control-ant'
                            placeholder='Alternate Contact Number'
                            disabled={customerDetailsDisable && !editMode}
                          />
                        </Form.Item>
                      </Col>

                      {/* Address */}
                      <Col xl={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name='address'
                          label='Address'
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject('Please input your address');
                                }

                                if (/\s{2,}/.test(value)) {
                                  return Promise.reject('Address cannot contain two consecutive spaces');
                                }
                                const trimmedValue = value.trim();
                                if (trimmedValue !== value) {
                                  return Promise.reject('Address cannot start or end with spaces');
                                }
                                if (value?.length > 100) {
                                  return Promise.reject('Address must not exceed 100 characters');
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            placeholder='Address'
                            className='form-control-ant'
                            disabled={customerDetailsDisable && !editMode}
                          />
                        </Form.Item>
                      </Col>

                      {/* Statd Id */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='state_id'
                          label='State'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your state',
                            },
                          ]}
                        >
                          <Select
                            placeholder='State'
                            className='form-control-ant'
                            onChange={handleStateChange}
                            disabled={customerDetailsDisable && !editMode}
                          >
                            {stateData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* City Id  */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='city_id'
                          label='City'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your city',
                            },
                          ]}
                        >
                          <Select placeholder='City' className='form-control-ant' disabled={register.state_id === 0}>
                            {cityData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Rto location  */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='rto_location_id'
                          label='RTO Location'
                          rules={[
                            {
                              required: false,
                              message: 'Please select your rto',
                            },
                          ]}
                        >
                          <Select
                            disabled={register.state_id === 0}
                            className='form-control-ant'
                            placeholder='RTO Location'
                          >
                            {rtoData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Territory Id  */}
                      <Col xl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name='territory_id'
                          label='Territory'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your territory',
                            },
                          ]}
                        >
                          <Select
                            disabled={register.state_id === 0}
                            placeholder='Territory'
                            className='form-control-ant'
                            onChange={handleTerritoryChange}
                          >
                            {territoryData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Area Id  */}
                      <Col xl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name='area_id'
                          label='Area'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your area',
                            },
                          ]}
                        >
                          <Select
                            disabled={register.territory_id === 0}
                            className='form-control-ant'
                            placeholder='Area'
                          >
                            {areaData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Customer Type  */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='customer_type_id'
                          label='Customer Type'
                          rules={[
                            {
                              required: false,
                              message: 'Please input your desgination',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Customer'
                            className='form-control-ant'
                            disabled={customerDetailsDisable && !editMode}
                          >
                            {customeTypeData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Enquiry Source Id  */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='enquiry_source_id'
                          label='Enquiry Source'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your enquiry source',
                            },
                          ]}
                        >
                          <Select onChange={handleEnquiry} className='form-control-ant' placeholder='Enquiry Source'>
                            {enquiryData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.source}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Enquiry Sub Source Id  */}
                      <Col xl={8} md={8} sm={24} xs={24}>
                        {enquirySubData?.length > 1 ? (
                          <Form.Item
                            name='enquiry_sub_source_id'
                            label='Enquiry Sub Source'
                            rules={[
                              {
                                required: true,
                                message: 'Please select your enquiry sub source',
                              },
                            ]}
                          >
                            <Select className='form-control-ant' placeholder='Enquiry Sub Source'>
                              {enquirySubData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.source}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <></>
                        )}
                      </Col>

                      {/* Exchange Car  */}
                      <Col xl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name='exchange_car'
                          label='Exchange Car'
                          initialValue='No'
                          rules={[
                            {
                              required: true,
                              message: 'Please select exchange ',
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Exchange Car' onChange={handleExchangeCar}>
                            <Select.Option value={'Yes'}>Yes</Select.Option>
                            <Select.Option value={'No'}>No</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Banker Id  */}
                      <Col xl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name='bank_id'
                          label='Banker'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your banker',
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Banker'>
                            {bankData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Choice Of Vehicle Number  */}
                      <Col xl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name='choice_vehicle_no'
                          label='Choice of Vehicle number'
                          rules={[
                            {
                              required: false,
                            },
                            {
                              pattern: /^[0-9]{4}$/,
                              message: 'Please enter a valid 4-digit vehicle number',
                            },
                          ]}
                        >
                          <Input placeholder='Choice of Vehicle Number' className='form-control-ant' />
                        </Form.Item>
                      </Col>

                      {/* Expected Date of Delivery  */}
                      <Col xl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name='expected_delivery_date'
                          label='Expected Date of Delivery'
                          rules={[
                            {
                              required: false,
                              message: 'Please input your delivery date',
                            },
                          ]}
                        >
                          <DatePicker
                            format='DD/MM/YYYY'
                            disabledDate={(current) => current && current < dayjs().startOf('day')}
                            placeholder='Select Date'
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
                {/* vehicle details */}
                <div className='user-left text-start'>
                  <Title level={4} className='pb-2'>
                    Vehicle Details
                  </Title>
                </div>
                <div className='detail-wrapper'>
                  <div className=''>
                    <Row gutter={20} justify='start'>
                      {/* Vehicle Modal  */}
                      <Col xl={4} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='vehicle_model_id'
                          label='Model'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your car model',
                            },
                          ]}
                        >
                          <Select placeholder='Model' onChange={handleModalIdChange} className='form-control-ant'>
                            {modalData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.model_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Vehicle Variant  */}
                      <Col xl={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='vehicle_variant_id'
                          label='Car Variant'
                          rules={[
                            {
                              required: true,
                              message: 'Please select your car variant',
                            },
                          ]}
                        >
                          <Select
                            // disabled={modalData?.length < 1}
                            onChange={handleVariantIdChange}
                            className='form-control-ant'
                            placeholder='Car variant'
                          >
                            {varientData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.variant_code}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Vehicle Color  */}
                      <Col xl={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='color_id'
                          label='Color'
                          rules={[
                            {
                              required: false,
                              message: 'Please input your car color',
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Color'>
                            {colorData?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option?.color}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Fuel Type  */}
                      <Col xl={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='fuel_type_id'
                          label='Fuel'
                          rules={[
                            {
                              required: true,
                              message: 'Please select fuel type',
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Fuel'>
                            {fuelData &&
                              fuelData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.title}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Seater Id  */}
                      <Col xl={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          name='seater_id'
                          label='Seater'
                          rules={[
                            {
                              required: true,
                              message: `Please select seater's count`,
                            },
                          ]}
                        >
                          <Select className='form-control-ant' placeholder='Seater'>
                            {seatData &&
                              seatData?.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                  {option?.seater}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* Next follow up  */}
                      <Col xl={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          label='Next Follow Up Date'
                          name='next_follow_up_date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select next follow up date ',
                            },
                          ]}
                        >
                          <DatePicker
                            format='DD/MM/YYYY'
                            disabledDate={(current) => {
                              return current < dayjs().startOf('day') || current > dayjs().add(10, 'day').endOf('day');
                            }}
                            placeholder='Select Date'
                          />
                        </Form.Item>
                      </Col>

                      {/* Remark  */}
                      <Col xl={6} md={12} sm={24} className='remark-box w-100'>
                        <Form.Item
                          label='Remark'
                          name='remark'
                          rules={[
                            {
                              required: false,
                              message: 'Please input remark',
                            },
                            {
                              max: 300,
                              message: 'Remark cannot exceed 300 characters',
                            },
                          ]}
                        >
                          <Input.TextArea placeholder='Remark' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* exchange details */}
                {viewExchangeTab && (
                  <>
                    <div className='user-left text-start'>
                      <Title level={4} className='pb-2'>
                        Exchange Details
                      </Title>
                    </div>
                    <div className='detail-wrapper'>
                      <div className=''>
                        <Row gutter={20} justify='start'>
                          {/* Make Id  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='exchange_make_id'
                              label='Make'
                              rules={[
                                isExchange && {
                                  required: true,
                                  message: 'Please select make',
                                },
                              ]}
                            >
                              <Select
                                onChange={handleExcMakeChange}
                                className='form-control-ant'
                                disabled={!isExchange || isSentforEvaluation}
                                placeholder='Make'
                              >
                                {makeData?.map((option) => (
                                  <Select.Option key={option.id} value={option.id}>
                                    {option?.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* Modal Id  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='exchange_vehicle_model_id'
                              label='Model'
                              rules={[
                                isExchange && {
                                  required: true,
                                  message: 'Please select your car model',
                                },
                              ]}
                            >
                              <Select
                                className='form-control-ant'
                                onChange={handleExcModalChange}
                                placeholder='Model'
                                disabled={!isExchange || isSentforEvaluation}
                              >
                                {excModalData?.map((option) => (
                                  <Select.Option key={option.id} value={option.id}>
                                    {option?.model_name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* Car Variant  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='exchange_vehicle_variant_id'
                              label='Car Variant'
                              rules={[
                                isExchange && {
                                  required: true,
                                  message: 'Please select your car variant',
                                },
                              ]}
                            >
                              <Select
                                className='form-control-ant'
                                placeholder='Car Variant'
                                onChange={handleExcVariantChange}
                                disabled={!isExchange || isSentforEvaluation}
                              >
                                {excVarientData?.map((option) => (
                                  <Select.Option key={option.id} value={option.id}>
                                    {option?.variant_code}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* Car Transmission  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <InputSelect
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select your car transmission',
                                },
                              ]}
                              // disabled={disable === false ? true : false}
                              name='exchange_transmission_id'
                              placeholder='Transmission'
                              options={excTransmissionData}
                              onSelect={handleExcTransmissionChange}
                              label='Transmission'
                              optionName={'name'}
                              disable={isSentforEvaluation}
                              allowClear={false}
                            />
                          </Col>

                          {/* Fuel  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='exchange_fuel_type_id'
                              label='Fuel'
                              rules={[
                                isExchange && {
                                  required: true,
                                  message: 'Please select fuel type',
                                },
                              ]}
                            >
                              <Select
                                className='form-control-ant'
                                placeholder='Fuel'
                                disabled={!isExchange || isSentforEvaluation}
                              >
                                {excFuelData &&
                                  excFuelData?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                      {option?.title}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* Year  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='year'
                              label='Year'
                              rules={[
                                isExchange && {
                                  required: true,
                                  message: 'Please select year',
                                },
                              ]}
                            >
                              <DatePicker
                                picker='year'
                                disabled={!isExchange || isSentforEvaluation}
                                disabledDate={(current) => {
                                  return current && dayjs(current).isAfter(dayjs().endOf('year'), 'day');
                                }}
                                placeholder='Select Year'
                              />
                            </Form.Item>
                          </Col>

                          {/* Odometer */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='odometer'
                              label='Odometer'
                              rules={[
                                {
                                  required: true,
                                  validator: (_, value) => {
                                    if (!value) {
                                      return Promise.reject(new Error('Please input your odometer details'));
                                    } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                                      return Promise.reject(new Error('The input must be numeric'));
                                    } else if (value?.length < 2) {
                                      return Promise.reject(new Error('The input must be at least 2 digits long'));
                                    } else if (value?.length > 10) {
                                      return Promise.reject(
                                        new Error('Odometer reading cannot be more than 10 digits')
                                      );
                                    } else if (/\s/.test(value)) {
                                      return Promise.reject(new Error('The input must not contain whitespace'));
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input
                                placeholder='Odometer'
                                className='form-control-ant'
                                disabled={!isExchange || isSentforEvaluation}
                              />
                            </Form.Item>
                          </Col>

                          {/* Expected Price  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='expected_price'
                              label='Expected Price'
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your expected price',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value) {
                                      // If the field is empty, it's considered valid
                                      return Promise.resolve();
                                    }
                                    if (!/^\d{1,10}(\.\d{1,2})?$/.test(value)) {
                                      return Promise.reject('Please enter a valid Expected price less than 10 digits');
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input
                                disabled={!isExchange || isSentforEvaluation}
                                placeholder='Expected Price'
                                className='form-control-ant'
                              />
                            </Form.Item>
                          </Col>

                          {/* Insurance validity date  */}
                          <Col xl={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name='insurance_validity'
                              label='Insurance Validity'
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input insurance validity',
                                },
                              ]}
                            >
                              {/* <Input
                                disabled={!isExchange}
                                placeholder="Insurance Validity"
                                className="form-control-ant"
                              /> */}
                              <DatePicker
                                className='form-control-ant'
                                disabled={!isExchange || isSentforEvaluation}
                                format='DD/MM/YYYY'
                                placeholder='Select Date'
                                // disabledDate={(current) =>
                                //   current && current < dayjs().startOf('day')
                                // }
                              />
                            </Form.Item>
                          </Col>

                          {/* Remark 2  */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <Form.Item
                              label='Remark'
                              name='remark2'
                              rules={[
                                isExchange && {
                                  required: true,
                                  message: 'Please input your remark',
                                },
                                {
                                  max: 300,
                                  message: 'Remark cannot exceed 300 characters',
                                },
                              ]}
                            >
                              <Input.TextArea disabled={!isExchange || isSentforEvaluation} placeholder='Remark' />
                            </Form.Item>
                          </Col>
                          {/* Upload file  */}
                          <Col xl={8} md={12} sm={24} xs={24}>
                            <div className='user-left text-start'>
                              <Title level={4} className='pb-2'>
                                Upload Image Details
                              </Title>
                            </div>
                            <Row gutter={[16, 16]} align='middle'>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                  // label="Upload Front Image"
                                  className='mb-0'
                                  name='front_image'
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please upload file',
                                    },
                                  ]}
                                >
                                  <div className='exchange-upload d-flex justify-content-between align-items-baseline'>
                                    <p>Front Image</p>
                                    <Upload
                                      showUploadList={{
                                        showRemoveIcon: !isSentforEvaluation, // This controls whether the remove icon is shown
                                        removeIcon: (file) =>
                                          // If you need custom remove logic or icon, you can define it here
                                          // This example uses the default icon provided by Ant Design
                                          isSentforEvaluation ? null : '',
                                      }}
                                      customRequest={({ file, onSuccess }) => {
                                        onSuccess('ok');
                                      }}
                                      onChange={(info) => handleFileChange(info, 'front_image')}
                                      beforeUpload={validateFile}
                                      maxCount={1}
                                      accept='.jpg,.jpeg,.png,.gif'
                                      maxFileSize={10 * 1024 * 1024}
                                      fileList={fileList?.front_image}
                                      onRemove={
                                        leadsDetailsData?.documents?.length > 0 && !isSentforEvaluation
                                          ? handleRemoveDocument
                                          : undefined
                                      }
                                    >
                                      <Button disabled={isSentforEvaluation} icon={<UploadOutlined />}></Button>
                                    </Upload>
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                  // label="Upload Back Image"
                                  className='mb-0'
                                  name='back_image'
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please upload file',
                                    },
                                  ]}
                                >
                                  <div className='exchange-upload d-flex justify-content-between align-items-baseline'>
                                    <p>Back Image</p>
                                    <Upload
                                      maxCount={1}
                                      beforeUpload={validateFile}
                                      accept='.jpg,.jpeg,.png,.gif'
                                      maxFileSize={10 * 1024 * 1024}
                                      fileList={fileList?.back_image}
                                      customRequest={({ file, onSuccess }) => {
                                        onSuccess('ok');
                                      }}
                                      onChange={(info) => handleFileChange(info, 'back_image')}
                                      onRemove={
                                        leadsDetailsData?.documents?.length > 0 && !isSentforEvaluation
                                          ? handleRemoveDocument
                                          : ''
                                      }
                                      showUploadList={{
                                        showRemoveIcon: !isSentforEvaluation, // This controls whether the remove icon is shown
                                        removeIcon: (file) =>
                                          // If you need custom remove logic or icon, you can define it here
                                          // This example uses the default icon provided by Ant Design
                                          isSentforEvaluation ? null : '',
                                      }}
                                    >
                                      <Button disabled={isSentforEvaluation} icon={<UploadOutlined />}></Button>
                                    </Upload>
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                  // label="Upload Side Image"
                                  className='mb-0'
                                  name='side_image'
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please upload file',
                                    },
                                  ]}
                                >
                                  <div className='exchange-upload d-flex justify-content-between align-items-baseline'>
                                    <p>Side Image</p>
                                    <Upload
                                      maxCount={1}
                                      beforeUpload={validateFile}
                                      fileList={fileList?.side_image}
                                      accept='.jpg,.jpeg,.png,.gif'
                                      maxFileSize={10 * 1024 * 1024}
                                      customRequest={({ file, onSuccess }) => {
                                        onSuccess('ok');
                                      }}
                                      onChange={(info) => handleFileChange(info, 'side_image')}
                                      onRemove={
                                        leadsDetailsData?.documents?.length > 0 && !isSentforEvaluation
                                          ? handleRemoveDocument
                                          : ''
                                      }
                                      showUploadList={{
                                        showRemoveIcon: !isSentforEvaluation, // This controls whether the remove icon is shown
                                        removeIcon: (file) =>
                                          // If you need custom remove logic or icon, you can define it here
                                          // This example uses the default icon provided by Ant Design
                                          isSentforEvaluation ? null : '',
                                      }}
                                    >
                                      <Button disabled={isSentforEvaluation} icon={<UploadOutlined />}></Button>
                                    </Upload>
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                  // label="Upload Inside Image"
                                  className='mb-0'
                                  name='inside_image'
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please upload file',
                                    },
                                  ]}
                                >
                                  <div className='exchange-upload d-flex justify-content-between align-items-baseline'>
                                    <p>Inside Image</p>
                                    <Upload
                                      maxCount={1}
                                      beforeUpload={validateFile}
                                      fileList={fileList?.inside_image}
                                      accept='.jpg,.jpeg,.png,.gif'
                                      maxFileSize={10 * 1024 * 1024}
                                      customRequest={({ file, onSuccess }) => {
                                        onSuccess('ok');
                                      }}
                                      onChange={(info) => handleFileChange(info, 'inside_image')}
                                      onRemove={
                                        leadsDetailsData?.documents?.length > 0 && !isSentforEvaluation
                                          ? handleRemoveDocument
                                          : ''
                                      }
                                      showUploadList={{
                                        showRemoveIcon: !isSentforEvaluation, // This controls whether the remove icon is shown
                                        removeIcon: (file) =>
                                          // If you need custom remove logic or icon, you can define it here
                                          // This example uses the default icon provided by Ant Design
                                          isSentforEvaluation ? null : '',
                                      }}
                                    >
                                      <Button disabled={isSentforEvaluation} icon={<UploadOutlined />}></Button>
                                    </Upload>
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                )}
                <div className='user-right d-flex align-items-center justify-content-end mt-4'>
                  <Space size='middle'>
                    <Button type='primary' ghost danger className='primary-button' onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button type='primary' className='secondary-button' htmlType='submit'>
                      {editMode ? 'Update' : 'Add'}
                    </Button>
                  </Space>
                </div>
              </Card>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddLeads;
