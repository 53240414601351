/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { formatIndianCurrency } from '../../../utills/helpers';
import { Row, Col, Card, Typography, notification, Button } from 'antd';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { getQuotationByIdService } from '../../../services/master-services';
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title } = Typography;

const ViewQuotation = ({ setShowQuotation, quotationId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [getQuotationById, setQuotationById] = useState();

  useEffect(() => {
    getLeadsData(quotationId);
  }, []);

  const getLeadsData = async (id) => {
    setIsLoading(true);
    try {
      const res = await getQuotationByIdService(id);
      setQuotationById(res?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='user-data view-quotation-data'>
        <Loader isLoading={isLoading} />
        <PageContentHeader
          title='View Quotation'
          headerRight={
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                setShowQuotation(false);
              }}
              type='primary'
              className='secondary-button'
            >
              Back
            </Button>
          }
        />
        <Card className='user-left mt-3'>
          <div className='detail-wrapper pt-2'>
            <div className='view-input-field'>
              <Row gutter={20} justify='start'>
                {/* Quotation Number */}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                    <h6 className='label'>Quotation Number:</h6>
                    <p className='value'>{getQuotationById?.quotation_no ? getQuotationById?.quotation_no : '-'}</p>
                  </div>
                </Col>
                {/* Issue Date */}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                    <h6 className='label'>Issue Date:</h6>
                    <p className='value'>
                      {getQuotationById?.issue_date ? dayjs(getQuotationById?.issue_date).format('DD/MM/YYYY') : '-'}
                    </p>
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                    <h6 className='label'>Valid Up To:</h6>
                    <p className='value'>
                      {' '}
                      {getQuotationById?.valid_upto ? dayjs(getQuotationById?.valid_upto).format('DD/MM/YYYY') : '-'}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* customer details */}
          <div className='h-line'>&nbsp;</div>
          <div className='user-left text-start'>
            <Title level={4} className=''>
              Customer Details
            </Title>
          </div>
          <div className='detail-wrapper pt-2'>
            <Row gutter={20} justify='start'>
              {/* Name */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Name:</h6>
                  <p className='value'>{getQuotationById?.lead_details?.customer?.full_name}</p>
                </div>
              </Col>
              {/* Address */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Address:</h6>
                  <p className='value'>{getQuotationById?.lead_details?.customer?.address}</p>
                </div>
              </Col>
              {/* Email */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Email:</h6>
                  <p className='value'>{getQuotationById?.lead_details?.customer?.email}</p>
                </div>
              </Col>
              {/* Contact Number */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Contact Number:</h6>
                  <p className='value'>{getQuotationById?.lead_details?.customer?.phone_no}</p>
                </div>
              </Col>
            </Row>
          </div>

          {/* vehicle details */}
          <div className='h-line'>&nbsp;</div>
          <div className='user-left text-start'>
            <Title level={4} className=''>
              Vehicle Details
            </Title>
          </div>
          <div className='detail-wrapper pt-2'>
            <Row gutter={20} justify='start'>
              {/* Model */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Model:</h6>
                  <p className='value'>
                    {getQuotationById?.model?.model_name ? getQuotationById?.model?.model_name : '-'}
                  </p>
                </div>
              </Col>
              {/* Car Variant */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Car Variant:</h6>
                  <p className='value'>
                    {getQuotationById?.variant?.variant_code ? getQuotationById?.variant?.variant_code : '-'}
                  </p>
                </div>
              </Col>
              {/* Color */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Color:</h6>
                  <p className='value'>{getQuotationById?.color?.color ? getQuotationById?.color?.color : '-'}</p>
                </div>
              </Col>
              {/* Fuel */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Fuel:</h6>
                  <p className='value'>
                    {getQuotationById?.fuel_type?.title ? getQuotationById?.fuel_type?.title : '-'}
                  </p>
                </div>
              </Col>
              {/* Seater */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields  d-flex align-items-baseline justify-content-start'>
                  <h6 className='label'>Seater:</h6>
                  <p className='value'> {getQuotationById?.seater?.seater ? getQuotationById?.seater?.seater : '-'}</p>
                </div>
              </Col>
            </Row>
          </div>

          {/* price details */}
          <div className='h-line'>&nbsp;</div>
          <div className='user-left text-start'>
            <Title level={4} className=''>
              Price Details
            </Title>
          </div>
          <div className='detail-wrapper pt-2'>
            <Row gutter={20} justify='start'>
              {/* Ex-Showroom Price */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Ex-Showroom Price</h6>
                  <p className='value'>
                    {getQuotationById?.ex_showroom_price
                      ? `${formatIndianCurrency(getQuotationById?.ex_showroom_price)}`
                      : '-'}
                  </p>
                </div>
              </Col>
              {/* Commercial Rebate/ Discount */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Commercial Rebate/ Discount</h6>
                  <p className='value'>
                    {getQuotationById?.commercial_rebate_discount
                      ? `${formatIndianCurrency(getQuotationById?.commercial_rebate_discount)}`
                      : '-'}
                  </p>
                </div>
              </Col>
              {/* TCS */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>TCS</h6>
                  <p className='value'>
                    {' '}
                    {getQuotationById?.tcs ? `${formatIndianCurrency(getQuotationById?.tcs)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* Insurance type */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Insurance Type</h6>
                  <p className='value'>
                    {getQuotationById?.insurance
                      ? `${getQuotationById?.insurance_type ? getQuotationById?.insurance_type?.type : '-'}`
                      : '-'}
                  </p>
                </div>
              </Col>
              {/* Insurance */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Insurance</h6>
                  <p className='value'>
                    {getQuotationById?.insurance ? `${formatIndianCurrency(getQuotationById?.insurance)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* RTO */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>RTO</h6>
                  <p className='value'>
                    {getQuotationById?.rto_location?.name ? `${getQuotationById?.rto_location?.name}` : '-'}
                  </p>
                </div>
              </Col>
              {/* RTO Cost */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>RTO Cost</h6>
                  <p className='value'>
                    {getQuotationById?.rto_location?.name ? `${formatIndianCurrency(getQuotationById?.rto_cost)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* Cost of Registration */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Cost of Registration</h6>
                  <p className='value'>
                    {getQuotationById?.cost_of_registration
                      ? `${formatIndianCurrency(getQuotationById?.cost_of_registration)}`
                      : '-'}
                  </p>
                </div>
              </Col>
              {/* Extended Warranty */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Extended Warranty</h6>
                  <p className='value'>
                    {getQuotationById?.ew ? `${formatIndianCurrency(getQuotationById?.ew)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* AMC */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>AMC</h6>
                  <p className='value'>
                    {getQuotationById?.amc ? `${formatIndianCurrency(getQuotationById?.amc)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* Tentative Accessories */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Tentative Accessories</h6>
                  <p className='value'>
                    {getQuotationById?.tentative_accessories
                      ? `${formatIndianCurrency(getQuotationById?.tentative_accessories)}`
                      : '-'}
                  </p>
                </div>
              </Col>
              {/* Fastag */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Fastag</h6>
                  <p className='value'>
                    {getQuotationById?.fastag ? `${formatIndianCurrency(getQuotationById?.fastag)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* T-Gloss*/}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>T-Gloss</h6>
                  <p className='value'>
                    {getQuotationById?.body_care_3m ? `${formatIndianCurrency(getQuotationById?.body_care_3m)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* Other Logistics Charges */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Other Logistics Charges</h6>
                  <p className='value'>
                    {getQuotationById?.logistic_charges
                      ? `${formatIndianCurrency(getQuotationById?.logistic_charges)}`
                      : '-'}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className='h-line'>&nbsp;</div>
          <div className='detail-wrapper'>
            <Row gutter={20} justify='start'>
              {/* Road Tax Type */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Road Tax Type</h6>
                  <p className='value ms-auto'>
                    {getQuotationById?.road_tax_type ? `${getQuotationById?.road_tax_type?.type}` : '-'}
                  </p>
                </div>
              </Col>
              {/* Road Tax */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Road Tax</h6>
                  <p className='value ms-auto'>
                    {getQuotationById?.road_tax ? `${formatIndianCurrency(getQuotationById?.road_tax)}` : '-'}
                  </p>
                </div>
              </Col>
              {/* On Road Price */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>On Road Price</h6>
                  <p className='value'>
                    {getQuotationById?.on_road_price ? `${formatIndianCurrency(getQuotationById?.on_road_price)}` : '-'}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className='h-line'>&nbsp;</div>
          <div className='detail-wrapper'>
            <Row gutter={20} justify='start'>
              {/* Other Discount */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Other Discount</h6>
                  <p className='value ms-auto'>
                    {getQuotationById?.other_discount
                      ? `${formatIndianCurrency(getQuotationById?.other_discount)}`
                      : '-'}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className='h-line'>&nbsp;</div>
          <div className='detail-wrapper pt-2'>
            <Row gutter={20} justify='start'>
              {/* Total Amount Payable */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='view-fields view-field-quot d-flex align-items-baseline justify-content-between'>
                  <h6 className='label'>Total Amount Payable</h6>
                  <p className='value ms-auto'>
                    {getQuotationById?.total_amount ? `${formatIndianCurrency(getQuotationById?.total_amount)}` : '-'}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <Row gutter={[40, 0]} align='start' className='mb-3'>
              {/* Terms and Conditions */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <h4 className='mb-3'>Terms and Conditions</h4>
                <div
                  className='listing'
                  dangerouslySetInnerHTML={{
                    __html: getQuotationById?.terms_condition,
                  }}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ViewQuotation;
